import { SystemProps } from '@chakra-ui/react';

export const listItemStyle: SystemProps = {
  _hover: {
    color: 'texas.success.100',
    width: 'calc(100% - 2em)',
  },
  transition: 'color 100ms ease, width 200ms ease',
  paddingBottom: '2',
  cursor: 'pointer',
  ml: 'auto',
  w: '100%',
  display: 'flex',
};

export const subTitle: SystemProps = {
  fontSize: '12px',
  fontWeight: 'bold',
  color: 'gray.300',
};
