import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { Icons } from './shared/Icons';

export type SearchFilterStyle = 'unstyled' | 'border';

export interface SearchFilterInputProps {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  debounceDelay?: number;
  placeholder?: string;
  style?: SearchFilterStyle;
  autoFocus?: boolean;
}

const defaultDebounceDelay = 350;

export const SearchFilterInput = React.forwardRef<
  HTMLInputElement,
  SearchFilterInputProps
>(function SearchFilterInput(props, ref) {
  const [inputValue, setInputValue] = useState('');
  const inputValueRef = useRef('');
  const { t } = useTranslation();

  function setInputValueWithRef(value: string) {
    inputValueRef.current = value;
    setInputValue(value);
  }

  useEffect(() => {
    if (props.value !== inputValueRef.current) {
      setInputValueWithRef(props.value);
    }
  }, [props.value]);

  const onChangeDebounced = useDebouncedCallback(
    props.onChange,
    props.debounceDelay ?? defaultDebounceDelay,
  );

  let style: InputGroupProps;
  switch (props.style) {
    case 'unstyled':
      {
        style = {};
      }
      break;
    case undefined:
    case 'border': {
      style = {
        borderBottomWidth: 1,
        borderColor: 'gray.600',
        padding: '2',
      };
      break;
    }
  }

  return (
    <InputGroup
      {...style}
      transition="border-color 200ms ease"
      variant="unstyled"
      _focusWithin={{
        borderColor: 'gray.400',
      }}
      _hover={{
        borderColor: 'gray.400',
      }}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    >
      <InputLeftAddon mr={2}>
        <Icons.Search boxSize={6} />
      </InputLeftAddon>
      <Input
        name={t('general.search')}
        ref={ref}
        _placeholder={{ color: 'whiteAlpha.600' }}
        type="search"
        placeholder={props.placeholder ?? t('general.search')}
        fontSize={16}
        value={inputValue}
        onChange={(e) => {
          setInputValueWithRef(e.target.value);
          onChangeDebounced(e.target.value);
        }}
        autoFocus={props.autoFocus}
      />
    </InputGroup>
  );
});
