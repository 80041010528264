export const defaultCustomerWidgetLayouts = {
  articles: {
    lg: {
      x: 0,
      y: 3,
      w: 12,
      h: 10,
      minH: 3,
      minW: 4,
      maxH: 12,
    },
  },
};
