import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = config.definePartsStyle({
  container: {
    backgroundColor: 'texas.bg.900',
  },
});

export default config.defineMultiStyleConfig({
  baseStyle,
});
