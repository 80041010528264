import {
  Box,
  Button,
  Flex,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ArchiveTreatment } from './ArchiveTreatment';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { treatmentTableColumns } from './Columns';
import { EditTreatment } from './EditTreatment';
import { CreateTreatment } from './CreateTreatment';
import {
  TreatmentListItem,
  treatmentsApi,
} from '@texas/api/endpoints/metadata/treatmentsApi';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { Container } from '@texas/components/shared/Container';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { TexasSwitch } from '@texas/components/shared/form/TexasSwitch';
import {
  SearchQuery,
  defaultSearchQuery,
} from '@texas/api/endpoints/metadata/types';
import { SelectProductGroup } from '@texas/components/shared/form/SelectProductGroup';

export function Treatments() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<TreatmentListItem>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<number>();

  const { t } = useTranslation();
  const toast = useToast();

  const [searchQuery, setSearchQuery] =
    useState<SearchQuery>(defaultSearchQuery);

  const {
    data: treatments,
    refetch,
    error,
    loading,
  } = useApiResource(treatmentsApi.getAll);

  const { request: restoreTreatmentRequest } = useApiRequest(
    treatmentsApi.restore,
  );

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restoreTreatmentRequest,
        [id],
        () => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(searchQuery);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [restoreTreatmentRequest, toast, t, refetch, searchQuery],
  );

  const columns = useMemo(() => {
    return treatmentTableColumns(
      t,
      onArchiveOpen,
      performRestore,
      onEditOpen,
      searchQuery.productGroupId ?? 0,
    );
  }, [
    t,
    onArchiveOpen,
    performRestore,
    onEditOpen,
    searchQuery.productGroupId,
  ]);

  return (
    <Container title={t('configuration.treatments')}>
      {archiveValue && (
        <ArchiveTreatment
          treatment={archiveValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchive={() => {
            refetch(searchQuery);
          }}
        />
      )}
      <CreateTreatment
        onCreated={() => {
          refetch(searchQuery);
          onClose();
        }}
        onClose={onClose}
        isOpen={isOpen}
      />
      <EditTreatment
        treatmentId={editValue}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onUpdated={() => {
          refetch(searchQuery);
        }}
      />
      <VStack w="100%" alignItems="start" p={4} background="texas.bg.900">
        <Flex justify="space-between" w="100%">
          <Flex direction="column" gap={4}>
            <Flex gap={4} align="flex-end">
              <Box height="fit-content" mt="auto" minWidth={80}>
                <SearchFilterInput
                  value={searchQuery.searchTerm}
                  onChange={(s) =>
                    setSearchQuery({ ...searchQuery, searchTerm: s })
                  }
                />
              </Box>
              <SelectProductGroup
                selectedProductGroupId={searchQuery.productGroupId}
                onSelect={(id) =>
                  setSearchQuery({
                    ...searchQuery,
                    productGroupId: id > 0 ? id : null,
                  })
                }
              />
            </Flex>
            <TexasSwitch
              checked={searchQuery.includeArchived}
              label={t('general.includeArchived')}
              onChange={(checked) =>
                setSearchQuery({
                  ...searchQuery,
                  includeArchived: checked,
                })
              }
            />
          </Flex>
          <Button onClick={onOpen}>{t('general.addNew')}</Button>
        </Flex>
        <DataTableContainer
          w="100%"
          datatable={{
            data: treatments ?? [],
            sorted: {
              onSortedChange: ({ key, desc }) => {
                setSearchQuery({ ...searchQuery, sortBy: key, sortDesc: desc });
              },
              key: searchQuery.sortBy,
              desc: searchQuery.sortDesc,
            },
            isLoading: loading,
            columns: columns,
            variant: 'configuration',
          }}
          error={error}
        />
      </VStack>
    </Container>
  );
}
