import { Box, Flex } from '@chakra-ui/react';
import { WizardForm } from './WizardForm';
import { useProdutApprovalWizardState } from '../context';

export function WizardLayout() {
  const { setStepIsActive, steps, stepStates, summary } =
    useProdutApprovalWizardState();
  return (
    <Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        background="gray.800"
        gap={2}
      >
        <WizardForm
          setStepIsActive={setStepIsActive}
          stepStates={stepStates}
          steps={steps}
        />
        <Box w="full" p={6} borderTop="1px solid gray">
          {summary}
        </Box>
      </Flex>
    </Box>
  );
}
