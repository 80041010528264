import { TabPanel } from '@chakra-ui/react';
import { Step } from './types';
import { StepContent } from './StepContent';

export function StepTabPanel({ step }: { step: Step }) {
  return (
    <TabPanel px={0} backgroundColor="gray.700">
      <StepContent>{step.config.content}</StepContent>
    </TabPanel>
  );
}
