export const defaultWidgetLayouts = {
  articles: {
    lg: {
      x: 0,
      y: 3,
      w: 12,
      h: 8,
      minH: 3,
      minW: 4,
      maxH: 8,
    },
  },
  brands: {
    lg: {
      x: 0,
      y: 0,
      w: 12,
      h: 2,
      minH: 2,
      minW: 4,
      maxH: 6,
    },
  },
};
