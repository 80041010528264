import { Box, Portal } from '@chakra-ui/react';
import useChakraOutsideClick, {
  Modal,
  ModalState,
} from '@texas/hooks/useChakraOutsideClick';
import { drawerAnimations } from '@texas/resources/animations/drawerAnimations';
import { motion } from 'framer-motion';
import React from 'react';
import { useEffect, useRef, useState } from 'react';

export function useTexasDrawer(initialState: ModalState, peekClose?: boolean) {
  return useChakraOutsideClick<HTMLDivElement>(initialState, peekClose);
}

interface TexasDrawerProps {
  modal: Modal<HTMLDivElement>;
  children: React.ReactNode;
  toggleBackground?: boolean;
  customWidth?: number;
}

const defaultWidth = 30;
const peekWidth = 2;
const hoverPeekWidth = 6;
const animationDurationMs = 300;

export const TexasDrawer = React.forwardRef<HTMLDivElement, TexasDrawerProps>(
  function Drawer(props, ref) {
    const width = props.customWidth ?? defaultWidth;
    const { openAnimation, closeAnimation } = drawerAnimations({
      openRightOffset:
        props.modal.modalState === ModalState.Peek
          ? `${width - peekWidth}em`
          : '0px',
      width: `${width}em`,
      duration: animationDurationMs,
    });
    const [displayState, setDisplayState] = useState(props.modal.modalState);
    const [animation, setAnimation] = useState(openAnimation);
    const closingTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
      function setState(state: ModalState) {
        setDisplayState(state);
        clearTimeout(closingTimeout.current!);
        closingTimeout.current = undefined;
      }

      if (props.modal.modalState === ModalState.Closed) {
        setAnimation(closeAnimation);
        closingTimeout.current = setTimeout(() => {
          setState(props.modal.modalState);
        }, animationDurationMs);
      } else if (props.modal.modalState === ModalState.Open) {
        setAnimation(openAnimation);
        setState(props.modal.modalState);
      }
    }, [closeAnimation, openAnimation, props.modal.modalState]);

    return displayState === ModalState.Closed ? null : (
      <Portal>
        <Box
          pos="fixed"
          zIndex={99}
          left="0"
          top="0"
          w="100%"
          h="100%"
          color="white"
          pointerEvents="none"
          overflow="hidden"
          transition="background-color 100ms linear"
          bgColor={
            props.toggleBackground && props.modal.modalState === ModalState.Open
              ? 'rgba(0,0,0,0.6)'
              : 'transparent'
          }
        >
          <Box
            {...(props.modal.modalState === ModalState.Peek
              ? {
                  _hover: {
                    opacity: 1,
                    cursor: 'w-resize',
                    right: `-${width - hoverPeekWidth}em`,
                  },
                  onClick: () => props.modal.setModalState(ModalState.Open),
                }
              : null)}
            pointerEvents="all"
            borderLeftRadius={16}
            boxShadow="0px 0px 6px 3px rgb(0 0 0 / 20%)"
            ref={ref}
            pos="absolute"
            as={motion.div}
            animation={animation}
            opacity={props.modal.modalState === ModalState.Peek ? 0.9 : 1}
            top="0"
            bottom="0"
            width={`${width}em`}
            right={getRightOffset(props.modal.modalState, width, peekWidth)}
            transition="right 200ms ease, opacity 150ms ease"
            backgroundColor="texas.bg.900"
            display="flex"
            __css={{
              ' > *': {
                pointerEvents:
                  props.modal.modalState === ModalState.Open ? 'all' : 'none',
              },
            }}
          >
            {props.children}
          </Box>
        </Box>
      </Portal>
    );
  },
);

function getRightOffset(state: ModalState, width: number, peekWidth: number) {
  switch (state) {
    case ModalState.Closed:
      return `-${width}em`;
    case ModalState.Open:
      return '0';
    case ModalState.Peek:
      return `-${width - peekWidth}em`;
  }
}
