import { httpClient } from '../httpClient/httpClient';

export interface CompositionData {
  id: number;
  text: string;
  value?: string;
}

export interface PantoneColor {
  id: number;
  pantoneCode: string;
  pantoneName: string;
  colorDescription: string;
}

interface DimensionValue {
  id: number;
  label: string;
  value: string;
}

export interface Dimension {
  id: number;
  dimensions: DimensionValue[];
}

export interface Composition {
  materials: CompositionMaterial[];
  template: boolean;
  weight: number;
  imageIdentifier: string;
  exists: boolean;
  id: number;
  branchId: number | null;
  supplierId: number | null;
  cellX: number | null;
  cellY: number | null;
  dimension: Dimension | null;
  archived: string | null;
}

export interface CompositionMaterial {
  id: number;
  material: CompositionData;
  qualities: CompositionData[];
  techniques: CompositionData[];
  treatments: CompositionData[];
  colors: PantoneColor[];
  weight: number;
  note: string;
}

export interface QualityValueData {
  id: number;
  value: string;
}
export interface ColorData {
  pantoneCode: string;
  pantoneName: string;
  colorDescription: string;
}
export interface CreateCompositionMaterialRequest {
  materialId: number | null;
  qualities: QualityValueData[];
  techniqueIds: number[] | null | undefined;
  treatmentIds: number[] | null | undefined;
  colors: ColorData[];
  weight: number;
  note: string;
}
export type UpdateCompositionMaterialRequest = CreateCompositionMaterialRequest;

export interface UpdateCompositionRequest {
  weight: number;
  dimensions: DimensionData[];
}

export interface DimensionData {
  id: number;
  value: string;
}

export interface CompositionCell {
  x: number;
  y: number;
  id: number;
  colorCodes: string[];
}

interface CopyCompositionRequest {
  variantId: number;
  branchId: number | null;
  supplierId: number | null;
  matrixNodeXId: number | null;
  matrixNodeYId: number | null;
}

export interface SupplierCompositionData {
  variantId: number;
  supplierId: number;
  branchId: number;
  cellX?: number;
  cellY?: number;
}

export const compositionApi = {
  getVariantTemplate: (variantId: number) =>
    httpClient.get<Composition>(`compositions/template/${variantId}`),
  getComposition: (compositionId: number) =>
    httpClient.get<Composition>(`compositions/${compositionId}`),
  createVariantTemplate: (variantId: number) =>
    httpClient.post<Composition>(`compositions/template/${variantId}`),
  updateComposition: (
    compositionId: number,
    request: UpdateCompositionRequest,
  ) =>
    httpClient.put<Composition>(`compositions/${compositionId}`, {
      data: request,
    }),
  getVariantCellTemplate: (variantId: number, cellX: number, cellY: number) =>
    httpClient.get<Composition>(
      `compositions/template/${variantId}/cell/${cellX}/${cellY}`,
    ),
  createVariantCellTemplate: (
    variantId: number,
    cellX: number,
    cellY: number,
  ) =>
    httpClient.post<Composition>(
      `compositions/template/${variantId}/cell/${cellX}/${cellY}`,
    ),
  getAllCellCompositionTemplates: (variantId: number) =>
    httpClient.get<CompositionCell[]>(
      `compositions/template/${variantId}/cell/all`,
    ),
  createCompositionMaterial: (
    compositionId: number,
    request: CreateCompositionMaterialRequest,
  ) =>
    httpClient.post<CompositionMaterial>(
      `compositions/${compositionId}/material`,
      { data: request },
    ),
  updateCompositionMaterial: (
    compositionId: number,
    request: UpdateCompositionMaterialRequest,
  ) =>
    httpClient.put<CompositionMaterial>(
      `compositions/${compositionId}/material`,
      { data: request },
    ),
  getCompositionMaterial: (materialCompositionId: number) =>
    httpClient.get<CompositionMaterial>(
      `compositions/${materialCompositionId}/material`,
    ),
  removeCompositionMaterial: (materialCompositionId: number) =>
    httpClient.delete(`compositions/${materialCompositionId}/material`),
  copyComposition: (fromId: number, request: CopyCompositionRequest) =>
    httpClient.post<Composition>(`compositions/${fromId}/copy`, {
      data: request,
    }),
  searchCompositions: (params: {
    variantId: number;
    branchIds: number[];
    supplierIds: number[];
  }) => httpClient.get<Composition[]>(`compositions/search`, { params }),
  getSupplierComposition: (params: SupplierCompositionData) =>
    httpClient.get<Composition>(`compositions/supplier`, { params }),
  createSupplierComposition: (data: SupplierCompositionData) =>
    httpClient.post<Composition>(`compositions/supplier`, { data }),
  archiveComposition: (id: number) =>
    httpClient.put<Composition>(`compositions/${id}/archive`),
  restoreComposition: (id: number) =>
    httpClient.put<Composition>(`compositions/${id}/restore`),
};
