import {
  Checkbox,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import {
  Quality,
  UpdateQualityRequest,
  qualitiesApi,
} from '@texas/api/endpoints/metadata/qualitiesApi';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { productGroupsApi } from '@texas/api/endpoints/metadata/productGroupsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import Select from 'react-select';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';

export interface EditQualityProps extends SharedDisclosureProps {
  onUpdated: (quality: Quality) => void;
  qualityId: number | null;
}

export function EditQuality({
  onUpdated,
  qualityId,
  onClose,
  isOpen,
}: EditQualityProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('configuration.editQuality')}</ModalHeader>
        {qualityId && (
          <Form qualityId={qualityId} onClose={onClose} onUpdated={onUpdated} />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  qualityId,
  onClose,
  onUpdated,
}: {
  qualityId: number;
  onClose: () => void;
  onUpdated: (quality: Quality) => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: updateRequest, error } = useApiRequest(qualitiesApi.update);

  const {
    data: productGroups,
    refetch: refetchProductGroups,
    loading: productGroupsLoading,
  } = useApiResource(productGroupsApi.getProductGroups);

  const {
    data: qualities,
    refetch: refetchQualities,
    loading: qualitiesLoading,
  } = useApiResource(qualitiesApi.getAll);

  const { data: quality, refetch: refetchQuality } = useApiResource(
    qualitiesApi.get,
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<UpdateQualityRequest>();

  useEffect(() => {
    refetchQuality(qualityId);
    refetchProductGroups();
    refetchQualities({
      searchTerm: '',
      sortBy: '',
      sortDesc: false,
      includeArchived: false,
      preciseIdFiltering: true,
      productGroupId: null,
    });
  }, [
    reset,
    refetchProductGroups,
    refetchQuality,
    qualityId,
    refetchQualities,
  ]);

  useEffect(() => {
    reset({
      name: quality?.name,
      code: quality?.code,
      productGroupIds: quality?.productGroups.map((x) => x.id),
      childQualityIds: quality?.childQualities.map((x) => x.id),
      inputField: quality?.inputField,
    });
  }, [quality, reset]);

  const onSubmit = async (data: UpdateQualityRequest) =>
    await request(
      updateRequest,
      [quality!.id, data],
      (data: Quality) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(data);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Flex direction="column" gap={4}>
            <FormControl isRequired={true} isInvalid={!!errors.code}>
              <TexasFormLabel>{t('general.code')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('code', {
                  required: true,
                  maxLength: {
                    value: 6,
                    message: t('errors.maxLength', { count: 6 }),
                  },
                })}
                placeholder={t('general.code')}
              />
              <ErrorLabel text={errors.code?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>{t('configuration.qualityName')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 30,
                    message: t('errors.maxLength', { count: 30 }),
                  },
                })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('configuration.dynamicField')}</TexasFormLabel>
              <Controller
                name="inputField"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox onChange={onChange} isChecked={value} />
                )}
              />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('general.productGroups')}</TexasFormLabel>
              <Controller
                name="productGroupIds"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isLoading={productGroupsLoading}
                    isClearable={true}
                    styles={reactSelectStyle<true>()}
                    theme={reactSelectTheme}
                    value={field.value?.map<ReactSelectOption>((x) => ({
                      label:
                        productGroups?.find((p) => p.id === x)?.value ?? '',
                      value: x,
                    }))}
                    isMulti={true}
                    onChange={(e) => field.onChange(e.map((x) => x.value))}
                    options={
                      productGroups?.map<ReactSelectOption>((x) => ({
                        value: x.id,
                        label: x.value,
                      })) ?? []
                    }
                    closeMenuOnSelect={false}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <TexasFormLabel>
                {t('configuration.groupedQualities')}
              </TexasFormLabel>
              <Controller
                name="childQualityIds"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isLoading={qualitiesLoading}
                    isClearable={true}
                    styles={reactSelectStyle<true>()}
                    theme={reactSelectTheme}
                    value={field.value?.map<ReactSelectOption>((x) => ({
                      label: qualities?.find((p) => p.id === x)?.name ?? '',
                      value: x,
                    }))}
                    isMulti={true}
                    onChange={(e) => field.onChange(e.map((x) => x.value))}
                    options={
                      qualities
                        ?.filter((x) => x.id !== qualityId)
                        .map<ReactSelectOption>((x) => ({
                          value: x.id,
                          label: x.name,
                        })) ?? []
                    }
                    closeMenuOnSelect={false}
                  />
                )}
              />
              <Text variant="small" pt={1}>
                {t('configuration.groupedDesc')}
              </Text>
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <SubmitButton loading={isSubmitting}>
            {t('general.update')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
