import { reactEvents } from '../../../../../bridge/reactEvents';

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.general', {
    url: '/general',
    templateUrl: 'main/article/variant/general/general.tpl.html',
    controller: 'VariantGeneralController as vm',
  });
};

class VariantGeneralController {
  constructor(
    $scope,
    variant,
    loadSpinnerService,
    ArticlesApiService,
    HelperService,
    UploaderService,
    FilesApiService,
    AuthService,
    toasterService,
    SavingService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.originalVariant = variant;
    this.loadSpinnerService = loadSpinnerService;
    this.ArticlesApiService = ArticlesApiService;
    this.HelperService = HelperService;
    this.UploaderService = UploaderService;
    this.FilesApiService = FilesApiService;
    this.AuthService = AuthService;
    this.toasterService = toasterService;
    this.SavingService = SavingService;

    this.validationErrors = [];
    this.artworkImageInstance = {};
    this.photoInstance = {};
    this.artworkDocumentInstance = {};
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
      'agent',
    ]);
    const artworkImageOpts = {
      finalizeUrl: `variants/${variant.id}/artworkimage`,
      onDone: (success, file) => this.artworkImageUploadDone(success, file),
      keepBoth: true,
      single: true,
      disabled: !this.currentUserCanEdit,
      validate: (file) => this.validateImage(file),
    };
    const photoOpts = {
      finalizeUrl: `variants/${variant.id}/photo`,
      onDone: (success, file) => this.photoUploadDone(success, file),
      keepBoth: true,
      single: true,
      disabled: !this.currentUserCanEdit,
      validate: (file) => this.validateImage(file),
    };
    const artworkDocumentOpts = {
      finalizeUrl: `variants/${variant.id}/artworkdocument`,
      onDone: (success, file) => this.artworkDocumentUploadDone(success, file),
      keepBoth: true,
      single: true,
      disabled: !this.currentUserCanEdit,
    };

    this.artworkImageInstance = UploaderService.instance(
      `artworkImageInstance${variant.id}`,
      artworkImageOpts,
    );
    this.photoInstance = UploaderService.instance(
      `photoInstance${variant.id}`,
      photoOpts,
    );
    this.artworkDocumentInstance = UploaderService.instance(
      `artworkDocumentInstance${variant.id}`,
      artworkDocumentOpts,
    );
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validate(),
    });

    this.variantNameChangedEvent = reactEvents.variantNameChanged.subscribe(
      (variant) => {
        this.setVariantName(variant);
      },
    );

    this.setVariantClone();
    this.setWatchers();
  }

  setVariantClone() {
    this.variant = _.extend({}, this.originalVariant);
  }

  validateImage(file) {
    this.validationErrors = [];
    const mimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file.size > 20000000) {
      this.validationErrors.push({
        message: 'File size is too large (limit: 20mb)',
      });
      return false;
    }

    if (mimeTypes.indexOf(file.type) === -1) {
      this.validationErrors.push({
        message: 'File must be of type jpg, png or gif',
      });
      return false;
    }
  }

  deleteFile(type) {
    this.ArticlesApiService.deleteVariantFile(this.originalVariant, type);
  }

  getPreviewUrl(file) {
    return this.FilesApiService.getFilePreviewUrl(file.identifier, 250);
  }

  photoUploadDone(success, file) {
    if (success) {
      this.originalVariant.photoId = file.id;
      this.originalVariant.photo = file;
    }
  }

  artworkImageUploadDone(success, file) {
    if (success) {
      this.originalVariant.artworkImageId = file.id;
      this.originalVariant.artworkImage = file;
    }
  }

  artworkDocumentUploadDone(success, file) {
    if (success) {
      this.originalVariant.artworkDocumentId = file.id;
      this.originalVariant.artworkDocument = file;
    }
  }

  getChangedData() {
    return this.HelperService.getChangedData(
      this.originalVariant,
      this.variant,
      ['name', 'description', 'notes'],
    );
  }

  validate() {
    if (!this.variantGeneralForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  save(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateVariant(data, this.variant.id)
      .then(
        () => {
          this.setVariantClone();
          this.toasterService.success();
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  discard() {
    this.validationErrors = [];
    this.setVariantClone();
  }

  archive() {
    this.save({ archived: !this.variant.archived });
  }

  setAsMain() {
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateArticle(
      { mainVariantId: this.variant.id },
      this.variant.articleId,
    )
      .then(
        () => this.toasterService.success(),
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  getFileUrl(file) {
    return this.FilesApiService.getFileUrl(file.identifier);
  }

  setVariantName(variant) {
    this.originalVariant.variantName = variant.name;
    this.variant.name = variant.name;
  }

  setWatchers() {
    this.$scope.$watch(
      () => this.artworkImageInstance.active,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            this.loadSpinnerService.start('artworkImageSpinner');
          } else {
            this.loadSpinnerService.stop('artworkImageSpinner', {
              force: true,
            });
          }
        }
      },
    );

    this.$scope.$watch(
      () => this.photoInstance.active,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            this.loadSpinnerService.start('photoSpinner');
          } else {
            this.loadSpinnerService.stop('photoSpinner', {
              force: true,
            });
          }
        }
      },
    );

    this.$scope.$watch(
      () => this.artworkDocumentInstance.active,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            this.loadSpinnerService.start('artworkDocumentSpinner');
          } else {
            this.loadSpinnerService.stop('artworkDocumentSpinner', {
              force: true,
            });
          }
        }
      },
    );

    this.$scope.$watch(
      () => !_.isEmpty(this.getChangedData()),
      (hasChanges) => {
        if (!hasChanges) {
          this.variantGeneralForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
      this.variantNameChangedEvent();
    });
  }
}

angular
  .module('main.article.variant.general', ['main.article.variant.general.garp'])
  .config(config)
  .controller('VariantGeneralController', VariantGeneralController);
