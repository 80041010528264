import { VStack, Text, StackProps } from '@chakra-ui/react';

interface TexasDrawerSubHeaderProps {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  style?: StackProps;
}

export function TexasDrawerSubHeader(props: TexasDrawerSubHeaderProps) {
  return (
    <VStack
      padding={1}
      paddingLeft={4}
      backgroundColor="gray.700"
      alignItems="start"
      spacing={0}
      {...props.style}
    >
      {props.children ? (
        <>{props.children}</>
      ) : (
        <>
          <Text variant="main">{props.title}</Text>
          <Text variant="small">{props.subTitle}</Text>
        </>
      )}
    </VStack>
  );
}
