import { FilterOptionType } from '../filter/types';
import { defaultEmptyFilters } from '../searchArticles/useSearchArticles';
import { ArticleSearchProps, FilterOptions } from '../searchArticles/types';
import { VariantsSearchQuery } from '@texas/api/endpoints/searchApi';

export const pageSize = 12;
export const defaultPage = 1;
const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: '',
  sortDesc: false,
  page: defaultPage,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: false,
};
export const defaultArticlesWidgetOptions = (
  customerId: number | undefined,
  conceptId: number | undefined,
) => {
  return pageOptions(customerId, conceptId);
};

function pageOptions(
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  if (customerId) {
    return brandsOptions(customerId);
  } else if (conceptId) {
    return conceptOptions(conceptId);
  }

  throw Error('Customer or concept properties not declared');
}

const brandsOptions = (customerId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Customers],
    searchParams: { ...defaultSearchParams, customerIds: [customerId] },
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
  };

  return { defaultSearchPage, filterOptions };
};

const conceptOptions = (conceptId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Concepts],
    searchParams: { ...defaultSearchParams, conceptIds: [conceptId] },
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Concepts]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
  };

  return { defaultSearchPage, filterOptions };
};
