import { httpClient } from '../httpClient/httpClient';
import { ArticleState } from './searchApi';

export interface RecommendedSuppliers {
  productGroup: string | null;
  state: ArticleState;
  suppliers: SupplierListItem[];
}

export interface SupplierListItem {
  id: number;
  name: string;
  activeArticles: number;
  alreadyAdded: boolean;
}

export const suppliersApi = {
  variant: {
    getRecommendedSuppliers: (variantId: number, branchId: number) =>
      httpClient.get<RecommendedSuppliers>(
        `suppliers/${variantId}/recommended/${branchId}`,
      ),
    getAllBranchSuppliers: (variantId: number, branchId: number) =>
      httpClient.get<SupplierListItem[]>(
        `suppliers/${variantId}/all/${branchId}`,
      ),
  },
};
