import { HStack, Button, Box, VStack, useToast } from '@chakra-ui/react';
import { useContext } from 'react';
import { SalesTiers } from './SalesTiers';
import { useTranslation } from 'react-i18next';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { ServerError } from '@texas/types';
import { emptyNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { request } from '@texas/utils/helpers/httpHelpers';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesInformation } from '@texas/api/endpoints/salesInformationApi';
import {
  SalesCurrency,
  salesCurrenciesApi,
} from '@texas/api/endpoints/salesCurrenciesApi';
import { TierPrice } from '@texas/api/endpoints/tierPriceApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { useActiveSession } from '@texas/hooks/useSession';
import { AdditionalChargeForm } from './forms/AdditionalChargeForm';
import { SalesCurrencyForm } from './forms/SalesCurrencyForm';
import { SalesContext } from './Sales';

interface SalesCurrencyComponentProps {
  salesCurrency: SalesCurrency;
  currencies: CustomerCurrency[];
  selectedCurrency: CustomerCurrency;
  sellingExchangeRate: number;
  purchaseInformation: PurchaseInformation;
  salesInformation: SalesInformation;
  tierPrices: TierPrice[];
  onRemoved: () => void;
}

export function SalesCurrencyComponent(props: SalesCurrencyComponentProps) {
  const session = useActiveSession();
  const salesContext = useContext(SalesContext);
  const toast = useToast();
  const { t } = useTranslation();

  const removeSalesCurrencyRequest = useApiRequest(
    salesCurrenciesApi.deleteSalesCurrency,
  );
  const setMainRequest = useApiRequest(
    salesCurrenciesApi.setSalesCurrencyAsMain,
  );

  const isDefaultCurrency =
    session.branches.find((b) => b.id === props.salesInformation.branchId)
      ?.defaultCurrencyId === props.salesCurrency.currencyId;

  return (
    <VStack spacing={0}>
      {!props.salesCurrency.isMain && (
        <HStack
          p={4}
          align="start"
          mr="auto"
          backgroundColor="texas.bg.blueTint.200"
          borderBottom="1px solid"
          borderColor="gray.800"
          w="100%"
        >
          <Button
            isLoading={setMainRequest.loading}
            isDisabled={setMainRequest.loading}
            size="sm"
            alignSelf="center"
            variant="texas-success-solid"
            disabled={props.salesInformation.isReady}
            onClick={() => {
              setMainCurrency(props.salesCurrency.id);
            }}
          >
            Set {props.salesCurrency.currencyCode} as main
          </Button>
          {!isDefaultCurrency && (
            <VerifyButton
              buttonProps={{
                size: 'sm',
                alignSelf: 'center',
                isLoading: removeSalesCurrencyRequest.loading,
                isDisabled:
                  removeSalesCurrencyRequest.loading ||
                  props.salesInformation.isReady,
              }}
              onVerified={() => {
                removeCurrencyRequest(props.salesCurrency.id);
              }}
              label="Remove currency"
            />
          )}
        </HStack>
      )}
      <Box padding={4} animation={fadeInLeftAnimation(200)} w="100%">
        <VStack align="start">
          <HStack alignItems="start">
            <SalesCurrencyForm
              isBranchDefaultCurrency={isDefaultCurrency}
              salesCurrency={props.salesCurrency}
              disabled={props.salesInformation.isReady}
              branchId={props.salesInformation.branchId}
              variantId={props.salesInformation.variantId}
            />
            <AdditionalChargeForm
              additionalCharge={props.salesCurrency.additionalCharge}
              salesCurrency={props.salesCurrency}
              sellingExchangeRate={props.sellingExchangeRate}
              purchaseInformation={props.purchaseInformation}
              isDisabled={props.salesInformation.isReady}
              selectedCurrency={props.selectedCurrency}
            />
          </HStack>
        </VStack>

        <SalesTiers
          salesCurrency={props.salesCurrency}
          nodeGroups={salesContext?.articleNodeGroup ?? emptyNodeGroup}
          purchaseInformation={props.purchaseInformation}
          tierPrices={props.tierPrices}
          selectedCurrency={props.selectedCurrency}
          sellingExchangeRate={props.sellingExchangeRate}
          readyForSync={props.salesInformation.isReady}
        />
      </Box>
    </VStack>
  );

  async function setMainCurrency(salesCurrencyId: number) {
    await request(
      setMainRequest.request,
      [salesCurrencyId],
      (data) => {
        toast({
          title: t('variant.mainCurrencySet'),
          status: 'success',
          isClosable: true,
        });
        const toSet = salesContext?.salesInformation.data?.map((s) => {
          if (s.id === props.salesInformation.id) {
            return { ...s, currencies: data };
          }

          return s;
        });

        if (!toSet) {
          return;
        }

        salesContext?.salesInformation.set(toSet);
      },
      (error: ServerError) => {
        toast({
          title: 'Failed to set main currency',
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }

  async function removeCurrencyRequest(salesCurrencyId: number) {
    await request(
      removeSalesCurrencyRequest.request,
      [salesCurrencyId],
      () => {
        toast({
          title: 'Currency removed',
          status: 'success',
          isClosable: true,
        });
        const toSet = salesContext?.salesInformation.data?.map((s) => {
          if (s.id === props.salesInformation.id) {
            return {
              ...s,
              currencies: s.currencies.filter((s) => s.id !== salesCurrencyId),
            };
          }

          return s;
        });

        if (!toSet) {
          return;
        }

        salesContext?.salesInformation.set(toSet);
        props.onRemoved();
      },
      (error: ServerError) => {
        toast({
          title: 'Failed to remove currency',
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}
