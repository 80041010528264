// @ngInject
const MaterialsApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('material', {
      params: { organizationId },
    }),

  getById: (materialId) =>
    ResourcesService.getById('material', materialId, {
      params: { organizationId: false },
    }),

  newMaterial: (material, organizationId) =>
    ResourcesService.create('material', material, {
      params: { organizationId },
    }),

  updateMaterial: (data, materialId) =>
    ResourcesService.update('material', materialId, data, {
      params: { organizationId: false },
      updatedFields: [
        'code',
        'name',
        'description',
        'articleTextField',
        'displayOrder',
        'archived',
      ],
    }),
});

angular
  .module('resources.materials', [])
  .factory('MaterialsApiService', MaterialsApiService);
