import { useState } from 'react';

interface ValueDisclosure<T> {
  value: T | null;
  isOpen: boolean;
}

export const useValueDisclosure = <T>() => {
  const [props, setProps] = useState<ValueDisclosure<T>>({
    value: null,
    isOpen: false,
  });

  const onOpen = (v: T) => {
    setProps({ value: v, isOpen: true });
  };
  const onClose = () => {
    setProps((s) => ({ ...s, isOpen: false }));
  };

  return { value: props.value, isOpen: props.isOpen, onOpen, onClose };
};
