// @ngInject
const materialsResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadMaterials(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.materials', {
    url: '/materials',
    controller: 'MaterialsConfigurationController as vm',
    templateUrl: 'main/configuration/materials/materials.tpl.html',
    data: { pageTitle: 'Materials' },
    resolve: {
      materials: materialsResolve,
    },
  });
};

class MaterialsConfigurationController {
  constructor(
    $scope,
    DS,
    MaterialsApiService,
    MaterialDialogService,
    currentUser,
    materials,
  ) {
    'ngInject';

    this._MaterialDialogService = MaterialDialogService;
    this._MaterialsApiService = MaterialsApiService;
    this._currentUser = currentUser;
    this.materials = materials;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'code',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('material');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.materials = currentUser.organization.materials;
        }
      },
    );
  }

  openMaterial(material) {
    this._MaterialDialogService.showDialog({
      material,
      displayOrder: true,
      onSave: (material) =>
        this._MaterialsApiService.updateMaterial(
          _.pick(material, [
            'name',
            'description',
            'articleTextField',
            'displayOrder',
            'archived',
          ]),
          material.id,
        ),
    });
  }

  newMaterial() {
    this._MaterialDialogService.showDialog({
      displayOrder: true,
      onSave: (material) =>
        this._MaterialsApiService.newMaterial(
          material,
          this._currentUser.organization.id,
        ),
    });
  }
}

angular
  .module('configuration.materials', [])
  .config(config)
  .controller(
    'MaterialsConfigurationController',
    MaterialsConfigurationController,
  );
