import {
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from '@chakra-ui/react';
import {
  Composition,
  CompositionMaterial,
} from '@texas/api/endpoints/compositionApi';
import { dimensionGroupsApi } from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import useFormat from '@texas/hooks/useFormat';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';
import { ReactSelectOptionWithDesc } from '@texas/types';
import { RefObject, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { setValueAsDecimalExtender } from '../../sales/shared';
import { useUpdateCompositionFormSubmitState } from './updateCompositionFormSubmitContext';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { PopoverOptionComponent } from './PopoverOptionComponent';

export function CompositionForm({
  composition,
  drawerRef,
}: {
  composition: Composition;
  drawerRef?: RefObject<HTMLElement>;
}) {
  const { t } = useTranslation();
  const {
    data: groups,
    refetch: refetchGroups,
    loading: groupsLoading,
  } = useApiResource(dimensionGroupsApi.getAll);

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(
    composition.dimension?.id,
  );

  const {
    performSubmit,
    form: {
      register,
      control,
      reset,
      formState: { errors, isSubmitting, isDirty },
    },
  } = useUpdateCompositionFormSubmitState();

  const { fields, replace } = useFieldArray({
    control,
    name: 'dimensions',
  });

  const [dimensionLabels, setDimensionLabels] = useState<string[]>(
    composition.dimension?.dimensions.map((x) => x.label) ?? [],
  );
  const { formatWeight } = useFormat();

  useEffect(() => {
    replace([]);
    reset({
      weight: composition.weight,
      dimensions: composition.dimension?.dimensions.map((x) => ({
        id: x.id,
        value: x.value,
      })),
    });
  }, [composition.dimension?.dimensions, composition.weight, reset, replace]);

  useEffect(() => {
    refetchGroups();
  }, [refetchGroups]);

  return (
    <>
      <form>
        <Flex flexDir="column" gap={2}>
          <TexasFormLabel mb={-2}>
            {t('configuration.dimensions')}
          </TexasFormLabel>
          <Flex flexDir="column" bg="texas.bg.800" p={2} borderRadius={6}>
            <Select
              isLoading={groupsLoading}
              isClearable={true}
              styles={reactSelectStyle<false, ReactSelectOptionWithDesc>()}
              theme={reactSelectTheme}
              value={{
                label:
                  groups?.find((p) => p.id === selectedGroupId)?.name ?? '',
                value: selectedGroupId,
                description: groups?.find((p) => p.id === selectedGroupId)
                  ?.description,
              }}
              onChange={(e) => {
                const group = groups?.find((x) => x.id === e?.value);

                if (!group) {
                  replace([]);
                  setSelectedGroupId(undefined);
                  return;
                }
                setSelectedGroupId(group.id);
                setDimensionLabels(group.dimensions.map((d) => d.name));
                replace(group.dimensions.map((x) => ({ id: x.id, value: '' })));
              }}
              options={
                groups?.map<ReactSelectOptionWithDesc>((x) => ({
                  value: x.id,
                  label: x.name,
                  description: x.description,
                  ref: drawerRef,
                })) ?? []
              }
              closeMenuOnSelect={true}
              components={{ Option: PopoverOptionComponent }}
            />
            <ErrorLabel text={errors.dimensions?.message} />
            {fields.length > 0 && (
              <Flex flexDir="column" p={2} gap={1}>
                {fields.map((x, i) => {
                  return (
                    <FormControl
                      isInvalid={!!errors.dimensions?.[i]?.value}
                      key={x.id}
                    >
                      <Flex alignItems="center" gap={2}>
                        <Text w="24">{dimensionLabels[i]}</Text>
                        <InputGroup>
                          <Input
                            {...register(`dimensions.${i}.value` as const, {
                              required: true,
                              maxLength: {
                                value: 50,
                                message: t('errors.maxLength', { count: 50 }),
                              },
                            })}
                            variant="outline"
                          />
                          <InputRightAddon pl={4}>mm</InputRightAddon>
                        </InputGroup>
                      </Flex>
                      <ErrorLabel
                        text={errors.dimensions?.[i]?.value?.message}
                      />
                    </FormControl>
                  );
                })}
              </Flex>
            )}
          </Flex>

          <TexasInputWrapper
            rightAddon="kg"
            label="Weight"
            error={errors.weight}
            bottomLabel={t('composition.totalWeight', {
              total: formatWeight(totalMaterialWeights(composition.materials)),
            })}
          >
            <Input
              {...register('weight', {
                setValueAs: (value) => setValueAsDecimalExtender(value, false),
                maxLength: {
                  value: 21,
                  message: t('errors.maxLength', { count: 21 }),
                },
                min: {
                  value: 0,
                  message: t('composition.canNotBeNegative'),
                },
              })}
              placeholder="0"
            />
          </TexasInputWrapper>
          <Button
            variant="texas-success-solid"
            onClick={() => performSubmit()}
            isLoading={isSubmitting}
            disabled={!isDirty}
            display={isDirty ? 'inherit' : 'none'}
            animation={fadeInScaleAnimation()}
            w="full"
          >
            {t('general.save')}
          </Button>
        </Flex>
      </form>
    </>
  );
}

function totalMaterialWeights(materials: CompositionMaterial[]) {
  let total = 0;

  for (let i = 0; i < materials.length; i++) {
    total += materials[i].weight;
  }

  return total;
}
