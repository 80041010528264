import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import {
  CreateQualityRequest,
  qualitiesApi,
} from '../../../api/endpoints/metadata/qualitiesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { productGroupsApi } from '@texas/api/endpoints/metadata/productGroupsApi';
import { useEffect } from 'react';
import Select from 'react-select';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';
import { SharedDisclosureProps } from '@texas/components/shared/types';

export interface CreateQualityProps extends SharedDisclosureProps {
  onCreated: () => void;
}

const defaultValues: CreateQualityRequest = {
  name: '',
  code: '',
  productGroupIds: [],
  inputField: false,
  childQualityIds: [],
};

export function CreateQuality({
  onCreated,
  onClose,
  isOpen,
}: CreateQualityProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createRequest, error } = useApiRequest(qualitiesApi.create);

  const {
    data: productGroups,
    refetch: refetchProductGroups,
    loading: productGroupsLoading,
  } = useApiResource(productGroupsApi.getProductGroups);

  const {
    data: qualities,
    refetch: refetchQualities,
    loading: qualitiesLoading,
  } = useApiResource(qualitiesApi.getAll);

  useEffect(() => {
    if (!isOpen) return;
    refetchProductGroups();
    refetchQualities({
      searchTerm: '',
      sortBy: '',
      sortDesc: false,
      includeArchived: false,
      preciseIdFiltering: true,
      productGroupId: null,
    });
  }, [isOpen, refetchProductGroups, refetchQualities]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateQualityRequest>({
    defaultValues: {
      inputField: false,
      code: '',
      name: '',
      childQualityIds: [],
      productGroupIds: [],
    },
  });

  const onSubmit = async (data: CreateQualityRequest) =>
    await request(
      createRequest,
      [data],
      () => {
        reset(defaultValues);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        onCreated();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isOpen={isOpen}
      variant="above-angular"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewQuality')}
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            {error && <ErrorDetails error={error} />}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <VStack>
                <FormControl isRequired={true} isInvalid={!!errors.code}>
                  <TexasFormLabel>{t('general.code')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('code', {
                      required: true,
                      maxLength: {
                        value: 6,
                        message: t('errors.maxLength', { count: 6 }),
                      },
                    })}
                    placeholder={t('general.code')}
                  />
                  <ErrorLabel text={errors.code?.message} />
                </FormControl>
                <FormControl isRequired={true} isInvalid={!!errors.name}>
                  <TexasFormLabel>
                    {t('configuration.qualityName')}
                  </TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('name', {
                      required: true,
                      maxLength: {
                        value: 30,
                        message: t('errors.maxLength', { count: 30 }),
                      },
                    })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.name?.message} />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>
                    {t('configuration.dynamicField')}
                  </TexasFormLabel>
                  <Controller
                    name="inputField"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox onChange={onChange} isChecked={value} />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>{t('general.productGroups')}</TexasFormLabel>
                  <Controller
                    name="productGroupIds"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isLoading={productGroupsLoading}
                        isClearable={true}
                        styles={reactSelectStyle<true>()}
                        theme={reactSelectTheme}
                        value={field.value?.map<ReactSelectOption>((x) => ({
                          label:
                            productGroups?.find((p) => p.id === x)?.value ?? '',
                          value: x,
                        }))}
                        isMulti={true}
                        onChange={(e) => field.onChange(e.map((x) => x.value))}
                        options={
                          productGroups?.map<ReactSelectOption>((x) => ({
                            value: x.id,
                            label: x.value,
                          })) ?? []
                        }
                        closeMenuOnSelect={false}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>
                    {t('configuration.groupedQualities')}
                  </TexasFormLabel>
                  <Controller
                    name="childQualityIds"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isLoading={qualitiesLoading}
                        isClearable={true}
                        styles={reactSelectStyle<true>()}
                        theme={reactSelectTheme}
                        value={field.value?.map<ReactSelectOption>((x) => ({
                          label: qualities?.find((p) => p.id === x)?.name ?? '',
                          value: x,
                        }))}
                        isMulti={true}
                        onChange={(e) => field.onChange(e.map((x) => x.value))}
                        options={
                          qualities?.map<ReactSelectOption>((x) => ({
                            value: x.id,
                            label: x.name,
                          })) ?? []
                        }
                        closeMenuOnSelect={false}
                      />
                    )}
                  />
                  <Text variant="small" pt={1}>
                    {t('configuration.groupedDesc')}
                  </Text>
                </FormControl>
                <SubmitButton loading={isSubmitting}>
                  {t('general.create')}
                </SubmitButton>
              </VStack>
            </form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
