import {
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { useEffect, useState } from 'react';
import { MatrixGrid } from '../../../../shared/matrix/MatrixGrid';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { Icons } from '@texas/components/shared/Icons';
import { SelectCellModal } from './SelectCellModal';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ConfirmCopy } from '../shared/ConfirmCopy';
import { formatNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';
import { useTranslation } from 'react-i18next';
import { GetColorFromCode } from '../pantonePicker/PantonePicker';
import { ColorDot } from '../shared/ColorDot';
import { onlyUnique } from '@texas/utils/helpers/commonHelpers';

export function MatrixView({
  variantId,
  articleNodeGroup,
  baseTemplateId,
  onCellSelected,
  selectedCell,
}: {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
  baseTemplateId: number | null;
  onCellSelected: (cell: [number, number, number, number]) => void;
  selectedCell: [number, number, number, number] | null;
}) {
  const { data, refetch, loading } = useApiResource(
    compositionApi.getAllCellCompositionTemplates,
  );

  const { request: createRequest } = useApiRequest(
    compositionApi.createVariantCellTemplate,
  );
  const { request: copyRequest } = useApiRequest(
    compositionApi.copyComposition,
  );

  const toast = useToast();
  const { t } = useTranslation();

  const [preservedCell, setPreservedCell] =
    useState<[number, number, number, number]>();
  const {
    onOpen: onCopyOpen,
    onClose: onCopyClose,
    isOpen: isCopyOpen,
    value,
  } = useValueDisclosure<{
    cell: [number, number, number, number];
    id: number | null;
    copyTitle: string;
  }>();

  const {
    onOpen: selectCellOpen,
    onClose: selectCellClose,
    isOpen: isSelectCellOpen,
  } = useDisclosure();

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  return (
    <Flex pl={2}>
      <MatrixGrid
        articleNodeGroup={articleNodeGroup}
        row={(y, _) => {
          const nodes = data?.filter((x) => x.y === y);
          const colors = nodes?.flatMap((x) => x.colorCodes).filter(onlyUnique);
          return (
            <Flex gap={2}>
              {colors?.map((c) => {
                const color = GetColorFromCode(c);

                if (!color) return;

                return <ColorDot key={color.code} hex={color.hex} />;
              })}
            </Flex>
          );
        }}
        cell={(x, y, xIndex, yIndex) => {
          const template = data?.find((c) => c.x === x && c.y === y);
          return template ? (
            <Button
              {...(selectedCell &&
              selectedCell[0] === x &&
              selectedCell[1] === y
                ? { backgroundColor: 'white', color: 'texas.bg.900' }
                : null)}
              width="full"
              onClick={() => onCellSelected([x, y, xIndex, yIndex])}
            >
              {t('composition.viewTemplate')}
            </Button>
          ) : (
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                isLoading={loading}
                rightIcon={<Icons.ArrowDownRight />}
                borderStyle="dashed"
              >
                {t('composition.createCellTemplate')}
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={async () => {
                    await request(
                      createRequest,
                      [variantId, x, y],
                      (_) => {
                        refetch(variantId);
                        onCellSelected([x, y, xIndex, yIndex]);
                        toast({
                          title: t('general.created'),
                          status: 'success',
                          isClosable: true,
                        });
                      },
                      (error: ServerError) => {
                        toast({
                          title: t('general.createFailed'),
                          description: error.message,
                          status: 'error',
                          isClosable: true,
                        });
                      },
                    );
                  }}
                >
                  {t('composition.emptyTemplate')}
                </MenuItem>
                <MenuItem
                  isDisabled={baseTemplateId === null}
                  onClick={() =>
                    onCopyOpen({
                      cell: [x, y, xIndex, yIndex],
                      id: baseTemplateId,
                      copyTitle: t('composition.fromVariantBase'),
                    })
                  }
                >
                  {t('composition.fromVariantBase')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPreservedCell([x, y, xIndex, yIndex]);
                    selectCellOpen();
                  }}
                >
                  {t('composition.fromAnotherCell')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }}
      />

      {value?.id && (
        <ConfirmCopy
          title={value.copyTitle}
          isOpen={isCopyOpen}
          onClose={onCopyClose}
          compositionId={value.id}
          onConfirm={async (id) => {
            onCopyClose();
            selectCellClose();
            await request(
              copyRequest,
              [
                id,
                {
                  variantId: variantId,
                  matrixNodeXId: value.cell[0],
                  matrixNodeYId: value.cell[1],
                  branchId: null,
                  supplierId: null,
                },
              ],
              (_) => {
                refetch(variantId);
                onCellSelected(value.cell);
              },
              (error: ServerError) => {
                toast({
                  title: t('general.createFailed'),
                  description: error.message,
                  status: 'error',
                  isClosable: true,
                });
              },
            );
          }}
        />
      )}

      <SelectCellModal
        loadCellDataRequest={{
          request: compositionApi.getAllCellCompositionTemplates,
          requestData: [variantId],
        }}
        selectedCell={(data, xIndex, yIndex) => {
          if (!data || !preservedCell) return;
          onCopyOpen({
            cell: preservedCell,
            id: data.id,
            copyTitle: t('composition.fromCell', {
              label: formatNodeGroup(
                articleNodeGroup.nodeXValues[xIndex],
                articleNodeGroup.nodeYValues[yIndex],
              ),
            }),
          });
        }}
        articleNodeGroup={articleNodeGroup}
        onClose={selectCellClose}
        isOpen={isSelectCellOpen}
      />
    </Flex>
  );
}
