import {
  Button,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import { exchangeRateCalculation } from '@texas/utils/helpers/numberHelpers';
import {
  profitColor,
  evaluateProfit,
  setValueAsDecimalExtender,
} from './shared';
import { request } from '@texas/utils/helpers/httpHelpers';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import {
  MassUpdateSalesCellsPricesResponse,
  tierPriceApi,
} from '@texas/api/endpoints/tierPriceApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import useFormat from '@texas/hooks/useFormat';
import { defaultNumberOfDecimals } from '@texas/resources/constants';
import { Margin } from './common/Margin';
import { Multiplied } from './common/Multiplied';
import { MatrixCell } from './MatrixView';

interface SetCellPricesProps {
  cells: MatricCellWithPrice[];
  purchaseInfo: PurchaseInformation;
  sellingExchangeRate: number;
  salesCurrency: SalesCurrency;
  tierPriceId: number;
  onClose: () => void;
  isOpen: boolean;
  onUpdate: (data: MassUpdateSalesCellsPricesResponse) => void;
}

export interface MatricCellWithPrice extends MatrixCell {
  currentPrice: number;
  purchasePriceWithFees: number;
}

export function SetCellPrices(props: SetCellPricesProps) {
  const [newCellPrices, setNewCellPrices] = useState(0);
  const { t } = useTranslation();
  const { numberFormat } = useFormat();

  const toast = useToast();
  const {
    request: remoteRequest,
    error,
    loading,
  } = useApiRequest(tierPriceApi.massUpdateCellsPrices);

  useEffect(() => {
    setNewCellPrices(0);
  }, [props.isOpen]);

  return (
    <Modal size="4xl" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="white">
          {t('variant.sales.setCellPricesForCurrency', {
            currency: props.salesCurrency.currencyCode,
          })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ErrorDetails error={error} />
          <VStack>
            <TableContainer>
              <Table
                size="sm"
                display="block"
                maxHeight="380px"
                overflowY="auto"
                overflowX="hidden"
                variant="striped"
                colorScheme="gray"
              >
                <Thead
                  pos="sticky"
                  top="0"
                  backdropFilter="blur(2px)"
                  bgGradient="linear(to-t, transparent, gray.700)"
                >
                  <Tr>
                    <Th>{t('branch.identifier')}</Th>
                    <Th>
                      {t('general.cell')} <br />
                      {t('variant.sales.purchasePrice')}
                    </Th>
                    <Th>
                      {t('general.current')} <br />
                      {t('variant.sales.cellPrice')}
                    </Th>
                    <Th fontWeight="bold">
                      {t('general.new')} <br />
                      {t('variant.sales.cellPrice')}
                    </Th>
                    <Th>{t('variant.sales.margin')}</Th>
                    <Th>{t('variant.sales.multiplier')}</Th>
                  </Tr>
                </Thead>
                <Tbody color="white">
                  {props.cells.map((c) => {
                    return (
                      <Tr key={`${c.matrixNodeXId}${c.matrixNodeYId}`}>
                        <Td>{c.identifier}</Td>
                        <Td>{`${numberFormat(
                          exchangeRateCalculation(
                            c.purchasePriceWithFees,
                            props.purchaseInfo.fixedExchangeRate,
                            props.salesCurrency.fixedExchangeRate,
                            defaultNumberOfDecimals,
                          ),
                        )} ${props.salesCurrency.currencyCode}`}</Td>
                        <Td color="gray.300">{`${numberFormat(
                          c.currentPrice,
                        )} ${props.salesCurrency.currencyCode}`}</Td>
                        <Td
                          fontWeight="bold"
                          color={profitColor(
                            evaluateProfit(
                              exchangeRateCalculation(
                                newCellPrices,
                                props.salesCurrency.fixedExchangeRate,
                                props.sellingExchangeRate,
                                defaultNumberOfDecimals,
                              ),
                              exchangeRateCalculation(
                                c.purchasePriceWithFees,
                                props.salesCurrency.fixedExchangeRate,
                                props.sellingExchangeRate,
                                defaultNumberOfDecimals,
                              ),
                            ),
                          )}
                        >
                          {`${newCellPrices} ${props.salesCurrency.currencyCode}`}
                        </Td>
                        <Td>
                          <Margin
                            salesCurrency={props.salesCurrency}
                            price={newCellPrices}
                            purchasePrice={c.purchasePriceWithFees}
                            purchaseInformation={props.purchaseInfo}
                            sellingExchangeRate={props.sellingExchangeRate}
                          />
                        </Td>
                        <Td>
                          <Multiplied
                            salesCurrency={props.salesCurrency}
                            price={newCellPrices}
                            purchasePrice={c.purchasePriceWithFees}
                            purchaseInformation={props.purchaseInfo}
                            sellingExchangeRate={props.sellingExchangeRate}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        </ModalBody>
        <ModalBody backgroundColor="gray.900">
          <HStack>
            <Text color="white" w="50%">
              {t('variant.sales.enterNewCellPrice')}
            </Text>
            <TexasInputWrapper rightAddon={props.salesCurrency.currencyCode}>
              <Input
                color="white"
                placeholder="0"
                variant="filled"
                onChange={(e) =>
                  setNewCellPrices(
                    setValueAsDecimalExtender(e.target.value, false),
                  )
                }
              />
            </TexasInputWrapper>
          </HStack>
          <Divider mt={5} />
        </ModalBody>

        <ModalFooter backgroundColor="gray.900">
          <Button variant="ghost" onClick={props.onClose}>
            {t('general.close')}
          </Button>
          <Button
            isDisabled={loading}
            isLoading={loading}
            colorScheme="orange"
            onClick={async () => {
              await performRequest();
            }}
            ml={3}
          >
            {t('variant.sales.setPrices')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  async function performRequest() {
    await request(
      remoteRequest,
      [
        props.tierPriceId,
        props.salesCurrency.id,
        {
          priceCells: props.cells.map((c) => ({
            price: newCellPrices,
            matrixNodeValueXId: c.matrixNodeXId,
            matrixNodeValueYId: c.matrixNodeYId,
          })),
        },
      ],
      (data) => {
        toast({
          title: t('variant.sales.cellPricesSet'),
          status: 'success',
          isClosable: true,
        });
        props.onUpdate(data);
        props.onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('variant.sales.failedToSetCellPrices'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}
