import { Card } from '@chakra-ui/react';

export function TemplateCard({
  children,
  exists,
  hightlight,
}: {
  children: React.ReactNode;
  exists: boolean;
  hightlight: boolean;
}) {
  return (
    <Card
      border="1px solid"
      borderColor={hightlight ? 'white' : 'texas.bg.700'}
      w="300px"
      h="700px"
      background="hsl(0deg 0% 18%)"
      overflowY="auto"
      {...(!exists
        ? { borderStyle: 'dashed', background: 'transparent' }
        : null)}
    >
      {children}
    </Card>
  );
}
