import {
  ArticleNodeGroup,
  ArticleNodeValue,
} from '@texas/api/endpoints/articlesApi';

export const emptyNodeGroup: ArticleNodeGroup = {
  nodeXValues: [],
  nodeYValues: [],
};

export function nodeGroupIsPopulated(group: ArticleNodeGroup | undefined) {
  if (!group) return false;
  return group.nodeXValues.length > 0 || group.nodeYValues.length > 0;
}

export function formatNodeGroup(
  nodeX: ArticleNodeValue,
  nodeY: ArticleNodeValue,
) {
  return `${nodeX.matrixNodeValue.identifier}:${
    nodeX.name ?? nodeX.matrixNodeValue.name
  } - ${nodeY.matrixNodeValue.identifier}:${
    nodeY.name ?? nodeY.matrixNodeValue.name
  }`;
}

export function formatSingleNodeGroup(node: ArticleNodeValue) {
  return `${node.matrixNodeValue.identifier}:${
    node.name ?? node.matrixNodeValue.name
  }`;
}
