import { FormControl } from '@chakra-ui/react';
import {
  ProductGroup,
  productGroupsApi,
} from '@texas/api/endpoints/metadata/productGroupsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';
import { ReactSelectOption } from '@texas/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

export function SelectProductGroup({
  selectedProductGroupId,
  onSelect,
}: {
  selectedProductGroupId: number | null;
  onSelect: (id: number) => void;
}) {
  const { t } = useTranslation();

  const { data: productGroups, refetch: refetchProductGroups } = useApiResource(
    productGroupsApi.getProductGroups,
  );
  useEffect(() => {
    refetchProductGroups();
  }, [refetchProductGroups]);

  return (
    <FormControl w={80}>
      <Select
        styles={reactSelectStyle<false>()}
        theme={(theme) => reactSelectTheme(theme)}
        placeholder={t('filter.allProductGroups')}
        onChange={(e) => {
          const id = parseInt(e?.value);
          onSelect(id);
        }}
        value={getProductGroup(productGroups, selectedProductGroupId)}
        options={productGroups
          ?.sort((a, b) => a.value.localeCompare(b.value))
          .map((p) => {
            return { label: p.value, value: p.id };
          })}
        isClearable={true}
      />
    </FormControl>
  );
}

function getProductGroup(
  productGroups: ProductGroup[] | null,
  id: number | null,
): ReactSelectOption | undefined {
  if (productGroups === null || id === null) return undefined;

  const productGroup = productGroups.find((p) => p.id === id);

  if (productGroup)
    return { label: productGroup.value, value: productGroup.id };
}
