import { Button, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import usePagination from './usePagination';
import { Icons } from '../../Icons';

interface PaginationProps {
  totalItems: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export function Pagination({
  totalItems,
  pageSize,
  currentPage,
  onPageChange,
}: PaginationProps) {
  const { items, hasPrev, hasNext } = usePagination({
    page: currentPage,
    count: Math.ceil(totalItems / pageSize),
    siblingCount: 1,
    boundaryCount: 1,
  });

  if (!totalItems) {
    return null;
  }

  if (totalItems <= pageSize) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      <PageButton
        disabled={!hasPrev}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <Icons.ArrowLeftThick />
      </PageButton>
      {items.map((item) =>
        item === 'start-ellipsis' || item === 'end-ellipsis' ? (
          <Ellipsis key={item} />
        ) : (
          <PageButton
            active={item === currentPage}
            key={item}
            onClick={() => onPageChange(item)}
          >
            {item}
          </PageButton>
        ),
      )}
      <PageButton
        disabled={!hasNext}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <Icons.ArrowRightThick />
      </PageButton>
    </Stack>
  );
}

const PageButton = ({
  active,
  disabled,
  children,
  onClick,
}: {
  active?: boolean;
  disabled?: boolean;
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <Button
      size="sm"
      variant="outline"
      onClick={onClick}
      disabled={disabled}
      isActive={active}
    >
      {children}
    </Button>
  );
};

const Ellipsis = () => {
  return (
    <Button
      size="sm"
      aria-hidden="true"
      variant="ghost"
      disabled={true}
      _disabled={{ cursor: 'default' }}
    >
      ...
    </Button>
  );
};
