import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { modalAnatomy as parts } from '@chakra-ui/anatomy';

const modalTheme = (() => {
  const helper = createMultiStyleConfigHelpers(parts.keys);

  const modalStyles = helper.definePartsStyle({
    header: {
      color: 'white',
    },
    dialog: {
      background: 'texas.bg.900',
    },
    dialogContainer: {
      color: 'white',
    },
  });

  return helper.defineMultiStyleConfig({ baseStyle: modalStyles });
})();

export default modalTheme;
