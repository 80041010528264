// @ngInject
const folderTree = () => ({
  restrict: 'EA',
  transclude: true,
  templateUrl: 'directives/fileBrowser/folderTree/folderTree.tpl.html',
  scope: {
    rootFolder: '=',
    selectedFolder: '=',
    options: '=',
    getFolder: '&',
  },
  controller: 'FolderTreeController',
  controllerAs: 'vm',
  bindToController: true,
  compile(tElement, tAttrs, tTranscludeFn) {
    return (scope, iElement, iAttrs, controller) => {
      controller.transcludeFn = tTranscludeFn;
    };
  },
});

class FolderTreeController {
  constructor($scope, DS) {
    'ngInject';

    this.$scope = $scope;
    this.options = this.options || {};
    this.options.onExpand = this.options.onExpand || angular.noop;
    this.options.onCollapse = this.options.onCollapse || angular.noop;
    this.options.onSelect = this.options.onSelect || angular.noop;
    this.options.expanded = false;

    this.expandedFolders = [];
    $scope.node = this.nodify(this.rootFolder, null, this.options);

    $scope.$watch(
      () => DS.lastModified('folder'),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          $scope.node = this.nodify(this.rootFolder, null, this.options);
        }
      },
    );

    $scope.$watch(
      () => this.selectedFolder.id,
      (newValue, oldValue) => {
        if (newValue !== oldValue && this.selectedFolder.parentId !== 0) {
          this.expandAncestor(this.selectedFolder.id);
        }
      },
    );
  }

  toggle($event, node) {
    if (node.collapsed) {
      this.expand($event, node);
    } else {
      this.collapse($event, node);
    }
  }

  expand($event, node) {
    this.addToExpandedFolders(node.$model.id);
    this.fillChildrenNodes(node);
    node.collapsed = false;
    this.options.onExpand($event, node);
  }

  collapse($event, node) {
    this.expandedFolders.splice(
      this.expandedFolders.indexOf(node.$model.id),
      1,
    );
    node.collapsed = true;
    this.options.onCollapse($event, node);
  }

  selectNode($event, node) {
    this.options.onSelect($event, node);
  }

  addToExpandedFolders(id) {
    if (this.expandedFolders.indexOf(id) === -1) {
      this.expandedFolders.push(id);
    }
  }

  fillChildrenNodes(node) {
    if (
      node.$model.hasFolders &&
      angular.isArray(node.$model.folders) &&
      node.$model.folders.length === 0
    ) {
      this.getFolder({ folderId: node.$model.id });
    }
  }

  nodify(node, parent, options) {
    const isRoot = parent === null && angular.isArray(node.folders);
    const vNode = {
      $model: node,
      $parent: parent,
      collapsed: this.expandedFolders.indexOf(node.id) === -1 && !isRoot,
    };
    vNode.$children = _.map(
      _.filter(node.folders || [], { archived: false }),
      (child) => this.nodify(child, vNode, options),
    );

    return vNode;
  }

  expandAncestor(nodeId) {
    this.expandParentNodes(this.findNode(this.$scope.node, nodeId));
  }

  expandParentNodes(node) {
    if (node && node.$parent) {
      node.$parent.collapsed = false;
      this.addToExpandedFolders(node.$parent.$model.id);
      this.expandParentNodes(node.$parent);
    }
  }

  findNode(node, id) {
    let result = null;

    if (node.$model.id === id) {
      result = node;
    } else {
      _.forEach(node.$children || [], (child) => {
        if (result === null) {
          result = this.findNode(child, id);
        }
      });
    }

    return result;
  }
}

// @ngInject
const folder = ($compile) => ({
  restrict: 'EA',
  replace: false,
  scope: false,
  templateUrl: 'directives/fileBrowser/folderTree/children.tpl.html',
  compile(tElement) {
    const template = tElement.clone();
    tElement.empty();
    return (scope, iElement) => {
      if (scope.node.$children.length) {
        iElement.append($compile(template.html())(scope));
      }
    };
  },
});

angular
  .module('folderTree', [])
  .controller('FolderTreeController', FolderTreeController)
  .directive('folderTree', folderTree)
  .directive('folder', folder);
