import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Badge,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { QualityListItem } from '@texas/api/endpoints/metadata/qualitiesApi';
import { Icons } from '@texas/components/shared/Icons';
import { IconWithBadge } from '@texas/components/shared/IconWithBadge';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';

export const qualityTableColumns = (
  t: TFunction,
  onArchiveOpen: (quality: QualityListItem) => void,
  onRestore: (id: number) => Promise<void>,
  onEditOpen: (id: number) => void,
  selectedProductGroupId: number,
) => {
  const columnHelper = createColumnHelper<QualityListItem>();
  const columns = [
    columnHelper.accessor('code', {
      cell: (info) => info.getValue(),
      header: t('general.code'),
      maxSize: 100,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.name'),
      maxSize: 200,
    }),
    columnHelper.accessor('inputField', {
      cell: (info) => (info.getValue() ? <Icons.Checkmark /> : null),
      header: t('configuration.dynamicField'),
      maxSize: 200,
    }),
    columnHelper.accessor('productGroups', {
      cell: (info) => (
        <Flex gap={2} flexWrap="wrap">
          {info.getValue().map((v) => (
            <Badge
              key={v.id}
              color={
                v.id === selectedProductGroupId ? 'orange' : 'texas.sand.50'
              }
            >
              {v.value}
            </Badge>
          ))}
        </Flex>
      ),
      header: t('general.productGroups'),
      enableSorting: false,
    }),
    columnHelper.accessor('childQualities', {
      cell: (info) => (
        <Flex gap={2} flexWrap="wrap">
          {info.getValue().map((v) => (
            <Badge key={v.id} color="texas.sand.50">
              {v.value}
            </Badge>
          ))}
        </Flex>
      ),
      header: t('configuration.groupedQualities'),
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip archived={info.row.original.archived} />
            )}
            <Menu placement="left">
              <MenuButton float="right">
                <Box>
                  <IconWithBadge
                    tooltipLabel="More"
                    icon={<Icons.DotsHorizontal boxSize={4} />}
                  />
                </Box>
              </MenuButton>
              <Portal>
                <MenuList color="white" zIndex={99}>
                  <MenuItem
                    icon={<Icons.Pencil boxSize={5} />}
                    onClick={() => onEditOpen(info.row.original.id)}
                  >
                    {t('general.edit')}
                  </MenuItem>
                  {info.row.original.archived ? (
                    <MenuItem
                      color="texas.sand.100"
                      icon={<Icons.ArchiveRemove boxSize={5} />}
                      onClick={() => onRestore(info.row.original.id)}
                    >
                      {t('general.restore')}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      color="texas.sand.50"
                      icon={<Icons.Archive boxSize={5} />}
                      onClick={() => onArchiveOpen(info.row.original)}
                    >
                      {t('general.archive')}
                    </MenuItem>
                  )}
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
