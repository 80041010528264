// @ngInject
const fileList = () => ({
  templateUrl: 'directives/fileBrowser/fileList/fileList.tpl.html',
  restrict: 'EA',
  replace: true,
  scope: {
    currentFolder: '=',
    options: '=',
    selectedItems: '=',
  },
  controller: 'FileListController',
  controllerAs: 'vm',
});

class FileListController {
  constructor(
    $scope,
    $filter,
    $location,
    UsersApiService,
    FilesApiService,
    AuthService,
  ) {
    'ngInject';

    this.$filter = $filter;
    this.$location = $location;
    this.UsersApiService = UsersApiService;
    this.FilesApiService = FilesApiService;

    this.options = {};
    this.currentFolder = { files: [], folders: [] };
    this.selectedItems = $scope.selectedItems;
    this.folders = [];
    this.files = [];
    this.fileTable = {};
    this.folderTable = {};
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);

    this.options.onFolderSelect = $scope.options.onFolderSelect || angular.noop;
    this.options.onFolderEdit = $scope.options.onFolderEdit || angular.noop;
    this.options.onFolderArchive =
      $scope.options.onFolderArchive || angular.noop;
    this.options.onFileEdit = $scope.options.onFileEdit || angular.noop;
    this.options.onFileArchive = $scope.options.onFileArchive || angular.noop;

    this.tableOptions = {
      order: 'name',
    };

    this.onOrderChange = (order) => {
      this.files = this.$filter('orderBy')(this.currentFolder.files, order);
      this.folders = this.$filter('orderBy')(this.currentFolder.folders, order);
    };

    $scope.$watchGroup(
      [
        'currentFolder.id',
        'currentFolder.files.length',
        'currentFolder.folders.length',
      ],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.onOrderChange(this.tableOptions.order);
          this.loadUserDisplayNames(this.files);
          this.loadUserDisplayNames(this.folders);
        }
      },
    );

    $scope.$watch(
      () => $scope.currentFolder.id,
      (newValue) => {
        if (newValue) {
          this.currentFolder = $scope.currentFolder;
        }
      },
    );
  }

  selectFolder(folder) {
    this.options.onFolderSelect(folder);
  }

  editFolder(folder) {
    this.options.onFolderEdit(folder);
  }

  archiveFolder(folder) {
    this.options.onFolderArchive(folder);
  }

  editFile(file) {
    this.options.onFileEdit(file);
  }

  archiveFile(file) {
    this.options.onFileArchive(file);
  }

  getFileUrl(file) {
    return this.FilesApiService.getFileUrl(file.identifier);
  }

  copyUrl(file) {
    let urlOrigin;
    if (!window.location.origin) {
      let port = this.$location.port();
      const protocol = this.$location.protocol();
      if (
        !port ||
        (protocol === 'http' && port === 80) ||
        (protocol === 'https' && port === 443)
      ) {
        port = '';
      }

      port = port ? `:${port}` : port;

      urlOrigin = `${protocol}//${this.$location.host()}${port}`;
    } else {
      urlOrigin = window.location.origin;
    }

    const url = `${urlOrigin}/#${this.$location.path()}?identifier=${
      file.identifier
    }`;
    const modifier =
      navigator.userAgent.toLowerCase().indexOf('mac') > 0 ? 'Cmd' : 'Ctrl';
    window.prompt(`Copy to clipboard: ${modifier}+C, Enter`, url);
  }

  loadUserDisplayNames(nodes) {
    _.forEach(nodes, (node) => {
      if (!_.isEmpty(node.creatorName)) {
        return;
      }

      if (!node.creatorId) {
        node.creatorName = '';
        return;
      }

      this.UsersApiService.getById(node.creatorId).then(
        (user) => (node.creatorName = `${user.firstName} ${user.lastName}`),
      );
    });
  }
}

angular
  .module('fileList', [])
  .directive('fileList', fileList)
  .controller('FileListController', FileListController);
