import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  Collapse,
  VStack,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import rhLogo from '@assets/rh_logo.png';
import navbar from '@assets/navbar_bg.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import placeholderImg from '@assets/placeholder-50.png';
import { searchApi } from '@texas/api/endpoints/searchApi';
import { ClaimType } from '@texas/api/endpoints/userApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';
import { ClaimRestrict } from '@texas/components/shared/ClaimRestrict';
import useChakraOutsideClick, {
  ModalState,
} from '@texas/hooks/useChakraOutsideClick';
import { useActiveSession } from '@texas/hooks/useSession';
import { ActionBar } from './ActionBar';

export function NavigationBar() {
  const { t } = useTranslation();
  const session = useActiveSession();

  return (
    <Flex pos="relative" flexDirection="column">
      <Flex
        w="100%"
        backgroundColor="texas.bg.800"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <NavigationContainer />
        <Box textAlign="center" py={3}>
          <Text fontSize={28} fontWeight="light">
            {t('navbar.welcome')} {session.currentUser.firstName}
          </Text>
        </Box>
      </Flex>
      <Image
        zIndex={0}
        pos="absolute"
        w={96}
        left="-3em"
        top="-50%"
        opacity="0.05"
        src={rhLogo}
      />
      <ActionBar />
    </Flex>
  );
}

function NavigationContainer() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: articles,
    refetch,
    set,
    loading,
    error,
  } = useApiResource(searchApi.searchArticles);
  const { ref, modalState, setModalState } =
    useChakraOutsideClick<HTMLDivElement>(ModalState.Closed);

  const showArticleList =
    modalState === ModalState.Open && searchTerm.length > 1;

  useEffect(() => {
    if (searchTerm.length > 1) {
      refetch(searchTerm);
    } else {
      set(null);
    }
  }, [searchTerm, refetch, set]);
  return (
    <Box pos="relative" ref={ref}>
      <ClaimRestrict
        allow={[ClaimType.SystemAdministrator, ClaimType.CompanyAdministrator]}
      >
        <Box
          pos="absolute"
          zIndex={2}
          left={0}
          right={0}
          top={0}
          bottom={0}
          filter="drop-shadow(1px 1px 4px rgba(0,0,0,0.2));"
        >
          <Box
            backgroundColor="texas.bg.700"
            zIndex={99}
            pos="relative"
            borderBottomRadius={26}
          >
            <Collapse in={showArticleList}>
              <VStack pt={20}>
                <Divider />

                <VStack
                  alignItems="start"
                  justifyContent="start"
                  w="100%"
                  px={16}
                  pb="20"
                  maxH={80}
                  overflowY="auto"
                >
                  {error !== null ? (
                    <Text>{t('general.unkownError')}</Text>
                  ) : (
                    <>
                      {articles?.results.map((a) => {
                        return (
                          <AngularNavLink
                            as={NavLink}
                            transition="color opacity 200ms ease"
                            _activeLink={{
                              color: 'texas.sand.100',
                              opacity: 1,
                            }}
                            to="article"
                            navObj={{ articleId: a.id }}
                            key={a.id}
                            w="100%"
                            opacity={0.6}
                            cursor="pointer"
                            onClick={() => {
                              setModalState(ModalState.Closed);
                            }}
                            _hover={{
                              opacity: 1,
                            }}
                          >
                            <HStack>
                              <Image
                                boxSize={16}
                                objectFit="contain"
                                src={`/api/files/${a.imageIdentifier}/preview/128`}
                                fallbackSrc={placeholderImg}
                              />
                              <VStack alignItems="start" spacing={0}>
                                <Text fontSize={16}>{a.itemCode}</Text>
                                <Text fontSize={12} color="gray.300">
                                  {a.name}
                                </Text>
                              </VStack>
                            </HStack>
                          </AngularNavLink>
                        );
                      })}
                    </>
                  )}
                </VStack>
                <HStack
                  opacity={showArticleList ? 1 : 0}
                  pointerEvents="none"
                  mr="auto"
                  pos="absolute"
                  left={26}
                  right={26}
                  bottom="0"
                  p={4}
                  backdropFilter="blur(2px)"
                >
                  {loading ? (
                    <Text>{t('general.loading')}</Text>
                  ) : (
                    <Text>
                      {articles === null || articles.total === 0
                        ? t('navbar.noArticlesFound')
                        : t('navbar.articlesFound', {
                            articles: articles.total,
                          })}
                    </Text>
                  )}

                  {loading && <Spinner />}
                </HStack>
              </VStack>
            </Collapse>
          </Box>
          <Box
            opacity={showArticleList ? 0 : 1}
            backgroundColor="texas.bg.700"
            transition="opacity 100ms ease"
            width="100%"
            height="100%"
            sx={{
              maskSize: '100% 100%',
              maskImage: `url(${navbar})`,
            }}
            pointerEvents="none"
          />
        </Box>
      </ClaimRestrict>

      <HStack py={4} px={16} spacing={8} zIndex={2} pos="relative">
        <HStack spacing={8}>
          <NavbarLink label={t('general.brands')} to="customers" />

          <ClaimRestrict
            allow={[
              ClaimType.SystemAdministrator,
              ClaimType.CompanyAdministrator,
            ]}
          >
            <NavbarLink label={t('general.suppliers')} to="suppliers" />
            <NavbarLink label={t('general.users')} to="users" />
            <NavbarLink label={t('general.articles')} to="searcharticles" />
          </ClaimRestrict>
        </HStack>

        <ClaimRestrict
          allow={[
            ClaimType.SystemAdministrator,
            ClaimType.CompanyAdministrator,
          ]}
        >
          <SearchFilterInput
            onFocus={() => setModalState(ModalState.Open)}
            value={searchTerm}
            onChange={(v) => setSearchTerm(v)}
          />
        </ClaimRestrict>
      </HStack>
    </Box>
  );
}

function NavbarLink({ label, to }: { label: string; to: string }) {
  return (
    <AngularNavLink
      as={NavLink}
      to={to}
      _activeLink={{ opacity: 1, fontWeight: 'bold' }}
      opacity={0.5}
    >
      {label}
    </AngularNavLink>
  );
}
