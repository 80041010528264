import { Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ConnectContactRequest,
  ContactListItem,
} from '@texas/api/endpoints/contactsApi';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { TFunction } from 'i18next';

export const contactsTableColumns = (
  t: TFunction,
  customerId: number,
  onRemove: (data: ConnectContactRequest) => Promise<void>,
) => {
  const columnHelper = createColumnHelper<ContactListItem>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.name'),
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: t('general.email'),
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip archived={info.row.original.archived} />
            )}
            <VerifyButton
              buttonProps={{ size: 'sm' }}
              label={t('general.remove')}
              onVerified={() =>
                onRemove({ id: info.row.original.id, referenceId: customerId })
              }
            />
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
