import { ChakraComponent, Link } from '@chakra-ui/react';
import { LinkProps } from 'react-router-dom';
import { reactEvents } from 'src/bridge/reactEvents';

export interface AngularNavLinkProps extends LinkProps {
  navObj?: any;
}

type LinkComponent = ChakraComponent<'a', AngularNavLinkProps>;

export const AngularNavLink = ((props: AngularNavLinkProps) => {
  return (
    <Link
      {...props}
      onClick={(e) => {
        if (props.onClick !== undefined) {
          props.onClick(e);
        }

        reactEvents.routingChanged.dispatch({
          to: props.to.toString(),
          navObj: props.navObj,
        });
      }}
    >
      {props.children}
    </Link>
  );
}) as LinkComponent;
