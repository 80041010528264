import { httpClient } from '@texas/api/httpClient/httpClient';

export interface Material {
  id: number;
  name: string;
  code: string;
  description: string;
}

export const materialsApi = {
  getAll: () => httpClient.get<Material[]>('organizations/3/materials'),
};
