import { Global } from '@emotion/react';

export const CustomFonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'swiss';
        src: url('/assets/fonts/swiss.woff2') format('woff2');
        font-style: normal;
        font-weight: 300;
      }

      @font-face {
        font-family: 'swiss';
        src: url('/assets/fonts/swiss.woff2') format('woff2');
        font-style: normal;
        font-weight: 400;
      }

      @font-face {
        font-family: 'swiss';
        src: url('/assets/fonts/swiss-bold.woff2') format('woff2');
        font-style: normal;
        font-weight: 700;
      }
`}
  />
);
