import { Divider, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { SalesWarning, profitColor } from './shared';
import { useTranslation } from 'react-i18next';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import { Icons } from '@texas/components/shared/Icons';

interface SalesCurrencyTabProps {
  salesCurrency: SalesCurrency;
  warnings?: SalesWarning[];
}

export function SalesCurrencyTab(props: SalesCurrencyTabProps) {
  if (props.warnings) {
    return (
      <Tooltip
        backgroundColor="texas.bg.800"
        border="texas.bg.darkBlue"
        label={<SaleWarningsComponent warnings={props.warnings} />}
      >
        <HStack>
          <InnerTab salesCurrency={props.salesCurrency} />
        </HStack>
      </Tooltip>
    );
  }
  return (
    <HStack>
      <InnerTab salesCurrency={props.salesCurrency} />
    </HStack>
  );
}

function InnerTab({ salesCurrency }: { salesCurrency: SalesCurrency }) {
  return (
    <>
      {salesCurrency.isMain && <Icons.Flag pr={1} boxSize={5} />}
      <Text>
        {salesCurrency.currencyCode} ({salesCurrency.fixedExchangeRate})
      </Text>
    </>
  );
}

function SaleWarningsComponent({ warnings }: { warnings: SalesWarning[] }) {
  const { t } = useTranslation();
  return (
    <VStack spacing={1} fontSize={12} fontWeight="bold">
      <Text variant="sub">{t('variant.sales.profitWarnings')}</Text>
      <Divider />
      {warnings.map((w) => {
        return (
          <Text key={w.field} color={profitColor(w.type)}>
            {w.field}
          </Text>
        );
      })}
    </VStack>
  );
}
