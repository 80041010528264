import {
  Text,
  VStack,
  Box,
  HStack,
  Spinner,
  useToast,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import { branchIsModifiedSinceSync } from '@texas/utils/helpers/texas/branchHelpers';
import { reactEvents } from 'src/bridge/reactEvents';
import { request } from '@texas/utils/helpers/httpHelpers';
import {
  variantsApi,
  FinalizeRequest,
  GarpData,
} from '@texas/api/endpoints/variantsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from '@texas/components/shared/Icons';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TexasDrawerBody } from '@texas/components/shared/drawer/TexasDrawerBody';
import { TexasDrawerFooter } from '@texas/components/shared/drawer/TexasDrawerFooter';
import { TexasDrawerSubHeader } from '@texas/components/shared/drawer/TexasDrawerSubHeader';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { useActiveSession } from '@texas/hooks/useSession';
import { HandleFinalizeErrorContainer } from './HandleFinalizeErrorContainer';
import { SelectSync } from './SelectSync';
import { SyncChanges } from './SyncChanges';
import { ArticleBranchComponent } from './ArticleBranch';
import FinalizeError from '@texas/api/dto/variant/finalizeErrorTypes';

interface SaveAndSyncContentProps {
  configuration: SaveAndSyncConfiguration;
  onSyncSuccess: () => void;
  drawerState: ModalState;
}

export interface SaveAndSyncConfiguration {
  allBranches: boolean;
  articleId: number;
  variantId: number;
  syncedVariantId?: number;
}

export function SaveAndSyncContent(props: SaveAndSyncContentProps) {
  const {
    data: articleBranches,
    refetch: refetchArticleBranches,
    loading: branchesLoading,
  } = useApiResource(variantsApi.getArticleBranches);

  const {
    request: finalizeRequest,
    loading: finalizeLoading,
    error: finalizeError,
  } = useApiRequest(variantsApi.finalize);

  const toast = useToast();
  const { t } = useTranslation();

  const {
    data: validations,
    refetch: refetchValidation,
    loading: loadingValidation,
    set: setValidations,
  } = useApiResource(variantsApi.validateVariant);

  const {
    data: syncChanges,
    refetch: refetchChanges,
    loading: changesLoading,
  } = useApiResource(variantsApi.getSyncChanges);

  const session = useActiveSession();
  const [syncBranches, setSyncBranches] = useState<number[]>([]);

  useEffect(() => {
    refetchArticleBranches(props.configuration.variantId);
  }, [props.configuration.variantId, refetchArticleBranches]);

  useEffect(() => {
    if (props.drawerState === ModalState.Open) {
      refetchChanges(props.configuration.variantId);
      refetchValidation(props.configuration.variantId);
    }
  }, [
    props.configuration.variantId,
    props.drawerState,
    refetchArticleBranches,
    refetchChanges,
    refetchValidation,
  ]);

  useEffect(() => {
    if (articleBranches) {
      if (props.configuration.allBranches) {
        setSyncBranches(
          articleBranches
            .filter((ab) => !ab.blockedFromSync)
            .map((ab) => ab.branchId),
        );
      } else {
        setSyncBranches(
          articleBranches
            .filter(
              (ab) =>
                !ab.blockedFromSync &&
                ab.branchId == session.currentUser.branchId,
            )
            .map((ab) => ab.branchId),
        );
      }
    }
  }, [
    props.configuration.allBranches,
    articleBranches,
    session.currentUser.branchId,
  ]);

  return (
    <>
      {finalizeError && <ErrorDetails error={finalizeError} />}
      <Box
        {...(finalizeLoading
          ? { pointerEvents: 'none', opacity: '0.5' }
          : null)}
      >
        {hasArticleErrors(validations) && (
          <>
            <TexasDrawerSubHeader
              title={t('variant.sync.articleSyncErrors')}
              subTitle={t('variant.sync.resolveErrors')}
            />
            <TexasDrawerBody>
              <VStack alignItems="start">
                {validations
                  ?.filter((v) => !v.branchId)
                  .map((v) => {
                    return (
                      <Box w="100%" key={v.title}>
                        <HStack>
                          <Icons.AlertCircle color="texas.danger.100" />
                          <Flex flexDir="column">
                            <Text fontWeight="bold">{v.title}</Text>
                            {v.content && (
                              <Text fontSize="sm">{v.content.content}</Text>
                            )}
                          </Flex>
                        </HStack>
                        <HandleFinalizeErrorContainer
                          validation={v}
                          onResolved={() => {
                            const set = validations.filter(
                              (va) => va.title !== v.title,
                            );
                            setValidations(set);
                          }}
                        />
                      </Box>
                    );
                  })}
              </VStack>
            </TexasDrawerBody>
          </>
        )}
        <TexasDrawerBody>
          <Heading
            textTransform="uppercase"
            fontFamily="swiss"
            color="gray.100"
            size="md"
            textAlign="center"
          >
            {t('variant.sync.selectBranches')}
          </Heading>
        </TexasDrawerBody>
        {branchesLoading && (
          <VStack opacity={0.6}>
            <Text>{t('variant.sync.preparingBranches')}</Text>
            <Spinner />
          </VStack>
        )}

        {articleBranches?.map((ab) => {
          if (branchIsModifiedSinceSync(ab.branchId, syncChanges)) {
            return (
              <Popover
                key={ab.branchId}
                trigger="hover"
                variant="responsive"
                placement="left-start"
              >
                <PopoverTrigger>
                  <Box>
                    <ArticleBranchComponent
                      key={ab.branchId}
                      branch={ab}
                      validations={validations}
                      loadingValidations={loadingValidation}
                      syncChangesLoading={changesLoading}
                      syncChanges={syncChanges?.find(
                        (s) => s.branchId === ab.branchId,
                      )}
                      toggled={syncBranches.some((b) => b === ab.branchId)}
                      disabled={hasArticleErrors(validations)}
                      onToggle={(state) => toggleSyncBranch(state, ab.branchId)}
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent color="white">
                  <PopoverArrow />
                  <PopoverHeader>
                    <Text fontWeight="bold">
                      {t('variant.sync.changesInBranch', {
                        branchName: ab.branchName,
                      })}
                    </Text>
                  </PopoverHeader>
                  <PopoverBody>
                    <SyncChanges
                      branch={ab}
                      syncChanges={syncChanges?.find(
                        (s) => s.branchId === ab.branchId,
                      )}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            );
          }
          return (
            <ArticleBranchComponent
              key={ab.branchId}
              branch={ab}
              validations={validations}
              loadingValidations={loadingValidation}
              syncChangesLoading={changesLoading}
              toggled={syncBranches.some((b) => b === ab.branchId)}
              disabled={hasArticleErrors(validations)}
              onToggle={(state) => toggleSyncBranch(state, ab.branchId)}
            />
          );
        })}
      </Box>

      <TexasDrawerFooter>
        {finalizeLoading ? (
          <VStack w="100%">
            <Heading
              textTransform="uppercase"
              fontFamily="swiss"
              color="gray.100"
              size="md"
            >
              {t('variant.sync.syncInProgress')}
            </Heading>
            <Spinner />
          </VStack>
        ) : (
          <SelectSync
            variantId={props.configuration.variantId}
            syncSelected={(branchIds) => {
              performFinalize(
                props.configuration.variantId,
                {
                  branchIds: branchIds,
                },
                () => {
                  props.onSyncSuccess();
                  refetchArticleBranches(props.configuration.variantId);
                },
              );
            }}
            syncedVariantId={props.configuration.syncedVariantId}
            isLoading={loadingValidation}
            allBranchIds={
              articleBranches
                ?.filter((ab) => !ab.blockedFromSync)
                ?.map((ab) => ab.branchId) ?? []
            }
            selectedBranchIds={syncBranches}
            validations={validations}
          />
        )}
      </TexasDrawerFooter>
    </>
  );

  function toggleSyncBranch(state: boolean, branchId: number) {
    if (state) {
      setSyncBranches((s) => [...s, branchId]);
    } else {
      setSyncBranches((s) => s.filter((s) => s !== branchId));
    }
  }

  async function performFinalize(
    variantId: number,
    data: FinalizeRequest,
    onSyncSuccess: () => void,
  ) {
    await request(
      finalizeRequest,
      [variantId, data],
      (data: GarpData) => {
        reactEvents.variantFinalized.dispatch(data);
        toast({
          title: t('variant.sync.success'),
          status: 'success',
          isClosable: true,
        });
        onSyncSuccess();
      },
      (error: ServerError) => {
        toast({
          title: t('variant.sync.failed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}

function hasArticleErrors(validations: FinalizeError[] | null) {
  return (validations?.filter((v) => !v.branchId) ?? []).length > 0;
}
