import { Tab, Text } from '@chakra-ui/react';
import { Step, StepState } from './types';
import { Icons } from '@texas/components/shared/Icons';

export function StepTab({ step }: { step: Step }) {
  return (
    <Tab
      boxSize="28"
      background={step.state.isActive ? 'gray.700' : 'transparent'}
      _hover={{ background: 'gray.600' }}
      flexDirection="column"
    >
      <StepStatus stepState={step.state} />
      <Text fontWeight="bold">{step.config.label}</Text>
    </Tab>
  );
}

function StepStatus({ stepState }: { stepState: StepState }) {
  if (stepState.isValid) {
    return <Icons.Checkmark boxSize="6" color="green.300" mb="0.5" />;
  } else {
    return <Icons.Close boxSize="6" color="red.300" mb="0.5" />;
  }
}
