import { SimpleEventDispatcher } from 'ste-simple-events';

export interface WidgetResizeEvent {
  widget: string;
  width: number;
  height: number;
}

export enum WidgetType {
  Brands = 'brands',
  Articles = 'articles',
}

export const widgetEvents = {
  resize: {
    [WidgetType.Brands]: new SimpleEventDispatcher<WidgetResizeEvent>(),
    [WidgetType.Articles]: undefined,
  },
};
