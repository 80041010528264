import { httpClient } from '@texas/api/httpClient/httpClient';
import { SearchQuery } from './types';

export interface Dimension {
  name: string;
  id: number;
  displayOrder: number;
}

export interface DimensionGroup {
  id: number;
  name: string;
  description: string;
  dimensions: Dimension[];
  archived: string | null;
}

export interface DimensionGroupListItem {
  id: number;
  name: string;
  description: string;
  dimensions: string[];
  archived: string | null;
}

export interface UpdateDimensionGroupRequest {
  name: string;
  description: string | undefined;
}

export interface CreateDimensionGroupRequest {
  name: string;
  description: string | undefined;
  dimensions: string[];
}

export const dimensionGroupsApi = {
  get: (id: number) =>
    httpClient.get<DimensionGroup>(`metadata/dimensiongroups/${id}`),
  getAll: () =>
    httpClient.get<DimensionGroup[]>(`metadata/dimensiongroups/all`),
  search: (params: SearchQuery) =>
    httpClient.get<DimensionGroupListItem[]>(
      `metadata/dimensiongroups/search`,
      {
        params,
      },
    ),
  create: (request: CreateDimensionGroupRequest) =>
    httpClient.post<DimensionGroup>(`metadata/dimensiongroups`, {
      data: request,
    }),
  update: (id: number, request: UpdateDimensionGroupRequest) =>
    httpClient.put<DimensionGroup>(`metadata/dimensiongroups/${id}`, {
      data: request,
    }),
  archive: (id: number) =>
    httpClient.put(`metadata/dimensiongroups/${id}/archive`),
  restore: (id: number) =>
    httpClient.put(`metadata/dimensiongroups/${id}/restore`),
};
