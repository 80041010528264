import { CardBody, Flex, Text, useToast, Box } from '@chakra-ui/react';
import {
  Composition,
  UpdateCompositionRequest,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { RefObject, useEffect, useState } from 'react';
import { CompositionView } from '../shared/CompositionView';
import { CompositionOverview } from '../shared/CompositionOverview';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';
import { useTranslation } from 'react-i18next';
import { useFormSubmitStore } from '../shared/useFormSubmit';
import { ViewState } from '../shared/types';
import { TemplateViewFooter } from '../shared/TemplateViewFooter';
import { TemplateCard } from '../shared/TemplateCard';
import { UpdateCompositionFormSubmitProvider } from '../shared/updateCompositionFormSubmitContext';

export function TemplateView({
  variantId,
  productGroupId,
  onLoaded,
  drawerRef,
}: {
  variantId: number;
  productGroupId: number;
  onLoaded?: (template: Composition | null) => void;
  drawerRef?: RefObject<HTMLElement>;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    data: composition,
    refetch,
    loading,
    set,
  } = useApiResource(compositionApi.getVariantTemplate);

  const { request: createRequest } = useApiRequest(
    compositionApi.createVariantTemplate,
  );
  const { request: updateRequest } = useApiRequest(
    compositionApi.updateComposition,
  );

  const [state, setState] = useState<ViewState>('view');

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  useEffect(() => {
    if (!onLoaded) return;
    onLoaded(composition);
  }, [composition, onLoaded]);

  const formState = useFormSubmitStore({
    defaultValues: {
      weight: composition?.weight,
      dimensions: composition?.dimension?.dimensions.map((x) => ({
        id: x.id,
        value: x.value,
      })),
    },
    submit: async (data: UpdateCompositionRequest) => {
      if (!composition) return;

      await request(
        updateRequest,
        [composition.id, data],
        (response) => {
          set(response);
          toast({
            title: t('general.updated'),
            status: 'success',
            isClosable: true,
          });
        },
        (error: ServerError) => {
          toast({
            title: t('general.updateFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
  });

  return (
    <Flex flexDir="column" gap={2} w="fit-content">
      <UpdateCompositionFormSubmitProvider value={formState}>
        <Text textAlign="center">{t('composition.baseTemplate')}</Text>
        <TemplateCard
          exists={composition?.exists ?? false}
          hightlight={state === 'edit'}
        >
          <CardBody p={0}>
            <LoadingOverlayV2 loaded={!loading}>
              {composition && (
                <>
                  {state === 'view' && (
                    <CompositionView
                      composition={composition}
                      archiveDisabled={true}
                      onRestore={(response) => set(response)}
                    />
                  )}
                  {state === 'edit' && (
                    <Box p={2}>
                      <CompositionOverview
                        drawerRef={drawerRef}
                        onUpdate={() => refetch(variantId)}
                        composition={composition}
                        productGroupId={productGroupId}
                        onClose={() => setState('view')}
                      />
                    </Box>
                  )}
                </>
              )}
            </LoadingOverlayV2>
          </CardBody>
        </TemplateCard>
        <TemplateViewFooter
          state={state}
          setState={setState}
          onCreated={(response) => set(response)}
          onArchive={(response) => set(response)}
          onReplaced={null}
          apiRequest={createRequest}
          data={[variantId]}
          composition={composition}
          loading={loading}
          canBeReplaced={false}
          locationData={{ variantId }}
          baseTemplateId={null}
        />
      </UpdateCompositionFormSubmitProvider>
    </Flex>
  );
}
