import {
  VariantBranchSupplier,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface RemoveSupplierDialogProps extends SharedDisclosureProps {
  supplier: VariantBranchSupplier;
  onRemove: () => void;
}

export function RemoveSupplierDialog(props: RemoveSupplierDialogProps) {
  const { t } = useTranslation();

  return (
    <VerifyDialogWithRequest
      headerTitle={t('general.remove')}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={variantsApi.removeSupplierBranch}
      args={[
        props.supplier.variantId,
        props.supplier.branchId,
        props.supplier.supplierId,
      ]}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPerformed={props.onRemove}
      onSuccessTitle={t('general.successfullyRemoved')}
      onFailureTitle={t('general.removeFailed')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
