import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import {
  nodeGroupIsPopulated,
  formatNodeGroup,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { useTranslation } from 'react-i18next';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../../../types';

export function ProductApprovalList({
  selectedKey,
  nodeGroups,
  productApprovals,
  onClick,
  onHandle,
}: {
  selectedKey: string;
  nodeGroups: ArticleNodeGroup | undefined;
  productApprovals: GeneratedProductApproval[];
  onClick: (key: string) => void;
  onHandle: (key: string, state: GeneratedProductApprovalState) => void;
}) {
  const { t } = useTranslation();
  if (productApprovals.length === 0) {
    return (
      <Text color="gray.300" textAlign="center">
        {t('wizard.productApproval.noProductApprovals')}
      </Text>
    );
  }
  return (
    <Table
      variant="unstyled"
      width="full"
      __css={{ 'th, td': { p: '4px', fontSize: '14px' } }}
    >
      <Thead border="none">
        <Tr>
          <Th />
          <Th />
          {nodeGroupIsPopulated(nodeGroups) && <Th />}
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {productApprovals.map((x) => {
          return (
            <Tr
              _hover={{ backgroundColor: 'texas.bg.700' }}
              onClick={() => onClick(x.key)}
              key={x.key}
              backgroundColor={
                selectedKey === x.key ? 'texas.bg.700' : 'transparent'
              }
            >
              <Td>
                <Text>{x.supplier.name}</Text>
              </Td>
              <Td>
                <Text>{x.supplier.branch.identifier}</Text>
              </Td>
              {nodeGroupIsPopulated(nodeGroups) && (
                <Td>
                  {formatNodeGroup(
                    nodeGroups!.nodeXValues[x.cell!.xIndex],
                    nodeGroups!.nodeYValues[x.cell!.yIndex],
                  )}
                </Td>
              )}
              <Td>
                <Flex>
                  <Button
                    colorScheme={getColor(x.state)}
                    borderRightRadius="none"
                    isDisabled={!validProductApproval(x)}
                    onClick={(e) => {
                      onHandle(x.key, GeneratedProductApprovalState.Accepted);
                      e.stopPropagation();
                    }}
                  >
                    {t('wizard.productApproval.ok')}
                  </Button>
                  <Button
                    borderLeftRadius="none"
                    onClick={(e) => {
                      onHandle(x.key, GeneratedProductApprovalState.Ignored);
                      e.stopPropagation();
                    }}
                  >
                    {t('wizard.productApproval.ignore')}
                  </Button>
                </Flex>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

function validProductApproval(pa: GeneratedProductApproval) {
  return (
    pa.customerContact !== null &&
    pa.composition !== null &&
    pa.supplierContact !== null
  );
}

function getColor(state: GeneratedProductApprovalState) {
  if (state === GeneratedProductApprovalState.Accepted) {
    return 'green';
  }

  return 'gray';
}
