import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Fade,
  Flex,
  HStack,
  SlideFade,
  Tooltip,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import navbar from '@assets/navbar_bg.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ClaimType } from '@texas/api/endpoints/userApi';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';
import { ClaimRestrict } from '@texas/components/shared/ClaimRestrict';
import { Icons } from '@texas/components/shared/Icons';
import { IconWithBadge } from '@texas/components/shared/IconWithBadge';
import useChakraOutsideClick, {
  ModalState,
  reverseModalState,
} from '@texas/hooks/useChakraOutsideClick';
import { angularEvents } from 'src/bridge/angularEvents';
import { reactEvents } from 'src/bridge/reactEvents';

const actionBarIconSize = 6;

// Since these views are rendered and handled in angular, we cannot map the data
// until we fetch the data in react and map ids -> names inside a context
const maskedBreadcrumbs = [
  {
    path: 'articles',
    children: [
      { path: ':id', breadcrumb: null },
      { path: ':id/variants', breadcrumb: null },
      { path: ':id/variants/:id', breadcrumb: null },
      { path: ':id/variants/:id/general', breadcrumb: null },
    ],
    breadcrumb: null,
  },
  {
    path: 'suppliers',
    children: [{ path: ':id', breadcrumb: null }],
  },
  {
    path: 'customers',
    children: [{ path: ':id', breadcrumb: null }],
  },
  {
    path: 'concepts',
    breadcrumb: null,
    children: [{ path: ':id', breadcrumb: null }],
  },
  {
    path: 'searcharticles',
    breadcrumb: 'Search Articles',
  },
];

export function ActionBar() {
  const breadcrumbs = useBreadcrumbs(maskedBreadcrumbs);
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState(false);

  useEffect(() => {
    return angularEvents.pollReceived.subscribe((count) => {
      setNotifications(count > 0);
    });
  });
  return (
    <Flex w="100%" justifyContent="center">
      <Box pos="relative">
        <SlideFade offsetX={20} offsetY={0} in={true}>
          <Breadcrumb
            fontWeight="bold"
            fontSize="sm"
            color="gray.500"
            pos="absolute"
            right="100%"
            whiteSpace="pre"
          >
            {breadcrumbs.map(({ match, breadcrumb }, i) => (
              <BreadcrumbItem
                key={match.pathname}
                opacity={i < breadcrumbs.length - 1 ? 0.5 : 1}
              >
                <BreadcrumbLink
                  isCurrentPage={i < breadcrumbs.length - 1}
                  href={`/#${match.pathname}`}
                >
                  {breadcrumb}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </SlideFade>
        <Box pos="relative" w="25em">
          <Box
            zIndex="0"
            pos="absolute"
            h="100%"
            w="100%"
            sx={{
              maskSize: '100% 100%',
              maskImage: `url(${navbar})`,
            }}
            backgroundColor="texas.bg.800"
          />
          <HStack
            justifyContent="center"
            alignItems="center"
            padding={0.5}
            transform="translateY(-0.6em)"
            zIndex="1"
            pos="relative"
            css={{
              svg: {
                opacity: 0.6,
                cursor: 'pointer',
              },
              'a:hover, svg:hover': {
                opacity: 1,
              },
            }}
          >
            <Tooltip label={t('general.account')}>
              <Icons.AccountCircle
                onClick={() => {
                  reactEvents.accountConfigurationClicked.dispatch();
                }}
                boxSize={actionBarIconSize}
              />
            </Tooltip>
            <AngularNavLink as={ReactLink} to="tasks">
              <IconWithBadge
                tooltipLabel={t('general.tasks')}
                badge={notifications}
                icon={<Icons.CalendarCheck boxSize={actionBarIconSize} />}
              />
            </AngularNavLink>
            <HiddenActions />
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
}

export function HiddenActions() {
  const { ref, modalState, setModalState } =
    useChakraOutsideClick<HTMLDivElement>(ModalState.Closed);
  const { t } = useTranslation();
  return (
    <Box pos="relative" ref={ref}>
      <Tooltip label={t('general.more')}>
        <Icons.DotsHorizontal
          pos="relative"
          zIndex="1"
          onClick={() => {
            setModalState(reverseModalState(modalState));
          }}
          boxSize={actionBarIconSize}
        />
      </Tooltip>
      <Box
        zIndex="0"
        pos="absolute"
        top="0"
        // Calculation for correct offset
        left="calc(-100% + 32px - 0.5rem - 4px)"
      >
        <Fade in={modalState === ModalState.Open}>
          {modalState === ModalState.Open && (
            <Box
              borderRadius={22}
              backgroundColor="texas.bg.700"
              px={2}
              pl="32px"
            >
              <HStack>
                <ClaimRestrict
                  allow={[
                    ClaimType.SystemAdministrator,
                    ClaimType.CompanyAdministrator,
                  ]}
                >
                  <AngularNavLink as={ReactLink} to="configuration">
                    <Tooltip label={t('actionBar.configuration')}>
                      <Icons.Cog boxSize={actionBarIconSize} />
                    </Tooltip>
                  </AngularNavLink>

                  <AngularNavLink as={ReactLink} to="archive">
                    <Tooltip label={t('actionBar.archive')}>
                      <Icons.Archive boxSize={actionBarIconSize} />
                    </Tooltip>
                  </AngularNavLink>
                </ClaimRestrict>

                <Button
                  variant="unstyled"
                  boxSize="auto"
                  minW="auto"
                  onClick={() => {
                    reactEvents.signedOut.dispatch();
                  }}
                >
                  <Tooltip label={t('actionBar.signOut')}>
                    <Icons.Logout boxSize={actionBarIconSize} />
                  </Tooltip>
                </Button>
              </HStack>
            </Box>
          )}
        </Fade>
      </Box>
    </Box>
  );
}
