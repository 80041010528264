import {
  Button,
  Flex,
  useDisclosure,
  Text,
  Box,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  Composition,
  CompositionMaterial,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { CreateCompositionTemplate } from '../standard/CreateCompositionTemplate';
import { UpdateCompositionMaterial } from './UpdateCompositionMaterial';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { GetColorFromCode } from '../pantonePicker/PantonePicker';
import useFormat from '@texas/hooks/useFormat';
import { CompositionForm } from './CompositionForm';
import { fadeInRightAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { ColorDot } from './ColorDot';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { RefObject } from 'react';

interface CompositionOverviewProps {
  composition: Composition;
  productGroupId: number;
  onUpdate: () => void;
  onClose: () => void;
  drawerRef?: RefObject<HTMLElement>;
}

export function CompositionOverview({
  composition,
  productGroupId,
  onUpdate,
  drawerRef,
}: CompositionOverviewProps) {
  const { t } = useTranslation();

  const {
    isOpen: editIsOpen,
    value,
    onClose: editOnClose,
    onOpen: editOnOpen,
  } = useValueDisclosure<CompositionMaterial>();

  return (
    <>
      {value && (
        <UpdateCompositionMaterial
          onUpdate={() => {
            onUpdate();
          }}
          compositionMaterialId={value.id}
          productGroupId={productGroupId}
          isOpen={editIsOpen}
          onClose={editOnClose}
          drawerRef={drawerRef}
        />
      )}
      <Flex flexDir="column" gap={2} animation={fadeInRightAnimation()}>
        <CompositionForm composition={composition} drawerRef={drawerRef} />
        <Text fontWeight="bold">{t('general.materials')}</Text>
        <Grid rowGap={2} gridAutoRows="auto" gridAutoColumns="100%">
          {composition.materials.map((x) => {
            return (
              <GridItem key={x.id}>
                <CompositionMaterialView
                  onClick={() => editOnOpen(x)}
                  material={x}
                  onRemoved={() => onUpdate()}
                />
              </GridItem>
            );
          })}
          <GridItem>
            <AddMaterial
              compositionId={composition.id}
              productGroupId={productGroupId}
              onCreate={() => {
                onUpdate();
              }}
              drawerRef={drawerRef}
            />
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
}

function CompositionMaterialView({
  material,
  onClick,
  onRemoved,
}: {
  material: CompositionMaterial;
  onClick: () => void;
  onRemoved: () => void;
}) {
  const { formatWeight } = useFormat();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      bg="texas.bg.700"
      p={2}
      h="auto"
      role="group"
      borderRadius="md"
    >
      <Button
        boxSize="full"
        onClick={() => onClick()}
        variant="unstyled"
        _hover={{ opacity: 0.7 }}
      >
        <Flex flexDir="column" gap={2} alignItems="center">
          <Box>
            <Text>{material.material.text}</Text>
            <Text
              overflowWrap="anywhere"
              noOfLines={3}
              fontSize="sm"
              fontWeight="normal"
              color="gray.300"
              css={{ textWrap: 'wrap' }}
            >
              {material.note}
            </Text>
          </Box>

          <Flex fontSize="sm" fontWeight="medium" gap={2}>
            <DottedArray
              values={[
                {
                  amount: material.qualities.length,
                  label: t('configuration.qualities'),
                },
                {
                  amount: material.treatments.length,
                  label: t('configuration.treatments'),
                },
                {
                  amount: material.techniques.length,
                  label: t('general.techniques'),
                },
              ]}
            />
            <Text fontSize="sm" fontWeight="medium" color="gray.200">
              {formatWeight(material.weight)}
            </Text>
          </Flex>
          <Flex gap={2}>
            {material.colors.map((x) => {
              const color = GetColorFromCode(x.pantoneCode);

              if (!color) return null;
              return <ColorDot key={color.name} hex={color.hex} />;
            })}
          </Flex>
        </Flex>
      </Button>
      <Button
        variant="link"
        size="sm"
        colorScheme="red"
        _groupHover={{ opacity: 1 }}
        opacity={0}
        onClick={() => onOpen()}
      >
        Remove
      </Button>
      <VerifyDialogWithRequest
        headerTitle={t('composition.remove')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionApi.removeCompositionMaterial}
        args={[material.id]}
        isOpen={isOpen}
        onClose={onClose}
        onPerformed={onRemoved}
        onSuccessTitle={t('general.successfullyRemoved')}
        onFailureTitle={t('general.removeFailed')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
    </Flex>
  );
}

function DottedArray({
  values,
}: {
  values: { amount: number; label: string }[];
}) {
  return (
    <>
      {values.map((x) => {
        if (x.amount === 0) return null;
        return (
          <Text
            key={x.label}
            fontSize="sm"
            fontWeight="medium"
            color="gray.200"
          >
            {x.amount} {x.label}
          </Text>
        );
      })}
    </>
  );
}

function AddMaterial({
  compositionId,
  productGroupId,
  onCreate,
  drawerRef,
}: {
  compositionId: number;
  productGroupId: number;
  onCreate: (data: CompositionMaterial) => void;
  drawerRef?: RefObject<HTMLElement>;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <CreateCompositionTemplate
        onCreate={onCreate}
        compositionId={compositionId}
        productGroupId={productGroupId}
        isOpen={isOpen}
        onClose={onClose}
        drawerRef={drawerRef}
      />
      <Button
        minH="20"
        boxSize="full"
        variant="outline"
        borderStyle="dashed"
        onClick={() => onOpen()}
      >
        {t('composition.addMaterial')}
      </Button>
    </>
  );
}
