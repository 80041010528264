import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import {
  ProductGroup,
  UpdateProductGroupRequest,
  productGroupsApi,
} from '@texas/api/endpoints/metadata/productGroupsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';

export interface EditProductGroupProps extends SharedDisclosureProps {
  onUpdated: (productGroup: ProductGroup) => void;
  productGroupId: number | null;
}

export function EditProductGroup({
  onUpdated,
  productGroupId,
  onClose,
  isOpen,
}: EditProductGroupProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('configuration.editProductGroup')}</ModalHeader>
        {productGroupId && (
          <Form
            productGroupId={productGroupId}
            onClose={onClose}
            onUpdated={onUpdated}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  productGroupId,
  onClose,
  onUpdated,
}: {
  productGroupId: number;
  onClose: () => void;
  onUpdated: (productGroup: ProductGroup) => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: updateRequest, error } = useApiRequest(
    productGroupsApi.update,
  );

  const { data: productGroup, refetch: refetchProductGroup } = useApiResource(
    productGroupsApi.get,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateProductGroupRequest>();

  useEffect(() => {
    refetchProductGroup(productGroupId);
  }, [reset, refetchProductGroup, productGroupId]);

  useEffect(() => {
    reset({
      value: productGroup?.value,
      code: productGroup?.code,
      displayOrder: productGroup?.displayOrder,
    });
  }, [productGroup, reset]);

  const onSubmit = async (data: UpdateProductGroupRequest) =>
    await request(
      updateRequest,
      [productGroup!.id, data],
      (data) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(data);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Flex direction="column" gap={4}>
            <FormControl isRequired={true} isInvalid={!!errors.code}>
              <TexasFormLabel>{t('general.code')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('code', {
                  required: true,
                  maxLength: {
                    value: 2,
                    message: t('errors.maxLength', { count: 2 }),
                  },
                })}
                placeholder={t('general.code')}
              />
              <ErrorLabel text={errors.code?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.value}>
              <TexasFormLabel>{t('general.value')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('value', {
                  required: true,
                  maxLength: {
                    value: 100,
                    message: t('errors.maxLength', { count: 100 }),
                  },
                })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.value?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.displayOrder}>
              <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
              <Input
                type="number"
                variant="outline"
                {...register('displayOrder', {
                  required: true,
                  valueAsNumber: true,
                })}
                placeholder={t('branch.displayOrder')}
              />
              <ErrorLabel text={errors.displayOrder?.message} />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <SubmitButton loading={isSubmitting}>
            {t('general.update')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
