// @ngInject
const SupplierDialogService = ($mdDialog) => ({
  showDialog(data) {
    data = data || {};
    data.organizationId = data.organizationId || null;

    return $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'SupplierDialogController as vm',
      templateUrl: 'services/dialogs/supplierDialog/supplierDialog.tpl.html',
      locals: { organizationId: data.organizationId },
    });
  },
});

class SupplierDialogController {
  constructor($mdDialog, SuppliersApiService, organizationId, toasterService) {
    'ngInject';

    this.validationErrors = [];
    this.supplier = {};
    this._$mdDialog = $mdDialog;
    this._SuppliersApiService = SuppliersApiService;
    this._organizationId = organizationId;
    this._toasterService = toasterService;
  }

  save() {
    this.validationErrors = [];
    this._$mdDialog.startLoadingSpinner();
    this._SuppliersApiService
      .newSupplier(this.supplier, this._organizationId)
      .then(
        (newSupplier) => {
          this._toasterService.success();
          this._$mdDialog.hide(newSupplier);
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.supplierDialog', [])
  .service('SupplierDialogService', SupplierDialogService)
  .controller('SupplierDialogController', SupplierDialogController);
