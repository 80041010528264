import { Flex, Button, Text } from '@chakra-ui/react';
import { SupplierListItem } from '@texas/api/endpoints/suppliersApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function SuppliersList({
  suppliers,
  onClick,
}: {
  suppliers: SupplierListItem[];
  onClick: (supplierId: number) => Promise<void>;
}) {
  return (
    <Flex flexDir="column" gap={2} p={2}>
      {suppliers.map((x) => (
        <SupplierListItemComponent supplier={x} key={x.id} onClick={onClick} />
      ))}
    </Flex>
  );
}

function SupplierListItemComponent({
  supplier,
  onClick,
}: {
  supplier: SupplierListItem;
  onClick: (supplierId: number) => Promise<void>;
}) {
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Button
      isLoading={isAdding}
      loadingText={t('general.adding')}
      height="auto"
      py={2}
      isDisabled={supplier.alreadyAdded}
      onClick={async () => {
        setIsAdding(true);
        await onClick(supplier.id);
        setIsAdding(false);
      }}
      alignItems="start"
      variant="solid"
      display="flex"
      flexDir="row"
      {...(supplier.alreadyAdded
        ? { borderLeft: '6px solid', borderLeftColor: 'texas.sand.100' }
        : null)}
    >
      <Flex
        flexGrow={1}
        flexDir="column"
        align="start"
        textAlign="start"
        whiteSpace="normal"
      >
        <Text variant="main">{supplier.name}</Text>
        <Flex gap={2}>
          {supplier.activeArticles > 0 && (
            <Text variant="small">
              {t('suppliers.activeArticles', {
                count: supplier.activeArticles,
              })}
            </Text>
          )}

          {supplier.alreadyAdded && (
            <Text variant="small" color="texas.sand.100">
              {t('general.added')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Button>
  );
}
