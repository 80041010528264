import {
  InputGroup,
  InputRightAddon,
  HStack,
  Box,
  Input,
  FormControl,
} from '@chakra-ui/react';
import {
  profitColor,
  evaluateProfit,
  roundedPurchasePriceWithFees,
  setValueAsDecimalExtender,
} from '../shared';
import { useEffect } from 'react';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AutoUpdateResponseCallback,
  Position,
} from '@texas/components/shared/autoUpdate/types';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { exchangeRateCalculation } from '@texas/utils/helpers/numberHelpers';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import {
  SalesCellPrice,
  SalesPrice,
  AutoUpdateSalesCellPrice,
  AutoUpdateSalesCellPriceAdditionalData,
  tierPriceApi,
  EMPTY_AUTO_UPDATE_SALES_PRICE_OBJECT,
} from '@texas/api/endpoints/tierPriceApi';
import { defaultNumberOfDecimals } from '@texas/resources/constants';
import { Margin } from '../common/Margin';
import { Multiplied } from '../common/Multiplied';

interface SalesCellPriceFormProps {
  salesCell?: SalesCellPrice;
  salesPrice?: SalesPrice;
  purchasePrice: number;
  tierPriceId: number;
  cellX: number;
  cellY: number;
  salesCurrency: SalesCurrency;
  purchaseInformation: PurchaseInformation;
  sellingExchangeRate: number;
  showCurrencyCode?: boolean;
  isDisabled?: boolean;
  onUpdate?: (cell: SalesCellPrice) => void;
}

const schema = object({
  price: number(),
}).required();

export function SalesCellPriceForm({
  showCurrencyCode = true,
  purchasePrice,
  tierPriceId,
  cellX,
  cellY,
  salesCell,
  salesCurrency,
  purchaseInformation,
  sellingExchangeRate,
  salesPrice,
  onUpdate,
  isDisabled = false,
}: SalesCellPriceFormProps) {
  const {
    registerAutoUpdate,
    watch,
    getValues,
    resetValue,
    setValueId,
    form,
    register,
    triggerSubmit,
  } = useAutoUpdateForm<
    AutoUpdateSalesCellPrice,
    SalesCellPrice,
    AutoUpdateSalesCellPriceAdditionalData
  >({
    defaultValue: {
      price: salesCell?.price ?? salesPrice?.price ?? 0,
    },
    valueId: salesCell?.id ?? 0,
    namePrefix: 'salesCellPrice',
    autoUpdateRequest: tierPriceApi.autoUpdateSalesCellPrice,
    fallbackMapObject: EMPTY_AUTO_UPDATE_SALES_PRICE_OBJECT,
    additionalData: {
      tierPriceId: tierPriceId,
      salesCurrencyId: salesCurrency.id,
      matrixNodeValueXId: cellX,
      matrixNodeValueYId: cellY,
    },
    resolver: yupResolver(schema),
    onResponse,
  });

  function onResponse(
    data: AutoUpdateResponseCallback<SalesCellPrice, AutoUpdateSalesCellPrice>,
  ) {
    setValueId(data.unmodifiedServerData.id);

    if (onUpdate)
      onUpdate({ ...data.unmodifiedServerData, ...data.mappedServerData });
  }

  useEffect(() => {
    const salesPriceValue = salesCell?.price ?? salesPrice?.price;
    if (salesPriceValue !== getValues('price')) {
      resetValue('price', salesCell?.price ?? salesPrice?.price ?? 0);
    }
    if (salesCell) {
      setValueId(salesCell.id);
    }
  }, [salesCell, getValues, resetValue, setValueId, salesPrice?.price]);

  const watchPrice = watch('price');
  const profit = evaluateProfit(
    exchangeRateCalculation(
      watchPrice ?? 0,
      salesCurrency.fixedExchangeRate,
      sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    roundedPurchasePriceWithFees(
      purchasePrice,
      sellingExchangeRate,
      purchaseInformation,
    ),
  );
  const color = profitColor(profit);

  return (
    <form style={{ width: 'fit-content' }} {...form}>
      <FormControl isDisabled={isDisabled}>
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="price"
          position={Position.Right}
        >
          <InputGroup
            padding={1}
            pr={0}
            bg="texas.bg.800"
            minW={52}
            borderRadius={4}
            role="group"
          >
            <Input
              {...register('price', {
                setValueAs: (value) => setValueAsDecimalExtender(value, false),
                onBlur: triggerSubmit,
              })}
              color={color}
              role="group"
              _placeholder={{
                color: color,
              }}
              placeholder="0"
            />

            <InputRightAddon px={2} pointerEvents="none">
              {showCurrencyCode && (
                <HStack
                  overflow="hidden"
                  transition="opacity 200ms ease"
                  _groupHover={{ opacity: 0 }}
                  opacity={1}
                >
                  <Box>{salesCurrency.currencyCode}</Box>
                </HStack>
              )}

              <HStack
                overflow="hidden"
                transition="max-width 200ms ease"
                spacing={1}
                _groupHover={{ maxW: '200px' }}
                maxW="0px"
              >
                <Multiplied
                  salesCurrency={salesCurrency}
                  sellingExchangeRate={sellingExchangeRate}
                  price={watchPrice ?? 0}
                  purchasePrice={purchasePrice}
                  purchaseInformation={purchaseInformation}
                />
                <Margin
                  salesCurrency={salesCurrency}
                  sellingExchangeRate={sellingExchangeRate}
                  price={watchPrice ?? 0}
                  purchasePrice={purchasePrice}
                  purchaseInformation={purchaseInformation}
                />
              </HStack>
            </InputRightAddon>
          </InputGroup>
        </AutoUpdateWrapper>
      </FormControl>
    </form>
  );
}
