import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

export function TexasSwitch({
  checked,
  label,
  onChange,
}: {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}) {
  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel color={checked ? 'white' : 'whiteAlpha.600'} mb="0">
        {label}
      </FormLabel>
      <Switch onChange={(e) => onChange(e.target.checked)} />
    </FormControl>
  );
}
