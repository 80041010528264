import { Box } from '@chakra-ui/react';
import React, { MutableRefObject, useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';

interface BreakoutProps {
  children: React.ReactNode;
}

export function Breakout({ children }: BreakoutProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
  return (
    <Box
      pos="absolute"
      bg="texas.bg.900"
      left={0}
      right={0}
      overflow="auto"
      ref={ref}
      {...events}
    >
      <Box pos="relative" left={0} right={0}>
        <Box maxWidth="var(--content-width)" margin="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
