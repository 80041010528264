import { fadeInScaleAnimation } from '@texas/resources/animations/animations';

const gridCellStyle = {
  display: 'flex',
  animation: fadeInScaleAnimation(),
  bg: 'texas.bg.800',
  borderRadius: 'md',
  role: 'group',
};

export default gridCellStyle;
