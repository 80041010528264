import { StylesConfig, GroupBase, Theme } from 'react-select';
import { ReactSelectType } from '../types';

export interface SelectStyle {
  borderStyle?: 'solid' | 'dotted' | 'dashed' | 'none';
  isAngularTheme?: boolean;
}

const gray600 = '#474747';
const gray400 = '#787878';
const hsl30 = 'hsl(0, 0%, 30%)';
const hsl35 = 'hsl(0, 0%, 35%)';
const hsl45 = 'hsl(0, 0%, 45%)';
const hsl50 = 'hsl(0 0 50%)';
const hsl70 = 'hsl(0, 0%, 70%)';

const sandColors = {
  10: '#F8E2C8',
  20: '#F3DABE',
  30: '#E8CBAB',
  50: '#DEBE9A',
  100: '#C6A37C',
  40085: '#876d50D9',
};

export function reactSelectStyle<IsMulti extends boolean, T = ReactSelectType>(
  selectStyle: SelectStyle = { borderStyle: 'solid', isAngularTheme: false },
): StylesConfig<T, IsMulti, GroupBase<T>> | undefined {
  return {
    indicatorSeparator: (base, _) => ({
      ...base,
      backgroundColor: selectStyle.isAngularTheme ? 'gray' : hsl35,
      ':hover': {
        ...base[':hover'],
        backgroundColor: selectStyle.isAngularTheme ? hsl70 : gray400,
      },
    }),
    option: (base, _) => ({
      ...base,
      color: 'black',
    }),
    control: (base, _) => ({
      ...base,
      backgroundColor: 'transparent',
      borderColor: selectStyle.isAngularTheme ? 'gray' : hsl35,
      ':hover': {
        ...base[':hover'],
        borderColor: selectStyle.isAngularTheme ? hsl70 : hsl45,
        borderStyle: selectStyle.borderStyle,
      },
      ':focus-within': {
        ...base[':focus-within'],
        borderColor: selectStyle.isAngularTheme ? hsl70 : hsl45,
        borderStyle: selectStyle.borderStyle,
      },
      boxShadow: 'none',
      borderStyle: selectStyle.borderStyle,
      minHeight: '46px',
    }),
    input: (base, _) => ({
      ...base,
      color: selectStyle.isAngularTheme ? gray600 : 'white',
      visibility: 'inherit', // Fix to prevent input bleed out when popover is hidden
    }),
    singleValue: (base, _) => ({
      ...base,
      color: selectStyle.isAngularTheme ? gray600 : 'white',
    }),
    container: (base, _) => ({
      ...base,
      width: '100%',
    }),
    multiValue: (base, _) => ({
      ...base,
      backgroundColor: selectStyle.isAngularTheme ? hsl50 : hsl30,
    }),
    multiValueLabel: (base, _) => ({
      ...base,
      color: 'white',
    }),
  };
}

export const reactSelectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: sandColors[50],
    primary25: sandColors[10],
    primary50: sandColors[30],
  },
});
