import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useStep } from './hooks/useStep';
import { Icons } from '@texas/components/shared/Icons';
import { useProdutApprovalWizardState } from '../context';

export function StepContent({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const { state, config, nextStep, lastStep } = useStep();
  const { submit } = useProdutApprovalWizardState();

  if (!state.isActive) {
    return null;
  }

  return (
    <>
      <Text color="white" fontSize="x-large" fontWeight="bold" mb={6} px={4}>
        {config.label}
      </Text>
      {children}
      <Box px={4} mt="auto">
        <Divider mt={10} mb={4} />
        <Flex direction="row" justifyContent="flex-end">
          {lastStep ? (
            <Button
              color="white"
              onClick={() => submit()}
              minW="36"
              isDisabled={!state.isValid}
            >
              {t('wizard.create')} <Icons.Plus ml={1} boxSize={4} />
            </Button>
          ) : (
            <Button
              color="white"
              onClick={nextStep}
              minW="36"
              isDisabled={!state.isValid}
            >
              {t('wizard.continue')} <Icons.ArrowRight ml={1} boxSize={4} />
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
}
