import {
  Text,
  Flex,
  GridItem,
  Grid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { TemplateView } from './standard/TemplateView';
import { useEffect, useState } from 'react';
import { MatrixView } from './matrix/MatrixView';
import {
  TexasDrawer,
  useTexasDrawer,
} from '@texas/components/shared/drawer/TexasDrawer';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { TexasDrawerBody } from '@texas/components/shared/drawer/TexasDrawerBody';
import { TexasDrawerStrip } from '@texas/components/shared/drawer/TexasDrawerStrip';
import { TexasDrawerContent } from '@texas/components/shared/drawer/TexasDrawerContent';
import { CellTemplateView } from './matrix/CellTemplateView';
import {
  formatNodeGroup,
  nodeGroupIsPopulated,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { useTranslation } from 'react-i18next';
import { TemplateCard } from './shared/TemplateCard';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { SupplierView } from './shared/SupplierView';

interface CompositionsProps {
  variantId: number;
  productGroupId: number;
  articleNodeGroup: ArticleNodeGroup;
}

export function Compositions({
  variantId,
  productGroupId,
  articleNodeGroup,
}: CompositionsProps) {
  return (
    <BodyOverride>
      {!nodeGroupIsPopulated(articleNodeGroup) ? (
        <StandardComposition
          variantId={variantId}
          productGroupId={productGroupId}
        />
      ) : (
        <MatrixComposition
          variantId={variantId}
          productGroupId={productGroupId}
          articleNodeGroup={articleNodeGroup}
        />
      )}
    </BodyOverride>
  );
}

function StandardComposition({
  variantId,
  productGroupId,
}: {
  variantId: number;
  productGroupId: number;
}) {
  const { data: suppliers, refetch: refetchSuppliers } = useApiResource(
    variantsApi.getVariantBranchSuppliers,
  );

  const [baseTemplateId, setBaseTemplateId] = useState<number | null>(null);

  useEffect(() => {
    refetchSuppliers(variantId);
  }, [refetchSuppliers, variantId]);

  return (
    <Tabs variant="line">
      <Grid
        gridTemplateRows="auto 1fr"
        gridTemplateColumns="auto 1fr"
        columnGap={8}
      >
        <GridItem gridRow={1} gridColumn={2}>
          <TabList>
            {suppliers?.branches.map((x) => {
              return (
                <Tab key={x.id}>
                  <Flex flexDir="column" align="start">
                    <Text variant="header">{x.identifier}</Text>
                    <Text variant="sub">{x.name}</Text>
                  </Flex>
                </Tab>
              );
            })}
          </TabList>
        </GridItem>
        <GridItem gridRow={2} gridColumn={1} padding={4}>
          <TemplateView
            onLoaded={(t) => setBaseTemplateId(t?.exists ? t.id : null)}
            variantId={variantId}
            productGroupId={productGroupId}
          />
        </GridItem>
        <GridItem gridRow={2} gridColumn={2} overflow="auto">
          <TabPanels>
            {suppliers?.branches.map((x) => {
              return (
                <TabPanel key={x.id}>
                  <Flex gap={2}>
                    {suppliers.suppliers
                      .filter((s) => s.branchId === x.id)
                      .map((s) => {
                        return (
                          <SupplierView
                            key={s.supplierId}
                            variantId={variantId}
                            productGroupId={productGroupId}
                            supplierId={s.supplierId}
                            branchId={s.branchId}
                            supplierName={s.supplierName}
                            baseTemplateId={baseTemplateId}
                          />
                        );
                      })}
                  </Flex>
                </TabPanel>
              );
            })}
          </TabPanels>
        </GridItem>
      </Grid>
    </Tabs>
  );
}

function MatrixComposition({
  variantId,
  productGroupId,
  articleNodeGroup,
}: {
  variantId: number;
  productGroupId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  const { data: suppliers, refetch: refetchSuppliers } = useApiResource(
    variantsApi.getVariantBranchSuppliers,
  );

  useEffect(() => {
    refetchSuppliers(variantId);
  }, [refetchSuppliers, variantId]);
  const drawer = useTexasDrawer(ModalState.Peek, true);
  const [baseTemplateId, setBaseTemplateId] = useState<number | null>(null);
  const [selectedCell, setSelectedCell] = useState<
    [number, number, number, number] | null
  >(null);
  const { t } = useTranslation();
  return (
    <>
      <TexasDrawer
        customWidth={100}
        ref={drawer.ref}
        modal={drawer}
        toggleBackground={true}
      >
        <TexasDrawerStrip>
          <Flex alignItems="center" h="full">
            <Text
              color="black"
              fontWeight="bold"
              maxW="32px"
              whiteSpace="nowrap"
              height="fit-content"
              transform="rotateZ(270deg)"
            >
              {t('composition.viewComposition')}
            </Text>
          </Flex>
        </TexasDrawerStrip>
        <TexasDrawerContent>
          <TexasDrawerBody>
            <Tabs variant="line">
              <Grid
                gridTemplateRows="auto 1fr"
                gridTemplateColumns="auto 1fr"
                columnGap={8}
              >
                <GridItem gridRow={1} gridColumn={2}>
                  <TabList>
                    {suppliers?.branches.map((x) => {
                      return (
                        <Tab key={x.id}>
                          <Flex flexDir="column" align="start">
                            <Text variant="header">{x.identifier}</Text>
                            <Text variant="sub">{x.name}</Text>
                          </Flex>
                        </Tab>
                      );
                    })}
                  </TabList>
                </GridItem>
                <GridItem gridRow={2} gridColumn={1} padding={4}>
                  <Flex gap={2}>
                    <TemplateView
                      drawerRef={drawer.ref}
                      onLoaded={(t) =>
                        setBaseTemplateId(t?.exists ? t.id : null)
                      }
                      variantId={variantId}
                      productGroupId={productGroupId}
                    />
                    {selectedCell ? (
                      <CellTemplateView
                        drawerRef={drawer.ref}
                        variantId={variantId}
                        productGroupId={productGroupId}
                        cellX={selectedCell[0]}
                        cellY={selectedCell[1]}
                        header={formatNodeGroup(
                          articleNodeGroup.nodeXValues[selectedCell[2]],
                          articleNodeGroup.nodeYValues[selectedCell[3]],
                        )}
                        baseTemplateId={baseTemplateId}
                        articleNodeGroup={articleNodeGroup}
                      />
                    ) : (
                      <Flex flexDir="column" height="full" gap={2}>
                        <Text textAlign="center">
                          {t('composition.baseCellTemplate')}
                        </Text>
                        <TemplateCard exists={false} hightlight={false}>
                          <Text m="auto">
                            {t('composition.noCellSelected')}
                          </Text>
                        </TemplateCard>
                      </Flex>
                    )}
                  </Flex>
                </GridItem>
                <GridItem gridRow={2} gridColumn={2} overflow="auto">
                  {selectedCell && (
                    <TabPanels>
                      {suppliers?.branches.map((x) => {
                        return (
                          <TabPanel key={x.id}>
                            <Flex gap={2}>
                              {suppliers.suppliers
                                .filter((s) => s.branchId === x.id)
                                .map((s) => {
                                  return (
                                    <SupplierView
                                      drawerRef={drawer.ref}
                                      key={s.supplierId}
                                      variantId={variantId}
                                      productGroupId={productGroupId}
                                      supplierId={s.supplierId}
                                      branchId={s.branchId}
                                      supplierName={s.supplierName}
                                      cellX={selectedCell[0]}
                                      cellY={selectedCell[1]}
                                      baseTemplateId={baseTemplateId}
                                      articleNodeGroup={articleNodeGroup}
                                    />
                                  );
                                })}
                            </Flex>
                          </TabPanel>
                        );
                      })}
                    </TabPanels>
                  )}
                </GridItem>
              </Grid>
            </Tabs>
          </TexasDrawerBody>
        </TexasDrawerContent>
      </TexasDrawer>
      <MatrixView
        selectedCell={selectedCell}
        onCellSelected={(cell) => {
          setSelectedCell(cell);
          drawer.setModalState(ModalState.Open);
        }}
        variantId={variantId}
        articleNodeGroup={articleNodeGroup}
        baseTemplateId={baseTemplateId}
      />
    </>
  );
}
