import { Spinner, Flex, Box, Fade, Text } from '@chakra-ui/react';
import { fadeInAnimation } from '@texas/resources/animations/animations';

export function LoadingOverlayV2({
  loaded,
  children,
  label,
}: {
  loaded: boolean;
  children?: React.ReactNode;
  label?: string;
}) {
  if (loaded) {
    if (!children) return null;
    return <Box animation={fadeInAnimation()}>{children}</Box>;
  }

  return (
    <Fade in={true}>
      <Flex
        position="absolute"
        inset="0"
        zIndex="overlay"
        alignItems="center"
        flexDir="column"
        justifyContent="center"
        gap={4}
        bg="rgba(0, 0, 0, 0.6)"
      >
        <Spinner thickness="3px" color="primary.200" size="lg" />
        {label && <Text variant="sub">{label}</Text>}
      </Flex>
    </Fade>
  );
}
