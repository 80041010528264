import { Button, Flex, Text } from '@chakra-ui/react';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { useTranslation } from 'react-i18next';

export function SupplierCell({
  supplier,
  onRemove,
}: {
  supplier: VariantBranchSupplier;
  onRemove: (supplierId: number, branchId: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex grow={1} py={2} px={4} flexDir="column" align="start">
      <Text variant="main">{supplier.supplierName}</Text>
      <Button
        mt="auto"
        size="xs"
        variant="link"
        visibility="hidden"
        _groupHover={{ visibility: 'visible' }}
        onClick={() => onRemove(supplier.supplierId, supplier.branchId)}
      >
        {t('general.remove')}
      </Button>
    </Flex>
  );
}
