import { HStack, VStack, Box, IconButton, Tooltip } from '@chakra-ui/react';
import { WidgetWrapper } from '../WidgetWrapper';
import { useTranslation } from 'react-i18next';
import {
  defaultEmptyFilters,
  useSearchArticles,
} from '../../searchArticles/useSearchArticles';
import { SearchArticleOptions } from '../../searchArticles/types';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { reactEvents } from '../../../../bridge/reactEvents';
import { maxExportArticlesAmount } from '../../searchArticles/SearchArticles';

import {
  articlesWidgetSelectStyles,
  groupBadgeStyles,
  groupStyles,
} from './styles';
import { useMemo, useState } from 'react';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { Icons } from '@texas/components/shared/Icons';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import {
  GroupedOption,
  SelectFilterOption,
  ToggleFilterOption,
  GetFilterGroupedOptions,
} from './filterGroupedOptions';
import { RowSelectionState } from '@tanstack/react-table';
import { ArticlesDataTableContainerFooter } from '@texas/components/shared/dataTable/ArticlesDataTableContainerFooter';
import { useExportableArticles } from '@texas/components/searchArticles/useExportableArticles';

export function ArticlesWidget({
  options,
  customWidgetTitle,
}: {
  options: SearchArticleOptions;
  customWidgetTitle?: string;
}) {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return options.columns(t);
  }, [options, t]);

  const {
    articles,
    searchPage,
    searchParamsRef,
    filterElements,
    error,
    loading,
    setSearchPage,
    setSearchParamsWithRef,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    availableFilters,
  } = useSearchArticles({
    defaultFilters: defaultEmptyFilters,
    defaultArticleSearchProps: options.defaultSearchPage,
    defaultPage: options.defaultSearchPage.searchParams.page,
    defaultSearchParams: options.defaultSearchPage.searchParams,
    localStorageKey: options.localStorageKey,
    filterOptions: options.filterOptions,
    optOutDefaultOrder: options.optOutDefaultOrdering,
    limit: options.pageSize,
  });

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const exportableArticles = useExportableArticles(
    selectedRows,
    articles?.exportArticleItems ?? [],
  );

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <>
      <WidgetWrapper
        style="default"
        header={customWidgetTitle ?? t('widgets.articles.articles')}
        toolbox={WidgetToolbox()}
      >
        <VStack h="100%" w="100%" align="start">
          <HStack w="100%" align="start">
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(6, 1fr)"
              gridGap={1}
              rowGap={1}
            >
              <Box height="fit-content" mt="auto" gridColumn="span 2">
                <SearchFilterInput
                  placeholder={t('searchArticles.itemcodeOrDescription')}
                  value={searchPage.searchParams.searchTerm}
                  onChange={(searchTerm) =>
                    setSearchParamsWithRef({
                      ...searchParamsRef.current,
                      searchTerm,
                      page: options.defaultSearchPage.searchParams.page,
                    })
                  }
                />
              </Box>
              {filterElements}
            </Box>
          </HStack>
          <DataTableContainer
            p={4}
            w="100%"
            minH={0}
            display="flex"
            flexDir="column"
            error={error}
            css={{ ' th, td': { whiteSpace: 'normal' } }}
            datatable={{
              data: articles?.pagedItems.items ?? [],
              columns: columns,
              isLoading: loading,
              selectionProps: {
                setSelectedRows: setSelectedRows,
                selectedRows: selectedRows,
              },
              rowProps: {
                getRowId: (originalRow, _) => {
                  return originalRow.articleId.toString();
                },
              },
              sorted: {
                onSortedChange: ({ key, desc }) => {
                  setSearchParamsWithRef({
                    ...searchParamsRef.current,
                    sortBy: key,
                    sortDesc: desc,
                    page: options.defaultSearchPage.searchParams.page,
                  });
                },
                key: searchPage.searchParams['sortBy'],
                desc: searchPage.searchParams['sortDesc'],
              },
            }}
            pagination={{
              totalItems: articles?.pagedItems.totalItems ?? 0,
              pageSize: options.pageSize,
              currentPage: searchPage.searchParams.page,
              onPageChange: (page) =>
                setSearchParamsWithRef({
                  ...searchParamsRef.current,
                  page,
                }),
            }}
            footer={
              <ArticlesDataTableContainerFooter
                selectedRows={Object.keys(selectedRows).length}
                onClear={() => setSelectedRows({})}
              />
            }
          />
        </VStack>
      </WidgetWrapper>
    </>
  );

  function WidgetToolbox() {
    return (
      <VStack>
        <Tooltip label={t('searchArticles.addNewFilter')}>
          <Box>
            <Select<
              SelectFilterOption | ToggleFilterOption,
              false,
              GroupedOption
            >
              inputId="clickableInput"
              placeholder=""
              styles={articlesWidgetSelectStyles}
              components={{ DropdownIndicator }}
              options={GetFilterGroupedOptions(availableFilters)}
              formatGroupLabel={formatGroupLabel}
              onChange={(value) => {
                if (!value) return;
                const type = value.value;
                setActiveFiltersVariantResults((s) => [
                  ...s,
                  {
                    optionType: type,
                    ...defaultEmptyFilters,
                    isPopulated: false,
                  },
                ]);
                setActiveFilterTypes((s) => [...s, [true, type]]);
                setSearchPage((s) => ({
                  ...s,
                  filters: [...s.filters, type],
                }));
              }}
              value={null}
            />
          </Box>
        </Tooltip>

        <Tooltip label={t('general.exportExcel')}>
          <IconButton
            variant="unstyled"
            aria-label="Export excel"
            size="xs"
            isDisabled={
              Object.keys(selectedRows).length === 0 ||
              Object.keys(selectedRows).length > maxExportArticlesAmount
            }
            onClick={() => {
              reactEvents.excelExportClicked.dispatch({
                articles: exportableArticles,
                branchIds: searchPage.searchParams['branchIds'] ?? [],
                hideBranchIds: false,
              });
            }}
            icon={<Icons.FileExport boxSize={6} />}
          />
        </Tooltip>
      </VStack>
    );
  }
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<
    SelectFilterOption | ToggleFilterOption,
    false,
    GroupedOption
  >,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.Plus boxSize="6" />
    </components.DropdownIndicator>
  );
};
