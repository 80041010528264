import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import {
  CreateProductGroupRequest,
  productGroupsApi,
} from '@texas/api/endpoints/metadata/productGroupsApi';
import { SharedDisclosureProps } from '@texas/components/shared/types';

export interface CreateProductGroupProps extends SharedDisclosureProps {
  onCreated: () => void;
}

const defaultValues: CreateProductGroupRequest = {
  value: '',
  code: '',
  displayOrder: 0,
};

export function CreateProductGroup({
  onCreated,
  onClose,
  isOpen,
}: CreateProductGroupProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createRequest, error } = useApiRequest(
    productGroupsApi.create,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateProductGroupRequest>({
    defaultValues: {
      code: '',
      value: '',
      displayOrder: 0,
    },
  });

  const onSubmit = async (data: CreateProductGroupRequest) =>
    await request(
      createRequest,
      [data],
      () => {
        reset(defaultValues);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        onCreated();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isOpen={isOpen}
      variant="above-angular"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewProductGroup')}
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            {error && <ErrorDetails error={error} />}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <VStack>
                <FormControl isRequired={true} isInvalid={!!errors.code}>
                  <TexasFormLabel>{t('general.code')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('code', {
                      required: true,
                      maxLength: {
                        value: 2,
                        message: t('errors.maxLength', { count: 2 }),
                      },
                    })}
                    placeholder={t('general.code')}
                  />
                  <ErrorLabel text={errors.code?.message} />
                </FormControl>
                <FormControl isRequired={true} isInvalid={!!errors.value}>
                  <TexasFormLabel>{t('general.value')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('value', {
                      required: true,
                      maxLength: {
                        value: 100,
                        message: t('errors.maxLength', { count: 100 }),
                      },
                    })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.value?.message} />
                </FormControl>
                <FormControl
                  isRequired={true}
                  isInvalid={!!errors.displayOrder}
                >
                  <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
                  <Input
                    type="number"
                    variant="outline"
                    {...register('displayOrder', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder={t('branch.displayOrder')}
                  />
                  <ErrorLabel text={errors.displayOrder?.message} />
                </FormControl>

                <SubmitButton loading={isSubmitting}>
                  {t('general.create')}
                </SubmitButton>
              </VStack>
            </form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
