import {
  Button,
  VStack,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Portal,
  useDisclosure,
  FormControl,
  FormLabel,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerError } from '../../../types';
import { request } from '../../../utils/helpers/httpHelpers';
import {
  UserProfile,
  userProfilesApi,
} from '@texas/api/endpoints/userProfilesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from '@texas/components/shared/Icons';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ArchiveUserProfileDialog } from './ArchiveUserProfileDialog';
import { EditUserProfileModal } from './EditUserProfilesModal';
import { CreateUserProfileForm } from './forms/CreateUserProfileForm';
import { Container } from '@texas/components/shared/Container';
import { TableColumns } from './TableColumns';

interface UserProfilesProps {
  userId: number;
}

export function UserProfiles(props: UserProfilesProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<UserProfile>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<UserProfile>();

  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: userProfiles,
    refetch,
    error,
    loading,
  } = useApiResource(userProfilesApi.getUserProfilesFromUser);

  const [includeArchived, setIncludeArchived] = useState(false);

  const { request: restureUserProfileRequest } = useApiRequest(
    userProfilesApi.restoreUserProfile,
  );

  useEffect(() => {
    refetch(props.userId, includeArchived);
  }, [refetch, props.userId, includeArchived]);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restureUserProfileRequest,
        [id],
        (_) => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(props.userId, includeArchived);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [
      restureUserProfileRequest,
      toast,
      t,
      refetch,
      props.userId,
      includeArchived,
    ],
  );

  const columns = useMemo(() => {
    return TableColumns(t, onArchiveOpen, performRestore, onEditOpen);
  }, [t, onArchiveOpen, performRestore, onEditOpen]);

  return (
    <Container title={t('userProfile.userProfiles')} noBorderRadius={true}>
      {archiveValue && (
        <ArchiveUserProfileDialog
          userProfile={archiveValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchive={(_) => {
            refetch(props.userId, includeArchived);
          }}
        />
      )}

      <EditUserProfileModal
        userProfile={editValue}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onProfileUpdated={(_) => {
          refetch(props.userId, includeArchived);
        }}
      />
      <VStack w="100%" alignItems="start" p={4}>
        <HStack w="100%">
          <Popover
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
            variant="above-angular"
          >
            <PopoverTrigger>
              <Button>
                <>{t('general.addNew')}</> <Icons.ArrowDownRight ml={2} />
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent color="white">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader fontWeight="bold">
                  {t('userProfile.addNewUserProfile')}
                </PopoverHeader>
                <PopoverBody>
                  <CreateUserProfileForm
                    userId={props.userId}
                    onProfileCreated={(_) => {
                      refetch(props.userId, includeArchived);
                      onClose();
                    }}
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          <VStack w="100%">
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                {t('general.includeArchived')}
              </FormLabel>
              <Switch onChange={(e) => setIncludeArchived(e.target.checked)} />
            </FormControl>
          </VStack>
        </HStack>

        <DataTableContainer
          w="100%"
          datatable={{
            data: userProfiles ?? [],
            isLoading: loading,
            columns: columns,
          }}
          error={error}
        />
      </VStack>
    </Container>
  );
}
