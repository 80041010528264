import {
  Badge,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import {
  DimensionGroup,
  UpdateDimensionGroupRequest,
  dimensionGroupsApi,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';

export interface EditDimensionGroupProps extends SharedDisclosureProps {
  onUpdated: (dimensionGroup: DimensionGroup) => void;
  dimensionGroupId: number | null;
}

export function EditDimensionGroup({
  onUpdated,
  dimensionGroupId,
  onClose,
  isOpen,
}: EditDimensionGroupProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('configuration.editDimensionGroup')}</ModalHeader>
        {dimensionGroupId && (
          <Form
            dimensionGroupId={dimensionGroupId}
            onClose={onClose}
            onUpdated={onUpdated}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  dimensionGroupId,
  onClose,
  onUpdated,
}: {
  dimensionGroupId: number;
  onClose: () => void;
  onUpdated: (dimensionGroup: DimensionGroup) => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    request: updateRequest,
    error,
    loading,
  } = useApiRequest(dimensionGroupsApi.update);
  const { data: dimensionGroup, refetch: refetchDimensionGroup } =
    useApiResource(dimensionGroupsApi.get);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateDimensionGroupRequest>();

  useEffect(() => {
    refetchDimensionGroup(dimensionGroupId);
  }, [reset, refetchDimensionGroup, dimensionGroupId]);

  useEffect(() => {
    reset({
      name: dimensionGroup?.name,
      description: dimensionGroup?.description,
    });
  }, [reset, dimensionGroup]);

  const onSubmit = async (data: UpdateDimensionGroupRequest) =>
    await request(
      updateRequest,
      [dimensionGroup!.id, data],
      (data: DimensionGroup) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(data);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <VStack>
            <FormControl isRequired={true}>
              <TexasFormLabel>{t('general.name')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', { required: true })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('general.description')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('description')}
                placeholder={t('general.description')}
              />
              <ErrorLabel text={errors.description?.message} />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('configuration.dimensions')}</TexasFormLabel>
              <VStack align="start">
                {dimensionGroup?.dimensions.map((x) => {
                  return (
                    <Row key={x.id} order={x.displayOrder} value={x.name} />
                  );
                })}
              </VStack>
              <Text mt={1} fontWeight="bold" fontSize="xs" color="yellow.400">
                {t('configuration.dimensionCanNotBeEdited')}
              </Text>
            </FormControl>
            <SubmitButton loading={loading}>{t('general.update')}</SubmitButton>
          </VStack>
        </ModalBody>
      </form>
    </>
  );
}

function Row({ value, order }: { value: string; order: number }) {
  return (
    <Flex gap={1} align="center">
      <Text fontSize="xs" fontWeight="bold" color="gray.400">
        {order}
      </Text>
      <Badge>{value}</Badge>
    </Flex>
  );
}
