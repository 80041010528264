import {
  HStack,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { roundedPurchasePriceWithFees } from './shared';
import { Multiplied } from './common/Multiplied';
import { Margin } from './common/Margin';
import React, { ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Branch } from '@texas/api/endpoints/branchesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesInformation } from '@texas/api/endpoints/salesInformationApi';
import { TierPrice } from '@texas/api/endpoints/tierPriceApi';
import { Icons } from '@texas/components/shared/Icons';
import useFormat from '@texas/hooks/useFormat';
import { SalesPriceForm } from './forms/SalesPriceForm';

interface BranchTierPrice {
  branch?: Branch;
  purchaseInformation?: PurchaseInformation;
  salesInformation?: SalesInformation;
  sellingExchangeRate: number;
  selectedCurrencyCode: string;
  tierPrice?: TierPrice;
}

interface SalesInformationBranchOverviewProps {
  currentTier: TierPrice;
  branchTierPrices: BranchTierPrice[];
  children: React.ReactNode;
}

export const SalesBranchOverview = React.forwardRef(
  function SalesBranchOverview(
    props: SalesInformationBranchOverviewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const { t } = useTranslation();
    const { numberFormat } = useFormat();

    return (
      <Popover placement="top-end">
        <PopoverTrigger>{props.children}</PopoverTrigger>
        <PopoverContent
          minW={{ base: '100%', lg: 'max-content' }}
          bg="white"
          color="black"
          ref={ref}
        >
          <PopoverArrow bg="white" />
          <PopoverCloseButton />
          <PopoverHeader>
            <HStack>
              {props.currentTier.isMain && (
                <Icons.Flag color="texas.sand.100" />
              )}
              <Heading size="xs">
                {t('variant.salesBranchOverview.tierBranchOverview')}
              </Heading>
            </HStack>
          </PopoverHeader>
          <PopoverBody>
            <TableContainer>
              <Table
                variant="unstyled"
                css={{
                  'td, th': {
                    padding: '0.2em',
                  },
                }}
              >
                <Thead border="none">
                  <Tr color="gray.500">
                    <Th />
                    <Th>{t('salesMatrix.tier')}</Th>
                    <Th>{t('variant.sales.purchasePrice')}</Th>
                    <Th>{t('variant.sales.salesPrice')}</Th>
                    <Th>{t('variant.sales.margin')}</Th>
                    <Th>{t('variant.sales.multiplier')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.branchTierPrices.map((b) => {
                    const basePrice = b.tierPrice?.basePrices.find(
                      (p) => p.supplierId === b.purchaseInformation?.supplierId,
                    );

                    if (!b.branch) {
                      return (
                        <>{t('variant.salesBranchOverview.noBranchFound')}</>
                      );
                    }

                    const tierAvailability = checkTierAvailability(
                      b.purchaseInformation,
                      b.tierPrice,
                    );

                    return (
                      <React.Fragment key={b.branch.id}>
                        <Tr
                          bg="texas.bg.800"
                          color="white"
                          {...(tierAvailability === TierAvailability.Ok
                            ? {
                                css: {
                                  ' > td:nth-of-type(-n + 3)': {
                                    fontWeight: 'bold',
                                  },
                                },
                              }
                            : { opacity: 0.4, fontStyle: 'italic' })}
                        >
                          <Td>{b.branch.name}</Td>

                          {tierAvailability === TierAvailability.Ok ? (
                            <Td colSpan={5}>
                              {numberFormat(b.tierPrice?.quantity ?? 0)}
                            </Td>
                          ) : (
                            <Td colSpan={5}>
                              <TierAvailabilityComponent
                                tierAvailability={tierAvailability}
                              />
                            </Td>
                          )}
                        </Tr>

                        {tierAvailability === TierAvailability.Ok && (
                          <>
                            {b.salesInformation?.currencies.map((c) => {
                              const salesPrice = b.tierPrice?.salesPrices.find(
                                (s) => s.salesCurrencyId === c.id,
                              );
                              return (
                                <Tr key={c.id}>
                                  <Td>{c.currencyCode}</Td>
                                  <Td />
                                  <Td>
                                    {numberFormat(
                                      roundedPurchasePriceWithFees(
                                        basePrice?.price ?? 0,
                                        c.fixedExchangeRate,
                                        b.purchaseInformation,
                                      ),
                                    )}
                                  </Td>
                                  <Td color="white">
                                    <SalesPriceForm
                                      tierPrice={b.tierPrice!}
                                      salesCurrency={c}
                                      purchaseInformation={
                                        b.purchaseInformation!
                                      }
                                      currencyBuyingRate={c.fixedExchangeRate}
                                      isDisabled={b.salesInformation?.isReady}
                                      selectedCurrencyCode={
                                        b.selectedCurrencyCode
                                      }
                                    />
                                  </Td>
                                  <Td>
                                    <Margin
                                      salesCurrency={c}
                                      sellingExchangeRate={
                                        b.sellingExchangeRate
                                      }
                                      price={salesPrice?.price ?? 0}
                                      purchasePrice={basePrice?.price ?? 0}
                                      purchaseInformation={
                                        b.purchaseInformation
                                      }
                                    />
                                  </Td>
                                  <Td>
                                    <Multiplied
                                      salesCurrency={c}
                                      sellingExchangeRate={
                                        b.sellingExchangeRate
                                      }
                                      price={salesPrice?.price ?? 0}
                                      purchasePrice={basePrice?.price ?? 0}
                                      purchaseInformation={
                                        b.purchaseInformation
                                      }
                                    />
                                  </Td>
                                </Tr>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);

enum TierAvailability {
  Ok,
  NotFoundInBranch,
  BelowMoq,
  SupplierMissing,
}

function checkTierAvailability(
  purchaseInformation?: PurchaseInformation,
  tier?: TierPrice,
) {
  if (!purchaseInformation?.supplierId) {
    return TierAvailability.SupplierMissing;
  } else if (!tier) {
    return TierAvailability.NotFoundInBranch;
  } else if (tier.quantity < purchaseInformation.moq) {
    return TierAvailability.BelowMoq;
  }

  return TierAvailability.Ok;
}
function TierAvailabilityComponent({
  tierAvailability,
}: {
  tierAvailability: TierAvailability;
}) {
  const { t } = useTranslation();
  if (tierAvailability === TierAvailability.NotFoundInBranch) {
    return <>{t('variant.salesBranchOverview.tierNotFound')}</>;
  } else if (tierAvailability === TierAvailability.BelowMoq) {
    return <>{t('variant.salesBranchOverview.tierBelowMoq')}</>;
  } else if (tierAvailability === TierAvailability.SupplierMissing) {
    return <>{t('variant.salesBranchOverview.supplierMissing')}</>;
  }

  return <></>;
}
