import { httpClient } from '../httpClient/httpClient';
import { ArticleState } from '@texas/api/endpoints/searchApi';

export interface MatrixNodeValue {
  id: number;
  matrixNodeId: number;
  identifier: string;
  name: string | null;
  displayOrder: number;
  archived: boolean;
}

export interface ArticleNodeValue {
  id: number;
  name: string | null;
  matrixNodeValue: MatrixNodeValue;
}

export interface ArticleNodeGroup {
  nodeXValues: ArticleNodeValue[];
  nodeYValues: ArticleNodeValue[];
}

export interface MoveArticle {
  articleId: number;
  itemCode: string;
  brand: string;
  project: string;
  concept: string;
}

export interface UpdateStateArticle {
  variantId: number;
  name: string;
  itemCode: string;
  branchId: number;
  branchIdentifier: string;
  state: ArticleState;
}

interface MoveRequest {
  articleIds: number[];
  toCustomerId: number;
  toConceptId: number | null;
}

interface UpdateStateVariants {
  variants: UpdateVariant[];
}

interface UpdateStateRequest extends UpdateStateVariants {
  state: ArticleState;
}

export interface UpdateVariant {
  variantId: number;
  branchId: number;
}

export interface IdNameDto {
  id: number;
  name: string;
}

export const articlesApi = {
  moveArticles: (request: MoveRequest) =>
    httpClient.post<MoveArticle[]>('articles/move', { data: request }),
  updateState: (request: UpdateStateRequest) =>
    httpClient.put<UpdateStateArticle[]>('articles/state', { data: request }),
  getUpdateStateVariants: (request: UpdateStateVariants) =>
    httpClient.post<UpdateStateArticle[]>('articles/state', {
      data: request,
    }),
  getMoveArticles: (articleIds: number[]) =>
    httpClient.get<MoveArticle[]>(`articles/move`, {
      params: { articleIds: articleIds },
    }),
  getArticleBranches: (articleId: number) =>
    httpClient.get<IdNameDto[]>(`articles/${articleId}/branches`),
  getArticleSuppliers: (articleId: number) =>
    httpClient.get<IdNameDto[]>(`articles/${articleId}/suppliers`),
};
