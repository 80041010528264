import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { ReactSelectOption } from '@texas/types';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';

export function getStateOption(state: ProductApprovalState): ReactSelectOption {
  const t = getEnumNamesAndValues(ProductApprovalState);
  const v = t.find(
    (x) => convertToEnum(ProductApprovalState, x.value) === state,
  )!;

  return { label: v.name, value: v.value };
}
