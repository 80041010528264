import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = config.definePartsStyle({
  tablist: {
    color: 'whiteAlpha.600',
  },
  tab: {
    _selected: { color: 'white' },
  },
  tabindicator: {
    margintop: '-1.5px',
    height: '2px',
    bg: 'texas.sand.100',
    borderRadius: '1px',
  },
});

export default config.defineMultiStyleConfig({
  defaultProps: { variant: 'unstyled' },
  baseStyle,
});
