import { Box } from '@chakra-ui/react';

export function BodyOverride({ children }: { children: React.ReactNode }) {
  // Override the body BG background on fully styled react pages
  // This is a hack that is temporary and will be removed when
  // react is fully implemented
  // But this needs to exist in the meantime since we have 2 styles at the same time..
  return (
    <Box>
      <style>
        {
          '\
        body, html {\
          background: var(--chakra-colors-bg-900);\
        }\
      '
        }
      </style>
      {children}
    </Box>
  );
}
