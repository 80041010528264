import { httpClient } from '@texas/api/httpClient/httpClient';
import { SearchQuery } from './types';

export interface ProductGroup {
  value: string;
  code: string;
  id: number;
  archived: boolean;
  displayOrder: number;
}

export interface ProductGroupListItem {
  id: number;
  value: string;
  code: string;
  displayOrder: number;
  archived: string | null;
}

export interface UpdateProductGroupRequest {
  value: string;
  code: string;
  displayOrder: number;
}
export interface CreateProductGroupRequest {
  value: string;
  code: string;
  displayOrder: number;
}

export const productGroupsApi = {
  getProductGroups: () =>
    httpClient.get<ProductGroup[]>(`metadata/productgroups`),
  get: (id: number) =>
    httpClient.get<ProductGroup>(`metadata/productgroups/${id}`),
  getAll: (params: SearchQuery) =>
    httpClient.get<ProductGroupListItem[]>(`metadata/productgroups/all`, {
      params,
    }),
  create: (request: CreateProductGroupRequest) =>
    httpClient.post<ProductGroup>(`metadata/productgroups`, {
      data: request,
    }),
  update: (id: number, request: UpdateProductGroupRequest) =>
    httpClient.put<ProductGroup>(`metadata/productgroups/${id}`, {
      data: request,
    }),
  archive: (id: number) =>
    httpClient.put(`metadata/productgroups/${id}/archive`),
  restore: (id: number) =>
    httpClient.put(`metadata/productgroups/${id}/restore`),
};
