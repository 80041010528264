import { PagedItems } from '@texas/types';
import { httpClient } from '../httpClient/httpClient';

export enum ArticleProcess {
  KickOff = 1,
  SupplierEnquiry = 2,
  QuotationReview = 3,
  SalesOffer = 4,
  OfferApproved = 5,
  InitiateSampling = 6,
  SamplingUnderway = 7,
  SampleSentToBc = 8,
  SampleSentToBrand = 9,
  SampleApproved = 10,
}

export interface FilterItem {
  value: number;
  label: string;
  tags?: string[];
}

export interface SearchVariantsResponse {
  exportArticleItems: ExportArticleItem[];
  pagedItems: PagedItems<SearchVariantItem>;
  filterOptionIds: FilterOptionIds;
}

export interface FilterOptionIds {
  conceptIds?: number[];
  categoryCodeIds?: number[];
  customerIds?: number[];
  states?: number[];
  branchIds?: number[];
  materialIds?: number[];
  productGroupIds?: number[];
  processes?: number[];
}

export interface ExportArticleItem {
  id: number;
  customerId: number;
  branches: ExportArticleBranchItem[];
}

export interface ExportArticleBranchItem {
  id: number;
}

export enum ArticleState {
  Registered = 0,
  Accepted = 1,
  Rejected = 2,
  OnHold = 3,
  Cancelled = 4,
}
export interface SearchFilterQuery {
  searchTerm: string;
  page: number;
  pageSize: number;
  filteredOptions?: number[];
}

export interface SearchVariantItem {
  articleId: number;
  variantId: number;
  itemCode: string;
  name: string;
  variantName: string;
  imageIdentifier: string;
  customerId: number;
  customerIdentifier: string;
  customerName: string;
  articleProcess: ArticleProcess;
  concept: string;
  conceptId: number;
  branch: string;
  branchId: number;
  state: ArticleState;
  productGroup: string;
  color: string;
  size: string;
  material: string;
  created: string;
  sampleReadyDate?: string;
  mainVariant: boolean;
}

export interface VariantsSearchQuery extends FilterOptionIds {
  searchTerm: string;
  sortBy: string;
  sortDesc: boolean;
  page: number;
  showAllVariants?: boolean;
  includeArchived?: boolean;
  showMyBrands?: boolean;
}

interface VariantsSearchQueryRequest extends VariantsSearchQuery {
  limit: number;
  optOutDefaultOrdering: boolean;
}

export interface SearchArticlesResponse {
  total: number;
  results: SearchArticlesItem[];
}
export interface SearchArticlesItem {
  id: number;
  name: string;
  itemCode: string;
  imageIdentifier: string;
}

export const searchApi = {
  searchArticles: (searchTerm: string) =>
    httpClient.get<SearchArticlesResponse>('search/articles', {
      params: { searchTerm },
    }),
  searchVariants: (params: VariantsSearchQueryRequest) =>
    httpClient.get<SearchVariantsResponse>(`search/variants`, {
      params,
    }),

  searchBranches: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/branches', { data: query }),
  getBranches: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/branches/ids', { params: { ids } }),

  searchCategoryCodes: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/categorycodes', {
      data: query,
    }),
  getCategoryCodes: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/categorycodes/ids', {
      params: { ids },
    }),

  searchCustomers: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/customers', {
      data: query,
    }),
  getCustomers: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/customers/ids', { params: { ids } }),

  searchConcepts: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/concepts', { data: query }),
  getConcepts: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/concepts/ids', { params: { ids } }),

  searchStates: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/states', { data: query }),
  getStates: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/states/ids', { params: { ids } }),

  searchMaterials: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/materials', {
      data: query,
    }),
  getMaterials: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/materials/ids', {
      params: { ids },
    }),

  searchProcesses: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/processes', {
      data: query,
    }),
  getProcesses: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/processes/ids', { params: { ids } }),

  searchProductGroups: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/productgroups', {
      data: query,
    }),
  getProductGroups: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/productgroups/ids', {
      params: { ids },
    }),
};
