import { Box, useToast } from '@chakra-ui/react';
import { suppliersApi } from '@texas/api/endpoints/suppliersApi';
import {
  VariantBranchSupplier,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { useRegexSearch } from '@texas/components/shared/hooks/useRegexSearch';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SuppliersList } from './SuppliersList';
import { request } from '@texas/utils/helpers/httpHelpers';

export function AllSuppliers({
  variantId,
  branchId,
  onAdd,
}: {
  variantId: number;
  branchId: number;
  onAdd?: (data: VariantBranchSupplier) => void;
}) {
  const {
    data: AllSuppliers,
    refetch,
    loading,
    set,
  } = useApiResource(suppliersApi.variant.getAllBranchSuppliers);

  const { t } = useTranslation();
  const toast = useToast();

  const { search, setSearch, regexSearch } = useRegexSearch();

  const addSupplierBranch = useApiRequest(variantsApi.addSupplierBranch);

  useEffect(() => {
    refetch(variantId, branchId);
  }, [branchId, refetch, variantId]);

  return (
    <LoadingOverlayV2 loaded={!loading} label={t('general.loading')}>
      <Box pos="sticky" top={0} zIndex={1} bg="texas.bg.900" pt={2} gap={2}>
        <SearchFilterInput
          value={search}
          onChange={(searchTerm) => setSearch(searchTerm)}
          debounceDelay={0}
          placeholder={t('suppliers.filterSuppliers')}
        />
      </Box>

      <SuppliersList
        suppliers={
          AllSuppliers?.filter((c) =>
            c.name.toLowerCase().match(regexSearch),
          ) ?? []
        }
        onClick={async (id: number) => {
          await request(
            addSupplierBranch.request,
            [variantId, branchId, id],
            (data) => {
              if (!AllSuppliers) return;

              set((s) =>
                s!.map((x) => {
                  if (x.id === data.supplierId) {
                    return { ...x, alreadyAdded: true };
                  }

                  return x;
                }),
              );
              if (onAdd) {
                onAdd(data);
              }
            },
            (error) => {
              toast({
                title: t('general.createFailed'),
                description: error.message,
                status: 'error',
                isClosable: true,
              });
            },
          );
        }}
      />
    </LoadingOverlayV2>
  );
}
