import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { fadeInScaleAnimation } from '../../../resources/animations/animations';
import useChakraOutsideClick, {
  Modal,
  ModalState,
} from '@texas/hooks/useChakraOutsideClick';

interface FloaterProps {
  modal: Modal<HTMLDivElement>;
  children: React.ReactNode;
}

export function useFloater(initialState: ModalState) {
  return useChakraOutsideClick<HTMLDivElement>(initialState, true);
}

export const Floater = React.forwardRef<HTMLDivElement, FloaterProps>(
  function Floater(props, ref) {
    const [displayState, setDisplayState] = useState(props.modal.modalState);

    useEffect(() => {
      function setState(state: ModalState) {
        setDisplayState(state);
      }

      if (props.modal.modalState === ModalState.Closed) {
        setState(props.modal.modalState);
      } else if (props.modal.modalState === ModalState.Open) {
        setState(props.modal.modalState);
      }
    }, [props.modal.modalState]);

    return displayState === ModalState.Closed ? null : (
      <Box
        animation={fadeInScaleAnimation(200)}
        zIndex={1}
        ref={ref}
        position="absolute"
        left="50%"
        minW={24}
        backgroundColor="rgba(255,255,255,0.9)"
        padding={1}
        borderRadius={22}
        color="black"
        boxShadow="2xl"
      >
        {props.children}
      </Box>
    );
  },
);
