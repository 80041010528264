import { Button, Grid, Heading, VStack, Text } from '@chakra-ui/react';
import FinalizeError from '@texas/api/dto/variant/finalizeErrorTypes';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { branchCanFinalize } from '@texas/utils/helpers/texas/branchHelpers';
import { useTranslation } from 'react-i18next';

interface SelectSyncProps {
  syncedVariantId?: number;
  variantId: number;
  syncSelected: (branchIds: number[]) => void;
  isLoading: boolean;
  allBranchIds: number[];
  selectedBranchIds: number[];
  validations: FinalizeError[] | null;
}

export function SelectSync(props: SelectSyncProps) {
  const canFinalizeAllBranches = branchCanFinalize(
    props.allBranchIds,
    props.validations,
  );
  const canFinalize = branchCanFinalize(
    props.selectedBranchIds,
    props.validations,
  );

  const { t } = useTranslation();

  return (
    <VStack w="100%">
      <Heading
        textTransform="uppercase"
        fontFamily="swiss"
        color="gray.100"
        size="md"
      >
        {t('variant.sync.howWouldYouLikeToSync')}
      </Heading>
      {!props.syncedVariantId && (
        <Text fontSize={12} color="texas.sand.100">
          <b>{t('general.note').toUpperCase()}: </b>
          {t('variant.sync.firstSync')}
        </Text>
      )}
      <Grid templateColumns="1fr 1fr" w="100%" columnGap={2}>
        <VStack>
          <Text color="gray.300">{t('variant.sync.allBranches')}</Text>
          <VerifyButton
            buttonProps={{
              w: '100%',
              variant: 'texas-success-solid',
              isLoading: props.isLoading,
              isDisabled: !canFinalizeAllBranches,
            }}
            onVerifyButtonProps={{
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'texas.sand.100',
            }}
            onVerified={() => props.syncSelected(props.allBranchIds)}
            label={
              canFinalizeAllBranches
                ? t('variant.sync.allBranches')
                : t('variant.sync.errorsInBranches')
            }
          />
        </VStack>
        <VStack>
          <Text color="gray.300">{t('variant.sync.selectedBranches')}</Text>
          <Button
            w="100%"
            variant="texas-success-solid"
            onClick={() => props.syncSelected(props.selectedBranchIds)}
            isLoading={props.isLoading}
            isDisabled={!canFinalize}
          >
            {props.selectedBranchIds.length > 0
              ? canFinalize
                ? t('variant.sync.syncSelectedBranches')
                : t('variant.sync.errorsInBranches')
              : t('variant.sync.noBranchesSelected')}
          </Button>
        </VStack>
      </Grid>
    </VStack>
  );
}
