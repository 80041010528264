import { useToast } from '@chakra-ui/react';
import {
  CompositionMaterial,
  CreateCompositionMaterialRequest,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useTranslation } from 'react-i18next';
import { CompositionMaterialForm } from '../shared/CompositionMaterialForm';
import { RefObject } from 'react';

interface CreateCompositionProps extends SharedDisclosureProps {
  compositionId: number;
  productGroupId: number;
  onCreate: (data: CompositionMaterial) => void;
  drawerRef?: RefObject<HTMLElement>;
}

export function CreateCompositionTemplate({
  compositionId,
  productGroupId,
  isOpen,
  onClose,
  onCreate,
  drawerRef,
}: CreateCompositionProps) {
  const { request: createRequest } = useApiRequest(
    compositionApi.createCompositionMaterial,
  );

  const toast = useToast();
  const { t } = useTranslation();

  const onSubmit = async (data: CreateCompositionMaterialRequest) =>
    await request(
      createRequest,
      [compositionId, data],
      (response) => {
        onCreate(response);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <CompositionMaterialForm
      header={t('composition.createVariantComposition')}
      loading={false}
      onClose={onClose}
      isOpen={isOpen}
      productGroupId={productGroupId}
      onFormSubmit={(data) => onSubmit(data)}
      drawerRef={drawerRef}
    />
  );
}
