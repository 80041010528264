import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  IconButton,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Portal,
} from '@chakra-ui/react';
import { Profit } from './shared';
import { useTranslation } from 'react-i18next';
import { exchangeRateCalculation } from '@texas/utils/helpers/numberHelpers';
import { Icons } from '@texas/components/shared/Icons';

interface MarginCalculationProps {
  purchasePrice: number;
  purchaseExchangeRate: number;
  salesPrice: number;
  salesExchangeRate: number;
  targetExchangeRate: number;
  targetCurrencyCode: string;
  profit: Profit;
}

export function MarginCalculation(props: MarginCalculationProps) {
  const { t } = useTranslation();
  if (props.profit !== Profit.Bad) return null;

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <IconButton
          variant="ghost"
          size="xs"
          icon={<Icons.Calculator />}
          aria-label="calculator"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent minW={{ base: '100%', lg: 'max-content' }}>
          <PopoverArrow />
          <PopoverBody>
            <TableContainer>
              <Table
                size="sm"
                fontSize={12}
                css={{
                  td: { fontSize: '12px', padding: 0 },
                  ' td[data-operator="1"]': {
                    textAlign: 'center',
                    fontWeight: 'bold',
                  },
                }}
              >
                <Thead>
                  <Tr
                    css={{
                      td: {
                        fontWeight: 'bold',
                        minWidth: '16px',
                        color: 'white',
                      },
                    }}
                  >
                    <Td />
                    <Td>{t('salesMatrix.price')}</Td>
                    <Td w={2} />
                    <Td>
                      {t('variant.marginCalculations.exchange')} <br />
                      {t('variant.marginCalculations.rate')}
                    </Td>
                    <Td w={2} />
                    <Td>
                      {t('variant.marginCalculations.target')} <br />
                      {t('variant.marginCalculations.exchangeRate')}
                      {` (${props.targetCurrencyCode})`}
                    </Td>
                    <Td w={2} />
                    <Td>{t('general.total')}</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr color="white">
                    <Td pr="12px !important">
                      {t('variant.marginCalculations.purchase')}
                    </Td>
                    <Td>{props.purchasePrice.toFixed(5)}</Td>
                    <Td data-operator="1">*</Td>
                    <Td>{props.purchaseExchangeRate}</Td>
                    <Td data-operator="1">/</Td>
                    <Td>{props.targetExchangeRate}</Td>
                    <Td data-operator="1">=</Td>
                    <Td>
                      {exchangeRateCalculation(
                        props.purchasePrice,
                        props.purchaseExchangeRate,
                        props.targetExchangeRate,
                        5,
                      ).toFixed(5)}
                    </Td>
                  </Tr>
                  <Tr color="texas.danger.100">
                    <Td pr={2}>{t('variant.marginCalculations.sales')}</Td>
                    <Td>{props.salesPrice.toFixed(5)}</Td>
                    <Td data-operator="1">*</Td>
                    <Td>{props.salesExchangeRate}</Td>
                    <Td data-operator="1">/</Td>
                    <Td>{props.targetExchangeRate}</Td>
                    <Td data-operator="1">=</Td>
                    <Td>
                      {exchangeRateCalculation(
                        props.salesPrice,
                        props.salesExchangeRate,
                        props.targetExchangeRate,
                        5,
                      ).toFixed(5)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
