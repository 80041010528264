import {
  Box,
  FormControl,
  Input,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ServerError } from '../../../../types';
import { request } from '../../../../utils/helpers/httpHelpers';
import { getEnumNamesAndValues } from '../../../../utils/helpers/enumHelpers';
import { addSpaceBeforeUppercaseLetter } from '../../../../utils/helpers/commonHelpers';
import {
  UserProfile,
  CreateUserProfileRequest,
  userProfilesApi,
  UserProfileType,
} from '@texas/api/endpoints/userProfilesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';

export interface CreateUserProfileFormProps {
  userId: number;
  onProfileCreated: (userProfile: UserProfile) => void;
}

const defaultValues = {
  name: '',
  type: UserProfileType.KeyAccountManager,
};

export function CreateUserProfileForm(props: CreateUserProfileFormProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createUserProfileRequest, error } = useApiRequest(
    userProfilesApi.createUserProfile,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateUserProfileRequest>({
    defaultValues: {
      userId: props.userId,
    },
  });

  const onSubmit = async (data: CreateUserProfileRequest) =>
    await request(
      createUserProfileRequest,
      [data],
      (data: UserProfile) => {
        reset({ ...defaultValues, userId: props.userId });
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        props.onProfileCreated(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Box>
      <VStack>
        {error && <ErrorDetails error={error} />}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <VStack>
            <FormControl isInvalid={!!errors.type}>
              <TexasFormLabel>
                {t('userProfile.userProfileType')}
              </TexasFormLabel>
              <Select {...register('type', { required: true })}>
                {getEnumNamesAndValues(UserProfileType).map((p) => {
                  return (
                    <option key={p.value} value={p.value}>
                      {addSpaceBeforeUppercaseLetter(p.name)}
                    </option>
                  );
                })}
              </Select>
              <ErrorLabel text={errors.type?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>
                {t('userProfile.userProfileName')}
              </TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 25,
                    message: t('errors.maxLength', { count: 25 }),
                  },
                })}
                placeholder="Name"
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <SubmitButton loading={isSubmitting}>
              {t('general.create')}
            </SubmitButton>
          </VStack>
        </form>
      </VStack>
    </Box>
  );
}
