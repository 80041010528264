const stickyContent = () => ({
  restrict: 'A',
  scope: false,
  controller: 'StickyContentController',
});

class StickyContentController {
  constructor($scope, $element, $window) {
    'ngInject';
    this.scope = $scope;
    this.element = $element;
    this.window = $window;
    this.top = this.element[0].getBoundingClientRect().top;
    this.setStickyContent = this.setStickyContent.bind(this);
    this.setupEventListners();
  }

  setStickyContent() {
    const stickyClass = 'sticky-content';
    if (
      !this.element.hasClass(stickyClass) &&
      this.window.pageYOffset > this.top
    ) {
      this.element.addClass(stickyClass);
    } else if (
      this.element.hasClass(stickyClass) &&
      this.window.pageYOffset <= this.top
    ) {
      this.element.removeClass(stickyClass);
    }
  }

  setupEventListners() {
    angular.element(this.window).bind('scroll', this.setStickyContent);
    this.scope.$on('$destroy', () =>
      angular.element(this.window).unbind('scroll', this.setStickyContent),
    );
  }
}

angular
  .module('directives.stickyContent', [])
  .controller('StickyContentController', StickyContentController)
  .directive('stickyContent', stickyContent);
