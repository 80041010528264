import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesInformation } from '@texas/api/endpoints/salesInformationApi';
import { TierPrice } from '@texas/api/endpoints/tierPriceApi';
import {
  marginBy,
  exchangeRateCalculation,
  getRoundedPurchasePriceWithFees,
} from '@texas/utils/helpers/numberHelpers';

export enum Profit {
  Bad,
  Ok,
  Good,
}

interface ProfitThreshold {
  bad: number;
  ok: number;
}

export const standardProfitThreshold: ProfitThreshold = {
  bad: 11,
  ok: 29.9999,
};
export const abovePurchaseProfitThreshold: ProfitThreshold = {
  bad: -0.00001,
  ok: -0.00001,
};

export function evaluateProfit(
  sales: number,
  purchase: number,
  threshold: ProfitThreshold = standardProfitThreshold,
): Profit {
  if (sales === 0 && purchase === 0) {
    return Profit.Good;
  }

  const margin = marginBy(sales, purchase, false);

  if (margin <= threshold.bad) {
    return Profit.Bad;
  } else if (margin <= threshold.ok) {
    return Profit.Ok;
  } else {
    return Profit.Good;
  }
}

export function profitColor(profit: Profit) {
  switch (profit) {
    case Profit.Bad:
      return 'texas.danger.100';
    case Profit.Ok:
      return 'texas.warning.100';
    case Profit.Good:
      return 'inherit';
    default:
      return 'inherit';
  }
}

export interface SalesCurrencyEvaluation {
  salesCurrencyId: number;
  warnings: SalesWarning[];
}

export interface SalesWarning {
  field: string;
  type: Profit;
}

export function findWorstProfit(warnings: SalesWarning[]) {
  if (warnings.length === 0) return Profit.Good;

  let worstProfit = Profit.Good;

  warnings.forEach((w) => {
    if (w.type < worstProfit) {
      worstProfit = w.type;
    }
  });

  return worstProfit;
}

export function evaluateSales(
  sales: SalesInformation,
  tierPrices: TierPrice[],
  isMatrix: boolean,
  purchase?: PurchaseInformation,
): SalesCurrencyEvaluation[] {
  if (!purchase) {
    return [];
  }

  const evaluations = sales.currencies
    .map((c) => {
      const salesWarnings: SalesWarning[] = [];

      salesWarnings.push({
        type: evaluateProfit(
          exchangeRateCalculation(
            c.additionalCharge.sampleCharge,
            c.fixedExchangeRate,
            purchase.fixedExchangeRate,
          ),
          purchase.sampleCharge,
          abovePurchaseProfitThreshold,
        ),
        field: 'Sample charge',
      });
      salesWarnings.push({
        type: evaluateProfit(
          exchangeRateCalculation(
            c.additionalCharge.mouldCharge,
            c.fixedExchangeRate,
            purchase.fixedExchangeRate,
          ),
          purchase.mouldCharge,
          abovePurchaseProfitThreshold,
        ),
        field: 'Mould charge',
      });
      salesWarnings.push({
        type: evaluateProfit(
          exchangeRateCalculation(
            c.additionalCharge.surcharge,
            c.fixedExchangeRate,
            purchase.fixedExchangeRate,
          ),
          purchase.surcharge,
          abovePurchaseProfitThreshold,
        ),
        field: 'Surcharge',
      });

      if (!isMatrix) {
        tierPrices.map((t) => {
          const salesPrice = t.salesPrices.find(
            (s) => s.salesCurrencyId === c.id,
          );
          const purchasePrice = t.basePrices.find(
            (b) => b.supplierId === purchase.supplierId,
          );

          if (!purchasePrice) return;

          salesWarnings.push({
            type: evaluateProfit(
              exchangeRateCalculation(
                salesPrice?.price ?? 0,
                c.fixedExchangeRate,
                purchase.fixedExchangeRate,
              ),
              purchasePrice.price,
            ),
            field: `Sales price QTY ${t.quantity}`,
          });
        });
      }

      return {
        salesCurrencyId: c.id,
        warnings: salesWarnings.filter((w) => w.type === Profit.Bad),
      };
    })
    .filter((c) => c.warnings.length > 0);

  return evaluations;
}

export function roundedPurchasePriceWithFees(
  price: number,
  buyingRate: number,
  purchaseInformation?: PurchaseInformation,
) {
  const purchasePriceWithFees = getRoundedPurchasePriceWithFees(
    price,
    purchaseInformation?.freightCostValue ?? 0,
    purchaseInformation?.handlingFeeValue ?? 0,
    5,
  );
  return exchangeRateCalculation(
    purchasePriceWithFees,
    purchaseInformation?.fixedExchangeRate ?? 0,
    buyingRate,
    5,
  );
}

export function setValueAsDecimalExtender(
  value: any,
  emptyAsNull: true,
): number | null;
export function setValueAsDecimalExtender(
  value: any,
  emptyAsNull: false,
): number;
export function setValueAsDecimalExtender(value: any, emptyAsNull: boolean) {
  if (typeof value === 'number') return value;
  else if (value === '' || value === null) return emptyAsNull ? null : 0;
  return parseFloat(value.replace(',', '.'));
}
