import {
  useToast,
  Flex,
  Image,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Badge,
  VStack,
  HStack,
  Tag,
} from '@chakra-ui/react';
import {
  Composition,
  compositionApi,
  CompositionData,
  CompositionMaterial,
} from '@texas/api/endpoints/compositionApi';
import placeholderImg from '@assets/placeholder-250.png';
import { GetColorFromCode } from '../pantonePicker/PantonePicker';
import {
  fadeInLeftAnimation,
  fadeInScaleAnimation,
  scaleInHeightAnimation,
} from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { ColorDot } from './ColorDot';
import { ColorBox } from './ColorBox';
import useFormat from '@texas/hooks/useFormat';
import { Icons } from '@texas/components/shared/Icons';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';

export function CompositionView({
  composition,
  archiveDisabled,
  onRestore,
}: {
  composition: Composition;
  archiveDisabled: boolean;
  onRestore?: (data: Composition) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { formatWeight } = useFormat();
  const { request: restoreRequest, loading } = useApiRequest(
    compositionApi.restoreComposition,
  );
  return (
    <Box>
      {composition.exists ? (
        <Flex flexDir="column" gap={2} animation={fadeInLeftAnimation()}>
          {composition.archived && !archiveDisabled && (
            <Flex justify="center" gap={2} mt={2}>
              <Tag color="texas.sand.100">
                <Icons.AlertCircle mr={1} />
                {`${t('general.archived')} ${formatDate(
                  Locale.En,
                  composition.archived,
                )}`}
              </Tag>
            </Flex>
          )}
          {composition.archived && archiveDisabled && (
            <Box
              animation={fadeInScaleAnimation(200)}
              pos="absolute"
              boxSize="100%"
              bg="texas.bg.90085"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <VStack spacing={1}>
                <Icons.Archive boxSize={6} />
                <HStack
                  fontSize={12}
                  animation={scaleInHeightAnimation('32px', 400, 200)}
                >
                  <Text variant="header">{`${t(
                    'general.archived',
                  )} ${formatDate(Locale.En, composition.archived)}`}</Text>
                </HStack>
                {!!onRestore && (
                  <VerifyButton
                    buttonProps={{
                      size: 'sm',
                      variant: 'texas-success-solid',
                      isLoading: loading,
                    }}
                    onVerified={async () => {
                      await request(
                        restoreRequest,
                        [composition.id],
                        (response) => {
                          onRestore(response);
                          toast({
                            title: t('general.restored'),
                            status: 'success',
                            isClosable: true,
                          });
                        },
                        (error: ServerError) => {
                          toast({
                            title: t('general.restoreFailed'),
                            description: error.message,
                            status: 'error',
                            isClosable: true,
                          });
                        },
                      );
                      return;
                    }}
                    label={t('general.restore')}
                  />
                )}
              </VStack>
            </Box>
          )}
          <Image
            fallbackSrc={placeholderImg}
            objectFit="contain"
            src={`/api/files/${composition.imageIdentifier}/preview/256`}
          />
          <Flex
            alignSelf="center"
            gap={2}
            flexWrap="wrap"
            justifyContent="center"
          >
            {!composition.dimension && (
              <NoData label={t('composition.noDimension')} />
            )}
            {composition.dimension?.dimensions.map((x) => (
              <Badge key={x.id}>
                {x.label}: {x.value}mm
              </Badge>
            ))}
          </Flex>
          <Box px={4}>
            <Header label="Materials" />
            <Text fontSize="sm">
              {t('composition.weightAmount', {
                amount: formatWeight(composition.weight),
              })}
            </Text>
          </Box>
          <Accordion allowMultiple={true}>
            {composition.materials.map((x) => (
              <MaterialComposition key={x.id} compositionMaterial={x} />
            ))}
          </Accordion>
        </Flex>
      ) : (
        <Text color="gray.400" py={2} textAlign="center">
          {t('composition.noComposition')}
        </Text>
      )}
    </Box>
  );
}

function MaterialComposition({
  compositionMaterial,
}: {
  compositionMaterial: CompositionMaterial;
}) {
  const { t } = useTranslation();
  return (
    <AccordionItem>
      <AccordionButton gap={2} textAlign="start">
        <Flex flexDir="column">
          <Text fontWeight="bold">{compositionMaterial.material.text}</Text>
          <Text
            overflowWrap="anywhere"
            noOfLines={3}
            fontSize="sm"
            color="gray.300"
          >
            {compositionMaterial.note}
          </Text>
        </Flex>

        <Flex
          gap={2}
          ml="auto"
          minW="32px"
          flexWrap="wrap"
          justifyContent="end"
        >
          {compositionMaterial.colors.map((x) => {
            const color = GetColorFromCode(x.pantoneCode);

            if (!color) return null;
            return <ColorDot key={color.name} hex={color.hex} />;
          })}
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <CompositionDataView
          label={t('configuration.qualities')}
          data={compositionMaterial.qualities}
        />
        <CompositionDataView
          label={t('general.techniques')}
          data={compositionMaterial.techniques}
        />
        <CompositionDataView
          label={t('configuration.treatments')}
          data={compositionMaterial.treatments}
        />
        <Header label={t('general.colors')} />
        <Flex flexWrap="wrap" gap={2}>
          {compositionMaterial.colors.length === 0 && <NoData />}
          {compositionMaterial.colors.map((x) => {
            const color = GetColorFromCode(x.pantoneCode);

            if (!color) return null;
            return (
              <ColorBox
                key={color.name}
                colorDescription={x.colorDescription}
                color={color}
              />
            );
          })}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}

function CompositionDataView({
  data,
  label,
}: {
  data: CompositionData[];
  label: string;
}) {
  return (
    <Box>
      <Header label={label} />
      {data.length === 0 && <NoData />}
      {data.map((x) => (
        <Flex fontSize="sm" key={x.id} alignItems="baseline">
          <Icons.CircleMedium pt="0.5" />
          <Text>{x.text}</Text>
          {x.value && <Text fontWeight="bold">: {x.value}</Text>}
        </Flex>
      ))}
    </Box>
  );
}

function Header({ label }: { label: string }) {
  return (
    <Text w="100%" fontWeight="bold" color="whiteAlpha.900" pt={1} pb="0.5">
      {label}
    </Text>
  );
}

function NoData({ label }: { label?: string }) {
  const { t } = useTranslation();

  return <Text color="gray.400">{label ? label : t('general.noData')}</Text>;
}
