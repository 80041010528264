import { httpClient } from '../httpClient/httpClient';

export interface BrandsDto {
  name: string;
  id: number;
  nrOfArticles: number;
  project: string;
}

interface FilterCustomerRequest {
  assignedTo: number | null;
  customerName: string;
}

interface Concept {
  name: string;
  id: number;
}

export const customersApi = {
  filterCustomers: (request: FilterCustomerRequest) =>
    httpClient.post<BrandsDto[]>('customers/filter', { data: request }),
  getConcepts: (customerId: number) =>
    httpClient.get<Concept[]>(`customers/${customerId}/concepts`, {
      params: { includeArticles: false },
    }),
};
