import { Flex, Box, Text } from '@chakra-ui/react';

export function MetadataList({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) {
  if (!Array.isArray(children)) {
    return <Box bg={metaListBackground(0)}>{children}</Box>;
  }

  return (
    <Flex flexDir="column" width="100%">
      {children
        .filter((c) => c !== null)
        .map((c, i) => (
          <Box bg={metaListBackground(i)} key={i} p={2}>
            {c}
          </Box>
        ))}
    </Flex>
  );
}

export function MetadataListRow({
  label,
  dir = 'row',
  children,
}: {
  label: string;
  dir?: 'column' | 'row';
  children: React.ReactNode;
}) {
  return (
    <Flex
      flexDir={dir}
      alignItems={dir === 'row' ? 'center' : 'normal'}
      justify="space-between"
    >
      <Text fontWeight="bold" color="gray.300">
        {label}
      </Text>
      <Box textAlign="right">{children}</Box>
    </Flex>
  );
}

function metaListBackground(index: number) {
  if (index % 2 === 0) {
    return 'gray.700';
  }
  return 'transparent';
}
