import {
  Flex,
  Button,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { useEffect } from 'react';
import { CompositionView } from './CompositionView';
import { useTranslation } from 'react-i18next';

export function ConfirmCopy({
  compositionId,
  onConfirm,
  isOpen,
  title,
  onClose,
}: {
  compositionId: number;
  onConfirm: (id: number) => void;
  isOpen: boolean;
  title: string;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { data, refetch, loading } = useApiResource(
    compositionApi.getComposition,
  );

  useEffect(() => {
    refetch(compositionId);
  }, [compositionId, refetch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Text textAlign="center">{title}</Text>
          <Flex flexDir="column" gap={4}>
            <LoadingOverlayV2 loaded={!loading}>
              {data && (
                <CompositionView composition={data} archiveDisabled={false} />
              )}
            </LoadingOverlayV2>
            <Button onClick={() => onConfirm(compositionId)}>
              {t('composition.copyComposition')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
