// @ngInject
const GarpApiService = (DS, $q, ResourcesService, DSHttpAdapter) => {
  const defer = (action) =>
    action().then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    );

  return {
    getAllCategoryCodes: () =>
      defer(() =>
        DSHttpAdapter.GET(`${DS.defaults.basePath}garp/categorycodes`),
      ),

    getCategoryCodes: (branchId) =>
      defer(() =>
        DSHttpAdapter.GET(
          `${DS.defaults.basePath}garp/branches/${branchId}/categorycodes`,
        ),
      ),

    getSharedCategoryCodes: (variantId) =>
      defer(() =>
        DSHttpAdapter.GET(
          `${DS.defaults.basePath}garp/variants/${variantId}/categorycodes`,
        ),
      ),

    getSharedVariantInfos: () =>
      defer(() =>
        DSHttpAdapter.GET(`${DS.defaults.basePath}garp/variants/categorycodes`),
      ),

    getCustomsCodes: (branchId) =>
      defer(() =>
        DSHttpAdapter.GET(
          `${DS.defaults.basePath}garp/branches/${branchId}/customscodes`,
        ),
      ),

    updateGarpData: (data, variantId) =>
      defer(() =>
        DSHttpAdapter.PUT(
          `${DS.defaults.basePath}garp/variants/${variantId}`,
          data,
        ),
      ),

    getGarpData: (variantId) =>
      defer(() =>
        DSHttpAdapter.GET(`${DS.defaults.basePath}garp/variants/${variantId}`),
      ),

    importMetadata: (branchId) =>
      defer(() =>
        DSHttpAdapter.POST(
          `${DS.defaults.basePath}garp/branches/${branchId}/importmetadata`,
        ),
      ),

    importMatrixNodes: () =>
      defer(() =>
        DSHttpAdapter.POST(`${DS.defaults.basePath}garp/importmatrixnodes`),
      ),

    getArticleBranches: (itemCode) =>
      defer(() =>
        DSHttpAdapter.GET(
          `${DS.defaults.basePath}garp/articleBranches?itemCode=${itemCode}`,
        ),
      ),
  };
};

angular.module('resources.garp', []).factory('GarpApiService', GarpApiService);
