import {
  Flex,
  Box,
  Button,
  IconButton,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from '@chakra-ui/react';
import Select from 'react-select';
import {
  AutoUpdateState,
  ProductApproval,
  ProductApprovalState,
  productApprovalsApi,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import {
  AutoUpdateWrapper,
  useAutoUpdateForm,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';
import { TexasHeader } from '@texas/components/shared/TexasHeader';
import { Icons } from '@texas/components/shared/Icons';
import { getStateOption } from './shared';
import { fadeInAnimation } from '@texas/resources/animations/animations';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import placeholderImg from '@assets/placeholder-250.png';

export function ProductApprovalDetails({
  id,
  refetchAll,
  onClose,
}: {
  id: number;
  refetchAll: () => void;
  onClose: Dispatch<SetStateAction<number>>;
}) {
  const {
    data: productApproval,
    refetch,
    error,
    loading,
  } = useApiResource(productApprovalsApi.get);

  useEffect(() => {
    refetch(id);
  }, [id, refetch]);

  return (
    <>
      {loading && (
        <Flex w="450px" justify="center" align="center">
          <Spinner thickness="3px" color="primary.200" size="lg" />
        </Flex>
      )}

      {!loading && (
        <Box w="450px" animation={fadeInAnimation(400)}>
          <ErrorLabel text={error?.message} />
          {productApproval && (
            <ProductApprovalForm
              refetchAll={refetchAll}
              productApproval={productApproval}
              onClose={onClose}
            />
          )}
        </Box>
      )}
    </>
  );
}

export function ProductApprovalForm({
  productApproval,
  refetchAll,
  onClose,
}: {
  productApproval: ProductApproval;
  refetchAll: () => void;
  onClose: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();

  function onResponse() {
    refetchAll();
  }

  const { registerAutoUpdate, control, triggerSubmit } = useAutoUpdateForm<
    AutoUpdateState,
    AutoUpdateState
  >({
    defaultValue: { state: productApproval.state },
    valueId: productApproval.id,
    namePrefix: 'productApprovalState',
    autoUpdateRequest: productApprovalsApi.autoUpdateState,
    onResponse,
  });

  return (
    <Flex
      direction="column"
      borderRadius={8}
      backgroundColor="texas.bg.900"
      height="100%"
      minHeight={0}
      overflow="hidden"
      p={2}
    >
      <Flex justifyContent="space-between">
        <Flex flexDir="column" pb={2}>
          <TexasHeader text={t('productApproval.details')} />
          <Image
            maxW="256px"
            fallbackSrc={placeholderImg}
            objectFit="contain"
            src={`/api/files/${productApproval.imageIdentifier}/preview/256`}
            borderRadius={8}
          />
        </Flex>
        <IconButton
          aria-label="close details"
          icon={<Icons.Close />}
          onClick={() => onClose(0)}
        />
      </Flex>

      <MetadataList>
        <MetadataListRow label={t('productApproval.number')}>
          {productApproval.formatNumber}
        </MetadataListRow>
        <MetadataListRow label={t('suppliers.supplier')}>
          {productApproval.supplierName}
        </MetadataListRow>
        <MetadataListRow label={t('general.branch')}>
          {productApproval.branchName}
        </MetadataListRow>
        <MetadataListRow label={t('general.option')}>
          {productApproval.variantName}
        </MetadataListRow>
        {productApproval.matrixNodeXId && (
          <MetadataListRow label={productApproval.matrixNodeXName}>
            {productApproval.matrixNodeXValue}
          </MetadataListRow>
        )}
        {productApproval.matrixNodeYId && (
          <MetadataListRow label={productApproval.matrixNodeYName}>
            {productApproval.matrixNodeYValue}
          </MetadataListRow>
        )}
        <MetadataListRow label={t('general.state')}>
          <AutoUpdateWrapper
            autoUpdateProps={registerAutoUpdate}
            path="state"
            boxProps={{ flexGrow: '1' }}
          >
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={reactSelectStyle<false>({ borderStyle: 'none' })}
                  theme={(theme) => reactSelectTheme(theme)}
                  value={getStateOption(field.value)}
                  onChange={(e) => {
                    field.onChange(
                      convertToEnum(ProductApprovalState, e?.value),
                    );
                    triggerSubmit();
                    refetchAll();
                  }}
                  options={getEnumNamesAndValues(ProductApprovalState).map(
                    (s) => {
                      return { label: s.name, value: s.value };
                    },
                  )}
                  closeMenuOnSelect={true}
                />
              )}
            />
          </AutoUpdateWrapper>
        </MetadataListRow>
        <MetadataListRow label={t('article.created')}>
          {formatDate(Locale.En, productApproval.created)}
        </MetadataListRow>
        <MetadataListRow label={t('suppliers.supplierContact')}>
          {productApproval.supplierContactName}
        </MetadataListRow>
        <MetadataListRow label={t('customer.customerContact')}>
          {productApproval.customerContactName}
        </MetadataListRow>
        <MetadataListRow label={t('productApproval.issuedBy')}>
          {productApproval.issuedBy}
        </MetadataListRow>
      </MetadataList>
      <Flex py={2} gap={4}>
        <Button
          variant="texas-success-solid"
          rightIcon={<Icons.Download />}
          as="a"
          href={productApprovalsApi.downloadSupplierProductApproval(
            productApproval.id,
          )}
          target="_blank"
          download={true}
        >
          {t('productApproval.downloadSpa')}
        </Button>
        <Menu>
          <MenuButton
            as={Button}
            variant="texas-success-solid"
            rightIcon={<Icons.Download />}
          >
            {t('productApproval.downloadPa')}
          </MenuButton>
          <MenuList>
            <MenuItem
              as="a"
              target="_blank"
              download={true}
              href={productApprovalsApi.downloadCustomerProductApproval(
                productApproval.id,
                true,
              )}
            >
              {t('productApproval.excludeMaterialSpecifications')}
            </MenuItem>
            <MenuItem
              as="a"
              target="_blank"
              download={true}
              href={productApprovalsApi.downloadCustomerProductApproval(
                productApproval.id,
                false,
              )}
            >
              {t('productApproval.includeMaterialSpecifications')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
