import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { MatrixGrid } from '../../../../shared/matrix/MatrixGrid';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ApiRequestType } from '@texas/api/hooks/useApiRequest';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CellData {
  x: number;
  y: number;
}

interface CellRequest<A extends any[], TData extends CellData> {
  request: ApiRequestType<A, TData[]>;
  requestData: A;
}

interface SelectCellModalProps<A extends any[], TData extends CellData>
  extends SharedDisclosureProps {
  articleNodeGroup: ArticleNodeGroup;
  loadCellDataRequest: CellRequest<A, TData>;
  selectedCell: (
    data: TData | undefined,
    xIndex: number,
    yIndex: number,
  ) => void;
}

export function SelectCellModal<A extends any[], TData extends CellData>({
  articleNodeGroup,
  selectedCell,
  isOpen,
  onClose,
  loadCellDataRequest,
}: SelectCellModalProps<A, TData>) {
  const [cellData, setCellData] = useState<TData[]>();

  useMemo(() => {
    loadCellDataRequest
      .request(...loadCellDataRequest.requestData)
      .then((d) => {
        if (d.hasError) {
          return;
        }
        setCellData(d.data);
      });
  }, [loadCellDataRequest]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <MatrixGrid
            articleNodeGroup={articleNodeGroup}
            cell={(x, y, xIndex, yIndex) => {
              const data = cellData?.find((c) => c.x === x && c.y === y);
              return (
                <Cell
                  hasValue={data !== undefined}
                  onClick={() => selectedCell(data, xIndex, yIndex)}
                />
              );
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function Cell({
  hasValue,
  onClick,
}: {
  hasValue: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();
  if (!hasValue) return null;

  return (
    <Button onClick={() => onClick()}>{t('composition.chooseTemplate')}</Button>
  );
}
