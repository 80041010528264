import {
  ProductApproval,
  productApprovalsApi,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Icons } from '../Icons';
import { Flex, Tag } from '@chakra-ui/react';
import { reactEvents } from '@bridge/reactEvents';

export function ProductApprovalLockedMessage({
  variantId,
  branchId = null,
  matrixNodeXId = null,
  matrixNodeYId = null,
  isOnlyInfo = false,
  textColor = 'gray.600',
}: {
  variantId: number;
  branchId?: number | null;
  matrixNodeXId?: number | null;
  matrixNodeYId?: number | null;
  isOnlyInfo?: boolean;
  textColor?: string;
}) {
  const { t } = useTranslation();
  const { data: productApprovals, refetch: refetchProductApprovals } =
    useApiResource(productApprovalsApi.getForVariant);

  useEffect(() => {
    refetchProductApprovals(variantId);
  }, [variantId, refetchProductApprovals]);
  const message = getMessage(
    t,
    productApprovals,
    branchId,
    matrixNodeXId,
    matrixNodeYId,
    isOnlyInfo,
  );

  return (
    <>
      {message && (
        <Flex mb="30px">
          <Tag
            p={2}
            alignItems="center"
            textTransform="uppercase"
            textColor={textColor}
          >
            <Icons.InformationOutline boxSize={8} pr={2} />
            {message}
          </Tag>
        </Flex>
      )}
    </>
  );
}

function getMessage(
  t: TFunction,
  productApprovals: ProductApproval[] | null,
  branchId?: number | null,
  matrixNodeXId?: number | null,
  matrixNodeYId?: number | null,
  isOnlyInfo?: boolean,
) {
  if (productApprovals === null || productApprovals.length === 0) {
    reactEvents.lockedStateChanged.dispatch({ locked: false });
    return null;
  }

  const isSharedBranch = branchId === undefined || branchId === null;

  if (isOnlyInfo && isSharedBranch) {
    const branchNames = productApprovals.map((pa) => pa.branchName);
    reactEvents.lockedStateChanged.dispatch({ locked: false });
    return t('productApproval.existsBranches', { val: branchNames });
  }

  const isMatrix =
    productApprovals.filter(
      (pa) => pa.matrixNodeXId !== null && pa.matrixNodeYId !== null,
    ).length > 0;
  const isMatrixCell = matrixNodeXId !== null && matrixNodeYId !== null;
  const isMatrixHeader =
    !isMatrixCell && (matrixNodeXId != null || matrixNodeYId != null);
  const branchProductApprovals = productApprovals.filter(
    (pa) => pa.branchId === branchId,
  );
  let sharedMatrixProductApprovals = productApprovals.filter(
    (pa) =>
      pa.matrixNodeXId === matrixNodeXId && pa.matrixNodeYId == matrixNodeYId,
  );

  if (isSharedBranch && !isMatrixCell && !isMatrixHeader) {
    reactEvents.lockedStateChanged.dispatch({ locked: true });
    const branchNames = productApprovals.map((pa) => pa.branchName);
    return t('productApproval.lockedBranch', { val: branchNames });
  }

  if (isOnlyInfo && !isMatrix && branchProductApprovals.length === 1) {
    reactEvents.lockedStateChanged.dispatch({ locked: false });
    return t('productApproval.exists');
  }

  if (isOnlyInfo && isMatrix && !isMatrixCell && !isMatrixHeader) {
    reactEvents.lockedStateChanged.dispatch({ locked: false });
    return t('productApproval.existsMatrixCells');
  }

  if (isSharedBranch && sharedMatrixProductApprovals.length > 0) {
    reactEvents.lockedStateChanged.dispatch({ locked: true });
    const branchNames = sharedMatrixProductApprovals.map((pa) => pa.branchName);
    return t('productApproval.lockedBranch', { val: branchNames });
  }

  sharedMatrixProductApprovals = productApprovals.filter(
    (pa) =>
      pa.matrixNodeXId === matrixNodeXId || pa.matrixNodeYId == matrixNodeYId,
  );

  if (
    isMatrixHeader &&
    isSharedBranch &&
    sharedMatrixProductApprovals.length > 0
  ) {
    const branchNames = sharedMatrixProductApprovals.map((pa) => pa.branchName);
    reactEvents.lockedStateChanged.dispatch({ locked: true });
    return t('productApproval.lockedBranch', { val: branchNames });
  }

  if (branchProductApprovals.length === 0) {
    reactEvents.lockedStateChanged.dispatch({ locked: false });
    return null;
  }

  sharedMatrixProductApprovals = branchProductApprovals.filter(
    (pa) =>
      pa.matrixNodeXId === matrixNodeXId || pa.matrixNodeYId == matrixNodeYId,
  );

  if (isMatrixHeader && sharedMatrixProductApprovals.length > 0) {
    reactEvents.lockedStateChanged.dispatch({ locked: true });
    return t('productApproval.lockedMatrixCells');
  }

  if (branchProductApprovals.length === 1 && !isMatrixCell && !isMatrixHeader) {
    reactEvents.lockedStateChanged.dispatch({ locked: true });
    return t('productApproval.locked', {
      paNumber: branchProductApprovals[0].formatNumber,
    });
  }

  const branchMatrixProductApproval = branchProductApprovals.find(
    (pa) =>
      pa.matrixNodeXId === matrixNodeXId && pa.matrixNodeYId == matrixNodeYId,
  );

  if (!branchMatrixProductApproval) {
    reactEvents.lockedStateChanged.dispatch({ locked: false });
    return null;
  }

  reactEvents.lockedStateChanged.dispatch({ locked: true });

  return t('productApproval.locked', {
    paNumber: branchMatrixProductApproval.formatNumber,
  });
}
