import { Badge } from '@chakra-ui/react';

import {
  marginBy,
  exchangeRateCalculation,
} from '@texas/utils/helpers/numberHelpers';
import { roundedPurchasePriceWithFees } from '../shared';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';

interface MarginProps {
  salesCurrency: SalesCurrency;
  sellingExchangeRate: number;
  price: number;
  purchasePrice: number;
  purchaseInformation: PurchaseInformation | undefined;
}

export function Margin(props: MarginProps) {
  return (
    <Badge display="flex" alignItems="center" variant="solid" w="fit-content">
      %
      {marginBy(
        exchangeRateCalculation(
          props.price,
          props.salesCurrency.fixedExchangeRate,
          props.sellingExchangeRate,
          5,
        ),
        roundedPurchasePriceWithFees(
          props.purchasePrice,
          props.sellingExchangeRate,
          props.purchaseInformation,
        ),
        true,
      )}
    </Badge>
  );
}
