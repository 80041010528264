import {
  useToast,
  Grid,
  GridItem,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useDisclosure,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { ApiRequestType, useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewState } from './types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { Icons } from '@texas/components/shared/Icons';
import {
  Composition,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { ConfirmCopy } from './ConfirmCopy';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { SelectCellModal } from '../matrix/SelectCellModal';
import { formatNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';

interface CompositionLocationData {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

export function TemplateViewFooter<A extends any[]>({
  state,
  setState,
  onCreated,
  onArchive,
  onReplaced,
  composition,
  apiRequest,
  data,
  locationData,
  loading,
  canBeReplaced,
  articleNodeGroup,
  baseTemplateId,
}: {
  state: ViewState;
  setState: Dispatch<SetStateAction<ViewState>>;
  onCreated: (data: Composition) => void;
  onArchive: (data: Composition) => void;
  onReplaced: ((data: Composition) => void) | null;
  apiRequest: ApiRequestType<A, Composition>;
  data: A;
  locationData: CompositionLocationData;
  composition: Composition | null;
  loading: boolean;
  canBeReplaced: boolean;
  articleNodeGroup?: ArticleNodeGroup;
  baseTemplateId: number | null;
}) {
  const toast = useToast();
  const { t } = useTranslation();

  const { request: archiveRequest } = useApiRequest(
    compositionApi.archiveComposition,
  );

  const { request: copyRequest } = useApiRequest(
    compositionApi.copyComposition,
  );

  const {
    onOpen: selectCellOpen,
    onClose: selectCellClose,
    isOpen: isSelectCellOpen,
  } = useDisclosure();
  const {
    onOpen: onCopyOpen,
    onClose: onCopyClose,
    isOpen: isCopyOpen,
    value,
  } = useValueDisclosure<{
    location: {
      supplierId?: number;
      variantId: number;
      cellX?: number;
      cellY?: number;
    };
    fromComposition: number;
    copyTitle: string;
  }>();
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Grid columnGap="2">
      <GridItem colStart={1}>
        <Flex gap={2}>
          <Button
            isDisabled={composition?.archived ? true : false}
            w="full"
            onClick={async () => {
              if (composition?.exists) {
                if (state === 'view') {
                  setState('edit');
                  return;
                }
                setState('view');
                return;
              }

              setIsCreating(true);
              await request(
                apiRequest,
                data,
                (response) => {
                  setIsCreating(false);
                  onCreated(response);
                  setState('edit');
                  toast({
                    title: t('general.created'),
                    status: 'success',
                    isClosable: true,
                  });
                },
                (error: ServerError) => {
                  setIsCreating(false);
                  toast({
                    title: t('general.createFailed'),
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                  });
                },
              );
            }}
            isLoading={loading || isCreating}
          >
            {state === 'view' ? (
              composition?.exists ? (
                <>{t('composition.editTemplate')}</>
              ) : (
                <>{t('composition.createTemplate')}</>
              )
            ) : (
              <>{t('general.back')}</>
            )}
          </Button>
          {state === 'view' &&
            !composition?.archived &&
            (baseTemplateId || (composition?.exists && !baseTemplateId)) && (
              <Menu placement="top">
                <MenuButton as={IconButton} icon={<Icons.DotsHorizontal />} />
                <MenuList color="white">
                  {composition?.exists && !composition.archived && (
                    <MenuItem
                      color="texas.sand.50"
                      icon={<Icons.Archive boxSize={5} />}
                      onClick={async () => {
                        await request(
                          archiveRequest,
                          [composition.id],
                          (response) => {
                            onArchive(response);
                            toast({
                              title: t('general.successfullyArchived'),
                              status: 'success',
                              isClosable: true,
                            });
                          },
                          (error: ServerError) => {
                            toast({
                              title: t('general.archiveFailed'),
                              description: error.message,
                              status: 'error',
                              isClosable: true,
                            });
                          },
                        );
                      }}
                    >
                      {t('general.archive')}
                    </MenuItem>
                  )}

                  {composition?.exists && canBeReplaced && <MenuDivider />}
                  {canBeReplaced && (
                    <MenuGroup
                      title={
                        composition?.exists
                          ? t('general.replace')
                          : t('general.create')
                      }
                    >
                      <MenuItem
                        icon={<Icons.ArrowRightTopBold />}
                        isDisabled={
                          !baseTemplateId || composition?.archived !== null
                        }
                        onClick={() =>
                          onCopyOpen({
                            location: locationData,
                            fromComposition: baseTemplateId!,
                            copyTitle: t('composition.fromVariantBase'),
                          })
                        }
                      >
                        {composition?.exists
                          ? t('composition.replaceVariantTemplate')
                          : t('composition.sameAsVariantTemplate')}
                      </MenuItem>
                      {articleNodeGroup && (
                        <MenuItem
                          icon={<Icons.ArrowRightTopBold />}
                          isDisabled={composition?.archived !== null}
                          onClick={() => {
                            selectCellOpen();
                          }}
                        >
                          {composition?.exists
                            ? t('composition.replaceCellTemplate')
                            : t('composition.sameAsCellTemplate')}
                        </MenuItem>
                      )}
                    </MenuGroup>
                  )}
                </MenuList>
              </Menu>
            )}
        </Flex>
      </GridItem>

      {value?.fromComposition && canBeReplaced && (
        <ConfirmCopy
          title={value.copyTitle}
          isOpen={isCopyOpen}
          onClose={onCopyClose}
          compositionId={value.fromComposition}
          onConfirm={async (id) => {
            onCopyClose();
            selectCellClose();
            setIsCreating(true);
            await request(
              copyRequest,
              [
                id,
                {
                  variantId: locationData.variantId,
                  matrixNodeXId: locationData.cellX ?? null,
                  matrixNodeYId: locationData.cellY ?? null,
                  branchId: locationData.branchId ?? null,
                  supplierId: locationData.supplierId ?? null,
                },
              ],
              (data) => {
                setIsCreating(false);
                if (!onReplaced) return;
                onReplaced(data);
                toast({
                  title: t('general.replaced'),
                  status: 'success',
                  isClosable: true,
                });
              },
              (error: ServerError) => {
                setIsCreating(false);
                toast({
                  title: t('general.replaceFailed'),
                  description: error.message,
                  status: 'error',
                  isClosable: true,
                });
              },
            );
          }}
        />
      )}

      {articleNodeGroup && (
        <SelectCellModal
          loadCellDataRequest={{
            request: compositionApi.getAllCellCompositionTemplates,
            requestData: [locationData.variantId],
          }}
          selectedCell={(data, xIndex, yIndex) => {
            if (!data) return;
            onCopyOpen({
              location: locationData,
              fromComposition: data.id,
              copyTitle: t('composition.fromCell', {
                label: formatNodeGroup(
                  articleNodeGroup.nodeXValues[xIndex],
                  articleNodeGroup.nodeYValues[yIndex],
                ),
              }),
            });
          }}
          articleNodeGroup={articleNodeGroup}
          onClose={selectCellClose}
          isOpen={isSelectCellOpen}
        />
      )}
    </Grid>
  );
}
