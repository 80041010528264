import {
  Checkbox,
  HStack,
  Spinner,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useProdutApprovalWizardState } from '../../../context';

export function SelectSuppliers({ page }: { page: string }) {
  const { t } = useTranslation();

  const {
    data: suppliers,
    refetch,
    loading,
  } = useApiResource(variantsApi.getSuppliers);

  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();

  useEffect(() => {
    if (data.selectedSuppliers.length > 0) {
      setStepIsValid(page, true);
      return;
    }

    setStepIsValid(page, false);
  }, [data.selectedSuppliers.length, setStepIsValid, page]);

  useEffect(() => {
    refetch(data.variantId);
  }, [refetch, data.variantId]);

  return (
    <>
      {loading && (
        <Flex gap={2} alignItems="center">
          <Text>{t('general.loading')}</Text>
          <Spinner size="sm" />
        </Flex>
      )}
      <Button
        mx={4}
        width="fit-content"
        variant="link"
        isLoading={loading}
        my={2}
        onClick={() =>
          setData((d) => ({
            ...d,
            selectedSuppliers:
              suppliers?.map((s) => {
                const branch = data.selectedBranches.find(
                  (b2) => b2.id === s.branchId,
                );

                return {
                  id: s.id,
                  name: s.name,
                  defaultContact: null,
                  branch: {
                    id: branch!.id,
                    identifier: branch!.identifier,
                    name: branch!.name,
                  },
                };
              }) ?? [],
          }))
        }
      >
        {t('general.selectAll')}
      </Button>
      {data.selectedBranches.map((b) => {
        const branchSuppliers = suppliers?.filter((s) => s.branchId === b.id);

        if (branchSuppliers?.length === 0) {
          return null;
        }

        return (
          <React.Fragment key={b.id}>
            <Text borderBottom="1px solid gray" px={4} py={2}>
              {b.name}
            </Text>
            {branchSuppliers?.map((x) => {
              const toggled = data.selectedSuppliers.some(
                (s) => s.id === x.id && s.branch.id === x.branchId,
              );
              return (
                <HStack
                  px={4}
                  py={2}
                  cursor="pointer"
                  backgroundColor={toggled ? 'gray.600' : 'transparent'}
                  _hover={{
                    backgroundColor: 'gray.500',
                  }}
                  transition="all 200ms ease"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!toggled) {
                      setData((d) => ({
                        ...d,
                        selectedSuppliers: [
                          ...d.selectedSuppliers,
                          {
                            id: x.id,
                            name: x.name,
                            defaultContact: null,
                            branch: {
                              id: b.id,
                              name: b.name,
                              identifier: b.identifier,
                            },
                          },
                        ],
                      }));
                      return;
                    }

                    setData((d) => ({
                      ...d,
                      selectedSuppliers: d.selectedSuppliers.filter(
                        (b2) =>
                          !(b2.id === x.id && b2.branch.id === x.branchId),
                      ),
                    }));
                  }}
                  w="100%"
                  opacity={!toggled ? 0.4 : 1}
                  key={x.id}
                >
                  <Text fontWeight="bold">{x.name}</Text>
                  <Checkbox
                    marginInlineStart="auto !important"
                    isChecked={toggled}
                  />
                </HStack>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
}
