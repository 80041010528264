const buttonTheme = {
  baseStyle: {
    fontFamily: 'swiss',
    _disabled: {
      opacity: 0.6,
    },
  },
  variants: {
    'texas-success-solid': {
      backgroundColor: 'texas.sand.100',
      _hover: {
        backgroundColor: 'texas.sand.50',
        _disabled: { backgroundColor: 'texas.sand.100' },
      },
    },
    'texas-light': {
      backgroundColor: 'texas.bg.900',
      _hover: {
        backgroundColor: 'texas.bg.700',
        color: 'white',
      },
    },
    'ghost-dashed': {
      border: '1px solid',
      borderColor: 'gray.600',
      borderStyle: 'dashed',
      borderRadius: 'md',
      color: 'gray.200',
      flexGrow: 1,
      _hover: { cursor: 'pointer', backgroundColor: 'texas.bg.800' },
    },
    'texas-link': {
      border: 'none',
      color: 'white',
      width: 'fit-content',
      padding: '0 8px 0 8px',
      _hover: { color: 'whiteAlpha.600' },
    },
  },
};

export default buttonTheme;
