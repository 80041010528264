import { Box, HStack, Progress, StackProps, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { AutoUpdateProps, Position } from './types';
import { fadeInOutAnimation } from '../../../resources/animations/animations';
import { Icons } from '../Icons';
import { AutoUpateError } from './AutoUpateError';
import {
  AutoUpdateFieldError,
  AutoUpdateFieldType,
} from '@texas/api/endpoints/autoUpdateTypes';

const displayDurationMs = 3000;

export function AutoUpdate<T extends FieldValues>(props: AutoUpdateProps<T>) {
  const indicatorTimeout = useRef<NodeJS.Timeout>();

  const preservedFailedResponse = useRef<AutoUpdateFieldError>();

  const response = props.states.find(
    (f) => f.fieldName.toLowerCase() === props.path.toString().toLowerCase(),
  );

  const [responseState, setResponseState] = useState(response);

  useEffect(() => {
    if (!response || response.iteration < (responseState?.iteration ?? -1)) {
      return;
    }

    setResponseState(response);
  }, [response, responseState?.iteration]);

  if (!response || !responseState || responseState.loading) {
    return autoUpdateComponent();
  }

  if (responseState.hasError) {
    preservedFailedResponse.current = responseState;
    return autoUpdateComponent();
  }

  // If we got here it means the last respons was successful and can therefore
  // clear the current error since it is not up-to-date
  if (preservedFailedResponse.current) {
    preservedFailedResponse.current = undefined;
  }

  clearIndicatorTimeout();
  indicatorTimeout.current = setTimeout(() => {
    setResponseState(undefined);
    clearIndicatorTimeout();
  }, displayDurationMs);

  function autoUpdateComponent() {
    return (
      <Box {...props.boxProps} position="relative" display="flex">
        {AutoUpdateInnerComponent(
          props.getValues(props.path),
          response?.loading ?? false,
          (v, forceOverwrite) => {
            props.setValue(props.path, v);
            props.onValueOptionClick(forceOverwrite);
            if (!forceOverwrite) {
              setResponseState(undefined);
              preservedFailedResponse.current = undefined;
            }
          },
          responseState,
          preservedFailedResponse.current,
          props.position,
        )}
        <Box {...props.boxProps}>
          <fieldset disabled={responseState?.loading}>
            {props.children}
          </fieldset>
        </Box>
      </Box>
    );
  }

  return autoUpdateComponent();

  function clearIndicatorTimeout() {
    if (indicatorTimeout.current) {
      clearTimeout(indicatorTimeout.current);
      indicatorTimeout.current = undefined;
    }
  }
}

function AutoUpdateInnerComponent(
  formValue: any,
  loading: boolean,
  onValueOptionClick: (value: any, force: boolean) => void,
  response?: AutoUpdateFieldType,
  preservedFailedResponse?: AutoUpdateFieldError,
  position?: Position,
) {
  if (loading) {
    return (
      <Progress
        size="xs"
        colorScheme="white"
        position="absolute"
        right="0"
        left="0"
        top="100%"
        isIndeterminate={true}
        borderRadius={12}
      />
    );
  }

  if (!response) {
    return null;
  }

  if (preservedFailedResponse) {
    return (
      <AutoUpateError
        row={preservedFailedResponse}
        formValue={formValue}
        onValueOptionClick={onValueOptionClick}
      />
    );
  }

  return (
    <HStack
      animation={`${fadeInOutAnimation(displayDurationMs)}`}
      position="absolute"
      zIndex={3}
      {...getPosition(position ?? Position.Bottom)}
      backgroundColor="gray.900"
      border="1px solid"
      borderColor="gray.800"
      css={{
        animationFillMode: 'forwards',
      }}
      padding={1}
      borderRadius={4}
    >
      <Text fontSize={12} fontWeight="bold">
        Saved
      </Text>
      <Icons.Checkmark />
    </HStack>
  );
}

function getPosition(position: Position): StackProps {
  switch (position) {
    case Position.Right:
      return { left: '100%', top: '0', bottom: '0' };
    case Position.Bottom:
    default:
      return { right: '0', top: '100%', left: '0' };
  }
}
