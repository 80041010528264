import { Text } from '@chakra-ui/react';

export function ErrorLabel({ text }: { text?: string }) {
  if (!text) return null;
  return (
    <Text fontSize={12} color="texas.danger.100" fontWeight="bold" mt={1}>
      {text}
    </Text>
  );
}
