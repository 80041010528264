import { Box, Grid, Tooltip } from '@chakra-ui/react';
import { ArticleProcess } from '@texas/api/endpoints/searchApi';
import { getEnumValues } from '@texas/utils/helpers/enumHelpers';

const articleProcesses = getEnumValues(ArticleProcess);

export function StepsIndicator({
  currentStep,
  theme = 'dark',
}: {
  currentStep: ArticleProcess;
  theme?: 'light' | 'dark';
}) {
  const intermediateSteps = articleProcesses.slice(0, -1);
  const bgColor = theme === 'dark' ? 'gray.600' : 'gray.100';
  return (
    <Tooltip label={ArticleProcess[currentStep]}>
      <Grid
        gridTemplateColumns={`repeat(${intermediateSteps.length}, 1fr)`}
        gridColumnGap="1px"
        borderRadius={4}
        height={4}
        overflow="hidden"
      >
        {intermediateSteps.map((v) => {
          return (
            <Box
              key={v}
              backgroundColor={v < currentStep ? 'texas.sand.100' : bgColor}
              border="1px solid"
              borderColor={
                v === currentStep ? 'texas.normal.100' : 'transparent'
              }
            />
          );
        })}
      </Grid>
    </Tooltip>
  );
}
