import {
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Button,
  Box,
  Grid,
  GridItem,
  Textarea,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import {
  CompositionMaterial,
  CreateCompositionMaterialRequest,
} from '@texas/api/endpoints/compositionApi';
import { materialsApi } from '@texas/api/endpoints/metadata/materialsApi';
import { qualitiesApi } from '@texas/api/endpoints/metadata/qualitiesApi';
import { techniquesApi } from '@texas/api/endpoints/metadata/techniquesApi';
import { treatmentsApi } from '@texas/api/endpoints/metadata/treatmentsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { Icons } from '@texas/components/shared/Icons';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';
import { ReactSelectOption, ReactSelectOptionWithDesc } from '@texas/types';
import { RefObject, useRef, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
  GetColorFromCode,
  PantoneColor,
  PantonePicker,
} from '../pantonePicker/PantonePicker';
import { setValueAsDecimalExtender } from '../../sales/shared';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { ColorBox } from './ColorBox';
import React from 'react';
import { PopoverOptionComponent } from './PopoverOptionComponent';

interface CompositionFormProps extends SharedDisclosureProps {
  loading: boolean;
  compositionMaterial?: CompositionMaterial | null;
  productGroupId: number;
  onFormSubmit: (data: CreateCompositionMaterialRequest) => void;
  header: string;
  drawerRef?: RefObject<HTMLElement>;
}

export function CompositionMaterialForm({
  loading,
  compositionMaterial,
  productGroupId,
  isOpen,
  onClose,
  onFormSubmit,
  header,
  drawerRef,
}: CompositionFormProps) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateCompositionMaterialRequest>();

  const { t } = useTranslation();

  const {
    onOpen: onSelectColorOpen,
    onClose: onSelectColorClose,
    isOpen: isSelectColorOpen,
  } = useDisclosure();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'qualities',
  });
  const {
    fields: colorFields,
    append: appendColor,
    remove: removeColor,
  } = useFieldArray({
    control,
    name: 'colors',
  });

  const [qualityIds, setQualityIds] = useState<number[]>([]);
  const [pantoneColors, setPantoneColors] = useState<PantoneColor[]>([]);
  const ref = useRef(null);

  useEffect(() => {
    if (!compositionMaterial) return;
    setQualityIds(compositionMaterial.qualities.map((x) => x.id));
    const colors = compositionMaterial.colors.map((x) =>
      GetColorFromCode(x.pantoneCode),
    );
    setPantoneColors(
      colors.map((x) => ({
        code: x?.code ?? '',
        hex: x?.hex ?? '',
        name: x?.name ?? '',
      })),
    );
    reset({
      materialId: compositionMaterial.material.id,
      note: compositionMaterial.note,
      techniqueIds: compositionMaterial.techniques.map((x) => x.id),
      treatmentIds: compositionMaterial.treatments.map((x) => x.id),
      weight: compositionMaterial.weight,
      colors: compositionMaterial.colors.map((x) => ({
        colorDescription: x.colorDescription,
        pantoneCode: x.pantoneCode,
        pantoneName: x.pantoneName,
      })),
      qualities: compositionMaterial.qualities.map((x) => ({
        id: x.id,
        value: x.value,
      })),
    });
  }, [compositionMaterial, reset]);

  const onSubmit = (data: CreateCompositionMaterialRequest) => {
    onClose();
    onFormSubmit(data);
  };

  const {
    qualities,
    treatments,
    techniques,
    materials,
    materialsLoading,
    techniquesLoading,
    treatmentsLoading,
    qualitiesLoading,
  } = useCompositionData(productGroupId);

  return (
    <>
      <PantonePicker
        drawerRef={drawerRef}
        onSelect={(e) => {
          const toRemove = pantoneColors
            .map<{ code: string; index: number }>((x, index) => ({
              code: x.code,
              index,
            }))
            .filter((p) => !e.some((c) => c.code === p.code));
          const toAdd = e.filter(
            (p) => !pantoneColors.some((c) => c.code === p.code),
          );

          for (let i = toRemove.length - 1; i >= 0; i--) {
            removeColor(toRemove[i].index);
          }

          for (let i = 0; i < toAdd.length; i++) {
            appendColor({
              pantoneCode: toAdd[i].code,
              pantoneName: toAdd[i].name,
              colorDescription: '',
            });
          }

          setPantoneColors(e);
          onSelectColorClose();
        }}
        onClose={onSelectColorClose}
        isOpen={isSelectColorOpen}
        addedColorCodes={pantoneColors.map((x) => x.code)}
      />
      <Modal
        portalProps={{ containerRef: drawerRef }}
        size="xl"
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent ref={ref}>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="90dvh" overflow="auto">
            <LoadingOverlayV2
              loaded={!loading}
              label={t('composition.loadingMaterial')}
            />
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Flex flexDir="column" gap={2}>
                <FormControl isInvalid={!!errors.materialId}>
                  <TexasFormLabel>{t('general.materials')}</TexasFormLabel>
                  <Controller
                    name="materialId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('composition.materialRequired'),
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        isLoading={materialsLoading}
                        styles={reactSelectStyle<false>()}
                        theme={reactSelectTheme}
                        value={{
                          label:
                            materials?.find((p) => p.id === field.value)
                              ?.name ?? '',
                          value: field.value,
                        }}
                        onChange={(e) => field.onChange(e?.value)}
                        options={
                          materials?.map<ReactSelectOption>((x) => ({
                            value: x.id,
                            label: x.name,
                          })) ?? []
                        }
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                  <ErrorLabel text={errors.materialId?.message} />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>{t('general.techniques')}</TexasFormLabel>
                  <Controller
                    name="techniqueIds"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isLoading={techniquesLoading}
                        isClearable={true}
                        styles={reactSelectStyle<
                          true,
                          ReactSelectOptionWithDesc
                        >()}
                        theme={reactSelectTheme}
                        value={field.value?.map<ReactSelectOptionWithDesc>(
                          (x) => ({
                            label:
                              techniques?.find((p) => p.id === x)?.value ?? '',
                            value: x,
                            description: techniques?.find((p) => p.id === x)
                              ?.description,
                          }),
                        )}
                        isMulti={true}
                        onChange={(e) => field.onChange(e.map((x) => x.value))}
                        options={
                          techniques?.map<ReactSelectOptionWithDesc>((x) => ({
                            value: x.id,
                            label: x.value,
                            description: x.description,
                            ref: ref,
                          })) ?? []
                        }
                        closeMenuOnSelect={false}
                        components={{ Option: PopoverOptionComponent }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>
                    {t('configuration.treatments')}
                  </TexasFormLabel>
                  <Controller
                    name="treatmentIds"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isLoading={treatmentsLoading}
                        isClearable={true}
                        styles={reactSelectStyle<
                          true,
                          ReactSelectOptionWithDesc
                        >()}
                        theme={reactSelectTheme}
                        value={field.value?.map<ReactSelectOptionWithDesc>(
                          (x) => ({
                            label:
                              treatments?.find((p) => p.id === x)?.name ?? '',
                            value: x,
                            description: treatments?.find((p) => p.id === x)
                              ?.description,
                          }),
                        )}
                        isMulti={true}
                        onChange={(e) => field.onChange(e.map((x) => x.value))}
                        options={
                          treatments?.map<ReactSelectOptionWithDesc>((x) => ({
                            value: x.id,
                            label: x.name,
                            description: x.description,
                            ref: ref,
                          })) ?? []
                        }
                        closeMenuOnSelect={false}
                        components={{ Option: PopoverOptionComponent }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl bg="texas.bg.800" p={2} borderRadius={6}>
                  <TexasFormLabel>
                    {t('configuration.qualities')}
                  </TexasFormLabel>
                  <Select
                    isLoading={qualitiesLoading}
                    isClearable={false}
                    styles={reactSelectStyle<true>()}
                    theme={reactSelectTheme}
                    isMulti={true}
                    value={qualityIds.map<ReactSelectOption>((x) => ({
                      label: qualities?.find((p) => p.id === x)?.name ?? '',
                      value: x,
                    }))}
                    onChange={(_, meta) => {
                      if (meta.action === 'select-option') {
                        setQualityIds((s) => [...s, meta.option?.value]);
                        append({ id: meta.option?.value, value: '' });
                        return;
                      }

                      setQualityIds((s) =>
                        s.filter((x) => x !== meta.removedValue?.value),
                      );
                      remove(
                        fields.findIndex(
                          (x) => x.id === meta.removedValue?.value,
                        ),
                      );
                    }}
                    options={
                      qualities?.map<ReactSelectOption>((x) => ({
                        value: x.id,
                        label: x.name,
                      })) ?? []
                    }
                    closeMenuOnSelect={false}
                  />
                  <Box pt={2}>
                    {fields.map((x, i) => {
                      const quality = qualities?.find(
                        (q) => q.id === qualityIds[i],
                      );
                      return (
                        <React.Fragment key={x.id}>
                          <Flex alignItems="center" gap={2} pt={2}>
                            <Text w="xl">{quality?.name}</Text>
                            {quality?.inputField && (
                              <FormControl
                                isRequired={true}
                                isInvalid={!!errors.qualities?.[i]?.value}
                              >
                                <Input
                                  {...register(
                                    `qualities.${i}.value` as const,
                                    {
                                      required: {
                                        value: true,
                                        message: t(
                                          'composition.qualityValueIsRequired',
                                        ),
                                      },
                                      maxLength: 50,
                                    },
                                  )}
                                  variant="outline"
                                />
                              </FormControl>
                            )}
                            <IconButton
                              ml="auto"
                              size="sm"
                              variant="ghost"
                              icon={<Icons.Close />}
                              onClick={() => {
                                setQualityIds((x) =>
                                  x.filter((q) => q !== quality?.id),
                                );
                                remove(i);
                              }}
                              aria-label={t('general.remove')}
                            />
                          </Flex>
                          <ErrorLabel
                            text={errors.qualities?.[i]?.value?.message}
                          />
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </FormControl>
                <FormControl bg="texas.bg.800" p={2} borderRadius={6}>
                  <TexasFormLabel>{t('general.colors')}</TexasFormLabel>
                  <Flex flexDir="column" gap={2}>
                    {colorFields.map((x, i) => {
                      const color = pantoneColors[i];
                      return (
                        <Grid
                          templateColumns="180px 1fr"
                          key={x.id}
                          columnGap={2}
                          alignItems="center"
                        >
                          <GridItem>
                            <ColorBox color={color} />
                          </GridItem>
                          <GridItem as={Flex} flexDir="column">
                            <Input
                              {...register(
                                `colors.${i}.colorDescription` as const,
                              )}
                              variant="outline"
                              placeholder={t('composition.colorDescription')}
                            />
                          </GridItem>
                        </Grid>
                      );
                    })}
                    <Button onClick={() => onSelectColorOpen()} w="full">
                      {t('composition.selectColors')}
                    </Button>
                  </Flex>
                </FormControl>

                <FormControl isInvalid={!!errors.weight}>
                  <TexasFormLabel>{t('composition.weight')}</TexasFormLabel>
                  <InputGroup>
                    <Input
                      variant="outline"
                      {...register('weight', {
                        setValueAs: (value) =>
                          setValueAsDecimalExtender(value, false),
                        maxLength: {
                          value: 21,
                          message: t('errors.maxLength', { count: 21 }),
                        },
                        min: {
                          value: 0,
                          message: t('composition.canNotBeNegative'),
                        },
                      })}
                      placeholder="0"
                    />
                    <InputRightAddon px={4} backgroundColor="texas.bg.900">
                      {t('composition.kg')}
                    </InputRightAddon>
                  </InputGroup>

                  <ErrorLabel text={errors.weight?.message} />
                </FormControl>
                <FormControl isInvalid={!!errors.note}>
                  <TexasFormLabel>{t('general.note')}</TexasFormLabel>
                  <AutoGrowTextareaContainer value={watch('note')}>
                    <Textarea
                      {...register('note')}
                      minHeight={10}
                      height="auto"
                    />
                  </AutoGrowTextareaContainer>
                  <ErrorLabel text={errors.note?.message} />
                </FormControl>
                <SubmitButton loading={isSubmitting}>{header}</SubmitButton>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function useCompositionData(productGroupId: number) {
  const {
    data: materials,
    refetch: refetchMaterials,
    loading: materialsLoading,
  } = useApiResource(materialsApi.getAll);

  const {
    data: qualities,
    refetch: refetchQualities,
    loading: qualitiesLoading,
  } = useApiResource(qualitiesApi.getAll);

  const {
    data: treatments,
    refetch: refetchTreatments,
    loading: treatmentsLoading,
  } = useApiResource(treatmentsApi.getAll);

  const {
    data: techniques,
    refetch: refetchTechniques,
    loading: techniquesLoading,
  } = useApiResource(techniquesApi.getAll);

  useEffect(() => {
    refetchQualities({
      sortBy: '',
      sortDesc: false,
      searchTerm: '',
      includeArchived: false,
      preciseIdFiltering: false,
      productGroupId: productGroupId,
    });
    refetchTechniques();
    refetchMaterials();
    refetchTreatments({
      sortBy: '',
      sortDesc: false,
      searchTerm: '',
      includeArchived: false,
      preciseIdFiltering: false,
      productGroupId: productGroupId,
    });
  }, [
    productGroupId,
    refetchMaterials,
    refetchQualities,
    refetchTechniques,
    refetchTreatments,
  ]);

  return {
    qualities,
    treatments,
    techniques,
    materials,
    materialsLoading,
    techniquesLoading,
    treatmentsLoading,
    qualitiesLoading,
  };
}
