import { Tooltip } from '@chakra-ui/react';
import { Icons } from '../Icons';
import { ArticleState } from '@texas/api/endpoints/searchApi';

export function StateIndicator({ state }: { state: ArticleState }) {
  if (state === ArticleState.Registered) {
    return null;
  }

  return <Tooltip label={ArticleState[state]}>{getStateIcon(state)}</Tooltip>;
}

function getStateIcon(state: ArticleState) {
  switch (state) {
    case ArticleState.Accepted:
      return <Icons.CheckmarkDecagram />;
    case ArticleState.Rejected:
      return <Icons.HandBackLeft />;
    case ArticleState.OnHold:
      return <Icons.PauseCircle />;
    case ArticleState.Cancelled:
      return <Icons.CloseCircle />;
    case ArticleState.Registered:
      return null;
  }
}
