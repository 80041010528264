import {
  Box,
  Checkbox,
  HStack,
  Spinner,
  Tooltip,
  VStack,
  Text,
} from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { HandleFinalizeErrorContainer } from './HandleFinalizeErrorContainer';
import FinalizeError from '@texas/api/dto/variant/finalizeErrorTypes';
import { Locale } from '@texas/i18n/types';
import { EMPTY_OBJECT } from '@texas/utils/helpers/commonHelpers';
import {
  formatDate,
  DateFormat,
  dateFromNow,
} from '@texas/utils/helpers/dateHelper';
import { branchHasErrors } from '@texas/utils/helpers/texas/branchHelpers';
import {
  ArticleBranch,
  ArticleBranchErpSyncChanges,
} from '@texas/api/endpoints/variantsApi';
import { Icons } from '@texas/components/shared/Icons';
import { TexasDrawerBody } from '@texas/components/shared/drawer/TexasDrawerBody';
import { TexasDrawerSubHeader } from '@texas/components/shared/drawer/TexasDrawerSubHeader';

interface ArticleBranchComponentProps {
  branch: ArticleBranch;
  validations: FinalizeError[] | null;
  loadingValidations: boolean;
  syncChangesLoading: boolean;
  syncChanges?: ArticleBranchErpSyncChanges;
  toggled: boolean;
  disabled: boolean;
  onToggle: (state: boolean) => void;
}

export function ArticleBranchComponent(props: ArticleBranchComponentProps) {
  const { t } = useTranslation();

  return (
    <Box key={props.branch.branchId}>
      <TexasDrawerSubHeader
        style={
          isDisabled(props.disabled, props.branch.blockedFromSync)
            ? {
                backgroundColor: 'transparent',
              }
            : {
                backgroundColor: props.toggled ? 'gray.700' : 'transparent',
                _hover: { backgroundColor: 'gray.500' },
              }
        }
      >
        <HStack
          {...(!isDisabled(props.disabled, props.branch.blockedFromSync)
            ? { cursor: 'pointer', _hover: { pl: '4px' } }
            : EMPTY_OBJECT)}
          transition="all 200ms ease"
          onClick={(e) => {
            if (isDisabled(props.disabled, props.branch.blockedFromSync))
              return;
            e.preventDefault();
            props.onToggle(!props.toggled);
          }}
          w="100%"
          opacity={
            isDisabled(props.disabled, props.branch.blockedFromSync) ||
            !props.toggled
              ? 0.4
              : 1
          }
        >
          {SyncIcon(props.branch, props.loadingValidations, props.validations)}
          <VStack align="start" spacing={0}>
            <Text fontWeight="bold">{props.branch.branchName}</Text>
            <HStack fontSize={12}>
              {SyncText(
                props.branch,
                props.loadingValidations,
                props.validations,
                t,
              )}
              {props.branch.lastSync ? (
                <Tooltip
                  label={`Synced ${formatDate(
                    Locale.En,
                    props.branch.lastSync,
                    DateFormat.Datetime,
                  )}`}
                >
                  <Text color="gray.200">
                    {t('variant.sync.lastSync', {
                      lastSync: dateFromNow(Locale.En, props.branch.lastSync),
                    })}
                  </Text>
                </Tooltip>
              ) : (
                <Text>{t('variant.sync.neverBeenSynced')}</Text>
              )}
              {props.syncChangesLoading && props.branch.lastSync ? (
                <HStack>
                  <Spinner size="sm" />
                  <Text>{t('variant.sync.checkingChanges')}</Text>
                </HStack>
              ) : (
                <>
                  {props.syncChanges && (
                    <Text color="texas.sand.100">
                      {t('variant.sync.modifiedSinceLastSync')}
                    </Text>
                  )}
                </>
              )}
            </HStack>
          </VStack>

          <Checkbox
            marginInlineStart="auto !important"
            disabled={isDisabled(props.disabled, props.branch.blockedFromSync)}
            isChecked={props.toggled}
          />
        </HStack>
      </TexasDrawerSubHeader>

      {branchHasErrors(props.branch.branchId, props.validations) &&
        props.toggled && (
          <TexasDrawerBody>
            <VStack align="start">
              {props.validations
                ?.filter(
                  (v) =>
                    v.branchId === props.branch.branchId &&
                    !props.branch.blockedFromSync,
                )
                .map((v) => {
                  return (
                    <Box key={v.title}>
                      {v.content && (
                        <Box
                          fontSize={12}
                          // Not dangerous since we are providing HTML hardcoded from server
                          dangerouslySetInnerHTML={{
                            __html: v.content.htmlContent,
                          }}
                        />
                      )}
                      <HandleFinalizeErrorContainer validation={v} />
                    </Box>
                  );
                })}
            </VStack>
          </TexasDrawerBody>
        )}
    </Box>
  );
}

function isDisabled(disabled: boolean, blockedFromSync: boolean) {
  return disabled || blockedFromSync;
}

function SyncText(
  branch: ArticleBranch,
  validationLoading: boolean,
  validations: FinalizeError[] | null,
  t: TFunction,
) {
  if (branch.blockedFromSync) {
    return <Text>{t('variant.sync.blockedFromSync')}</Text>;
  }

  if (validationLoading) {
    return <Text>{t('variant.sync.validationInProgress')}</Text>;
  }

  if (branchHasErrors(branch.branchId, validations)) {
    return <Text color="red.300">{t('variant.sync.errorsInBranch')}</Text>;
  }

  return <Text>{t('variant.sync.readyForSync')}</Text>;
}

function SyncIcon(
  branch: ArticleBranch,
  validationLoading: boolean,
  validations: FinalizeError[] | null,
) {
  if (branch.blockedFromSync) {
    return <Icons.Cancel />;
  }

  if (validationLoading) {
    return <Spinner size="sm" />;
  }

  if (branchHasErrors(branch.branchId, validations)) {
    return <Icons.AlertCircle color="texas.danger.100" />;
  }

  return <Icons.Checkmark />;
}
