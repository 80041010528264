import { Badge } from '@chakra-ui/react';
import {
  multipliedBy,
  exchangeRateCalculation,
} from '@texas/utils/helpers/numberHelpers';
import { roundedPurchasePriceWithFees } from '../shared';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';

interface MultipliedProps {
  price: number;
  purchasePrice: number;
  purchaseInformation: PurchaseInformation | undefined;
  salesCurrency: SalesCurrency;
  sellingExchangeRate: number;
}

export function Multiplied(props: MultipliedProps) {
  return (
    <Badge display="flex" alignItems="center" variant="solid" w="fit-content">
      x
      {multipliedBy(
        exchangeRateCalculation(
          props.price,
          props.salesCurrency.fixedExchangeRate,
          props.sellingExchangeRate,
          5,
        ),
        roundedPurchasePriceWithFees(
          props.purchasePrice,
          props.sellingExchangeRate,
          props.purchaseInformation,
        ),
      )}
    </Badge>
  );
}
