import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Icons } from '../shared/Icons';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function WidgetsLayout({
  children,
  layout,
  onLayoutChange,
  onResize,
}: {
  children: React.ReactNode;
  layout: ReactGridLayout.Layouts;
  onLayoutChange: (
    currentLayout: ReactGridLayout.Layout[],
    allLayouts: ReactGridLayout.Layouts,
  ) => void;
  onResize: (
    layout: ReactGridLayout.Layout[],
    oldItem: ReactGridLayout.Layout,
    newItem: ReactGridLayout.Layout,
  ) => void;
}) {
  return (
    <Box pb={12}>
      <ResponsiveGridLayout
        onLayoutChange={onLayoutChange}
        onResize={onResize}
        draggableHandle=".header"
        className=""
        layouts={layout}
        rowHeight={85}
        breakpoints={{ lg: 1620, md: 1320 }}
        cols={{ lg: 12, md: 10 }}
        resizeHandle={<CustomHandle />}
      >
        {children}
      </ResponsiveGridLayout>
    </Box>
  );
}

const CustomHandle = React.forwardRef<SVGSVGElement, any>(function CustomHandle(
  props,
  ref,
) {
  const { handleAxis, ...restProps } = props;
  return (
    <Icons.ResizeBottomRight
      pos="absolute"
      right={2}
      bottom={2}
      cursor="se-resize"
      className={`handle-${handleAxis}`}
      {...restProps}
      ref={ref}
    />
  );
});
