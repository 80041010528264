import buttonTheme from './components/button';
import inputTheme from './components/input';
import tabsTheme from './components/tabs';
import cardsTheme from './components/card';
import modalTheme from './components/modal';

const customTheme = {
  styles: {
    // Don't replace global styles set by the angular app
    global: () => ({
      badge: {
        backgroundColor: 'texas.bg.700',
        padding: '2px 4px',
        marginRight: '2px',
        borderRadius: '2px',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
      },
      ':root': {
        scrollbarColor: 'gray black',
        scrollbarWidth: 'thin !important',
      },
      '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: '12px',
        transition: 'background-color 200ms ease',
      },
      '::-webkit-scrollbar-track:hover': {
        backgroundColor: 'rgba(0,0,0,0.3)',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '12px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
      body: {
        bg: null,
        color: null,
      },
      '*::placeholder': {
        color: null,
      },
    }),
  },
  colors: {
    gray: {
      50: '#ededed',
      100: '#d9d9d9',
      200: '#bdbdbd',
      300: '#999999',
      400: '#787878',
      500: '#5c5c5c',
      600: '#474747',
      700: '#363636',
      800: '#2e2e2e',
      900: '#262626',
    },
    'old-texas': {
      bg: {
        100: '#f2f2f2',
      },
    },
    texas: {
      normal: {
        100: '#FFFFFF',
      },
      sand: {
        10: '#F8E2C8',
        20: '#F3DABE',
        30: '#E8CBAB',
        50: '#DEBE9A',
        100: '#C6A37C',
        40085: '#876d50D9',
      },
      green: {
        100: '#9ade9d',
      },
      danger: {
        100: '#f27272',
      },
      warning: {
        100: '#EDD657',
      },
      notification: {
        info: '#26A0F8',
      },
      bg: {
        700: '#3E3E3E',
        800: '#333333',
        900: '#242424',
        90085: '#242424D9',
        950: '#1F1F1F',
        blueTint: {
          50: '#242730',
          100: '#1B1D24',
          200: '#1c1b20',
        },
      },
    },
  },
  components: {
    Popover: {
      variants: {
        'above-angular': {
          popper: {
            zIndex: 100,
          },
        },
        responsive: {
          content: {
            width: 'unset',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'swiss',
      },
      variants: {
        header: {
          fontWeight: 'bold',
          fontSize: '1.4em',
        },
        main: {
          fontWeight: 'bold',
        },
        small: {
          fontSize: '0.9em',
          fontWeight: 'normal',
          color: 'gray.200',
        },
        sub: {
          color: 'gray.200',
        },
        'meta-field': {
          fontSize: '0.9em',
          fontWeight: 'bold',
          color: 'gray.100',
        },
      },
    },
    Heading: {
      baseStyle: {
        textTransform: 'uppercase',
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: 'swiss',
        fontSize: '0.9em',
        fontWeight: 'normal',
        color: 'gray.200',
      },
    },
    Input: inputTheme,
    Button: buttonTheme,
    Tabs: tabsTheme,
    Card: cardsTheme,
    TableContainer: {
      background: 'texas.bg.900',
    },
    Table: {
      variants: {
        stickyHeader: {
          thead: {
            backgroundColor: 'texas.bg.800',
            opacity: 0.95,
            pos: 'sticky',
            top: 0,
            zIndex: 1,
            borderBottom: 0,
          },
        },
        salesTiers: {
          thead: {
            td: {
              padding: '0 0 1em 0',
              fontWeight: 'bold',
              verticalAlign: 'baseline',
            },
          },
          tfoot: {
            td: {
              padding: '1em 0 0 0',
              fontWeight: 'bold',
              fontSize: '12px',
            },
          },
        },
        configuration: {
          tbody: {
            td: { fontSize: '14px', verticalAlign: 'baseline' },
          },
        },
      },
    },
    Modal: modalTheme,
  },
  fonts: {
    swiss: 'swiss',
  },
};

export { customTheme };
