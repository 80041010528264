import { StylesConfig } from 'react-select';
import {
  SelectFilterOption,
  ToggleFilterOption,
  GroupedOption,
} from './filterGroupedOptions';
import { CSSProperties } from 'react';

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.17em 0.5em',
  textAlign: 'center',
};

export const articlesWidgetSelectStyles: StylesConfig<
  SelectFilterOption | ToggleFilterOption,
  false,
  GroupedOption
> = {
  container: (baseStyles, _) => ({
    ...baseStyles,
  }),
  control: (baseStyles, _) => ({
    ...baseStyles,
    backgroundColor: '#414141',
    border: 'none',
    minHeight: 0,
  }),
  indicatorSeparator: (baseStyles, _) => ({
    ...baseStyles,
    display: 'none',
  }),
  valueContainer: (baseStyles, _) => ({
    ...baseStyles,
    width: 0,
    height: 0,
    visibility: 'hidden',
    padding: 0,
  }),
  indicatorsContainer: (baseStyles, _) => ({
    ...baseStyles,
    alignItems: 'flex-start',
  }),
  dropdownIndicator: (baseStyles, _) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles, { isFocused }) => ({
    ...baseStyles,
    backgroundColor: isFocused
      ? 'rgb(240, 240, 240)'
      : 'transparent !important',
    cursor: 'pointer',
    paddingTop: 2,
    paddingBottom: 2,
    color: 'black',
    fontSize: '0.8em',
  }),
  placeholder: (baseStyles, _) => ({
    ...baseStyles,
    color: 'white',
  }),
  input: (baseStyles, _) => ({
    ...baseStyles,
    color: 'white',
  }),
  menu: (baseStyles, _) => ({
    ...baseStyles,
    minWidth: '160px',
  }),
};
