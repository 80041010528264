import {
  Box,
  Checkbox,
  Grid,
  GridItem,
  HStack,
  VStack,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { evaluateProfit, roundedPurchasePriceWithFees } from './shared';
import { useDraggable } from 'react-use-draggable-scroll';
import { MatricCellWithPrice, SetCellPrices } from './SetCellPrices';
import { SalesContext } from './Sales';
import { useTranslation } from 'react-i18next';
import { fadeInAnimation } from '@texas/resources/animations/animations';
import {
  exchangeRateCalculation,
  getRoundedPurchasePriceWithFees,
} from '@texas/utils/helpers/numberHelpers';
import { UpdateHistory } from '@texas/components/shared/autoUpdate/history/UpdateHistory';
import { MarginCalculation } from './MarginCalculation';
import { articleHistoryApi } from '@texas/api/endpoints/articleHistoryApi';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import {
  SalesPrice,
  BasePrice,
  MassUpdateSalesCellsPricesResponse,
  tierPriceApi,
} from '@texas/api/endpoints/tierPriceApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from '@texas/components/shared/Icons';
import { LoadingOverlay } from '@texas/components/shared/LoadingOverlay';
import { useFloater, Floater } from '@texas/components/shared/floater/Floater';
import { IconWithBadge } from '@texas/components/shared/IconWithBadge';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import useFormat from '@texas/hooks/useFormat';
import { defaultNumberOfDecimals } from '@texas/resources/constants';
import { SalesCellPriceForm } from './forms/SalesCellPriceForm';
import { formatSingleNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';

interface MatrixViewProps {
  tierPriceId: number;
  salesPrice?: SalesPrice;
  purchasePrice?: BasePrice;
  nodeGroup: ArticleNodeGroup;
  purchaseInformation: PurchaseInformation;
  salesCurrency: SalesCurrency;
  selectedCurrency: CustomerCurrency;
  sellingExchangeRate: number;
  readyForSync: boolean;
}

export interface MatrixCell {
  matrixNodeXId: number;
  matrixNodeYId: number;
  identifier: string;
}

const cellPadding = '1em';

export function MatrixView(props: MatrixViewProps) {
  const ref = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
  const [cellRef, setCellRef] = useState<HTMLDivElement | null>(null);
  const [height, setHeight] = useState('auto');
  const salesContext = useActiveContext(SalesContext);
  const { t } = useTranslation();
  const { numberFormat } = useFormat();

  const onResponse = (response: MassUpdateSalesCellsPricesResponse) => {
    setSalesCells(response.priceCells);

    if (response.salesPrice) {
      const toSet =
        salesContext.tierPrices.data?.map((t) => {
          if (t.id === props.tierPriceId) {
            if (!t.salesPrices.find((s) => s.id === response.salesPrice!.id)) {
              t.salesPrices.push(response.salesPrice!);
              return t;
            }

            t.salesPrices = t.salesPrices.map((s) => {
              if (s.id === response.salesPrice!.id) {
                return response.salesPrice!;
              }

              return s;
            });
          }

          return t;
        }) ?? [];

      salesContext.tierPrices.set(toSet);
    }
  };

  const [selectedCells, setSelectedCells] = useState<MatrixCell[]>([]);

  const {
    data: salesCells,
    refetch: refetchSalesCells,
    set: setSalesCells,
    loading: cellsLoading,
  } = useApiResource(tierPriceApi.getSalesCellPrices);
  const { data: purchaseCells, refetch: refetchPurchaseCells } = useApiResource(
    tierPriceApi.getPurchaseCellPrices,
  );

  const floater = useFloater(ModalState.Closed);
  const floaterSetModalState = floater.setModalState;

  useEffect(() => {
    if (!cellRef) return;
    const bounds = cellRef.getBoundingClientRect();
    setHeight(`${bounds.height}px`);
  }, [cellRef]);

  useEffect(() => {
    refetchSalesCells(props.tierPriceId, props.salesCurrency.id);
    refetchPurchaseCells(props.tierPriceId);
  }, [
    refetchSalesCells,
    props.tierPriceId,
    refetchPurchaseCells,
    props.salesCurrency.id,
  ]);

  function addToCellSelection(nodes: MatrixCell[]) {
    const toAdd = nodes.filter(
      (n) =>
        selectedCells.find(
          (c) =>
            c.matrixNodeXId === n.matrixNodeXId &&
            c.matrixNodeYId === n.matrixNodeYId,
        ) === undefined,
    );

    setSelectedCells((s) => [...s, ...toAdd]);
  }
  function removeFromCellSelection(nodes: MatrixCell[]) {
    const toSet = selectedCells.filter(
      (n) =>
        nodes.find(
          (c) =>
            c.matrixNodeXId === n.matrixNodeXId &&
            c.matrixNodeYId === n.matrixNodeYId,
        ) === undefined,
    );

    setSelectedCells(toSet);
  }

  useEffect(() => {
    if (selectedCells.length === 0) floaterSetModalState(ModalState.Closed);
    else floaterSetModalState(ModalState.Open);
  }, [floaterSetModalState, selectedCells]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box animation={fadeInAnimation()} pos="relative">
      <fieldset disabled={cellsLoading}>
        {cellsLoading && <LoadingOverlay />}
        <SetCellPrices
          onUpdate={onResponse}
          isOpen={isOpen}
          onClose={onClose}
          tierPriceId={props.tierPriceId}
          purchaseInfo={props.purchaseInformation}
          salesCurrency={props.salesCurrency}
          sellingExchangeRate={props.sellingExchangeRate}
          cells={selectedCells.map<MatricCellWithPrice>((s) => {
            const salesCell = salesCells?.find(
              (c) =>
                c.matrixNodeValueXId === s.matrixNodeXId &&
                c.matrixNodeValueYId === s.matrixNodeYId,
            );
            const purchaseCell = purchaseCells?.find(
              (p) =>
                p.matrixNodeValueXId === s.matrixNodeXId &&
                p.matrixNodeValueYId === s.matrixNodeYId &&
                p.supplierId === props.purchaseInformation.supplierId,
            );
            return {
              identifier: s.identifier,
              matrixNodeXId: s.matrixNodeXId,
              matrixNodeYId: s.matrixNodeYId,
              purchasePriceWithFees: getRoundedPurchasePriceWithFees(
                purchaseCell?.price ?? props.purchasePrice?.price ?? 0,
                props.purchaseInformation.freightCostValue,
                props.purchaseInformation.handlingFeeValue,
                5,
              ),
              currentPrice: salesCell?.price ?? props.salesPrice?.price ?? 0,
            };
          })}
        />
        <Grid templateColumns="fit-content(200px) 1fr">
          <GridItem maxHeight="512px" overflow="hidden" ref={rowRef}>
            <Grid templateColumns="repeat(1, 1fr)">
              <GridItem
                position="sticky"
                top={0}
                zIndex={2}
                backgroundColor="texas.bg.blueTint.100"
                minHeight={height}
                maxHeight={height}
                padding={cellPadding}
                borderRight="1px solid"
                borderBottom="1px solid"
                borderColor="gray.500"
              >
                <Checkbox
                  isChecked={
                    selectedCells.length ===
                    props.nodeGroup.nodeXValues.length *
                      props.nodeGroup.nodeYValues.length
                  }
                  isIndeterminate={
                    selectedCells.length > 0 &&
                    selectedCells.length !==
                      props.nodeGroup.nodeXValues.length *
                        props.nodeGroup.nodeYValues.length
                  }
                  onChange={(e) => {
                    const cells: MatrixCell[] = [];
                    for (
                      let x = 0;
                      x < props.nodeGroup.nodeXValues.length;
                      x++
                    ) {
                      for (
                        let y = 0;
                        y < props.nodeGroup.nodeYValues.length;
                        y++
                      ) {
                        cells.push({
                          identifier: `${props.nodeGroup.nodeYValues[y].matrixNodeValue.identifier}${props.nodeGroup.nodeXValues[x].matrixNodeValue.identifier}`,
                          matrixNodeXId:
                            props.nodeGroup.nodeXValues[x].matrixNodeValue.id,
                          matrixNodeYId:
                            props.nodeGroup.nodeYValues[y].matrixNodeValue.id,
                        });
                      }
                    }

                    if (e.target.checked) addToCellSelection(cells);
                    else removeFromCellSelection(cells);
                  }}
                />
              </GridItem>
              {props.nodeGroup.nodeYValues.map((y, yIndex) => {
                return (
                  <GridItem
                    minHeight={height}
                    maxHeight={height}
                    padding={cellPadding}
                    key={yIndex}
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderRightColor="gray.500"
                    borderBottomColor="texas.bg.blueTint.50"
                  >
                    <VStack alignItems="start">
                      <Text>{formatSingleNodeGroup(y)}</Text>
                      <Checkbox
                        isChecked={
                          selectedCells.filter(
                            (s) => s.matrixNodeYId === y.matrixNodeValue.id,
                          ).length === props.nodeGroup.nodeXValues.length
                        }
                        isIndeterminate={
                          selectedCells.some(
                            (s) => s.matrixNodeYId === y.matrixNodeValue.id,
                          ) &&
                          selectedCells.filter(
                            (s) => s.matrixNodeYId === y.matrixNodeValue.id,
                          ).length !== props.nodeGroup.nodeXValues.length
                        }
                        onChange={(e) => {
                          const cells =
                            props.nodeGroup.nodeXValues.map<MatrixCell>(
                              (x) => ({
                                identifier: `${y.matrixNodeValue.identifier}${x.matrixNodeValue.identifier}`,
                                matrixNodeXId: x.matrixNodeValue.id,
                                matrixNodeYId: y.matrixNodeValue.id,
                              }),
                            );

                          if (e.target.checked) addToCellSelection(cells);
                          else removeFromCellSelection(cells);
                        }}
                      />
                    </VStack>
                  </GridItem>
                );
              })}
            </Grid>
          </GridItem>
          <GridItem
            overflow="auto"
            maxHeight="512px"
            ref={ref}
            {...events}
            onScroll={(e) => {
              if (!rowRef.current) return;
              rowRef.current.scrollTop = e.currentTarget.scrollTop;
            }}
          >
            <Grid
              gridAutoColumns="1fr"
              gridAutoFlow="column"
              templateRows={`repeat(${
                props.nodeGroup.nodeYValues.length + 1
              }, 1fr)`}
            >
              {props.nodeGroup.nodeXValues.map((x, xIndex) => {
                return (
                  <Fragment key={xIndex}>
                    <GridItem
                      padding={cellPadding}
                      fontWeight="bold"
                      borderBottom="1px solid"
                      borderBottomColor="gray.500"
                      borderRight="1px solid"
                      borderRightColor="texas.bg.blueTint.50"
                      pos="sticky"
                      top="0"
                      zIndex={2}
                      backgroundColor="texas.bg.blueTint.100"
                    >
                      <VStack>
                        <Text>{formatSingleNodeGroup(x)}</Text>
                        <Checkbox
                          isChecked={
                            selectedCells.filter(
                              (s) => s.matrixNodeXId === x.matrixNodeValue.id,
                            ).length === props.nodeGroup.nodeYValues.length
                          }
                          isIndeterminate={
                            selectedCells.some(
                              (s) => s.matrixNodeXId === x.matrixNodeValue.id,
                            ) &&
                            selectedCells.filter(
                              (s) => s.matrixNodeXId === x.matrixNodeValue.id,
                            ).length !== props.nodeGroup.nodeYValues.length
                          }
                          onChange={(e) => {
                            const cells =
                              props.nodeGroup.nodeYValues.map<MatrixCell>(
                                (y) => ({
                                  identifier: `${y.matrixNodeValue.identifier}${x.matrixNodeValue.identifier}`,
                                  matrixNodeXId: x.matrixNodeValue.id,
                                  matrixNodeYId: y.matrixNodeValue.id,
                                }),
                              );

                            if (e.target.checked) addToCellSelection(cells);
                            else removeFromCellSelection(cells);
                          }}
                        />
                      </VStack>
                    </GridItem>
                    {props.nodeGroup.nodeYValues.map((y, yIndex) => {
                      const salesCell = salesCells?.find(
                        (c) =>
                          c.matrixNodeValueXId === x.matrixNodeValue.id &&
                          c.matrixNodeValueYId === y.matrixNodeValue.id,
                      );
                      const purchaseCell = purchaseCells?.find(
                        (p) =>
                          p.matrixNodeValueXId === x.matrixNodeValue.id &&
                          p.matrixNodeValueYId === y.matrixNodeValue.id &&
                          p.supplierId === props.purchaseInformation.supplierId,
                      );
                      const isChecked = selectedCells.some(
                        (s) =>
                          s.matrixNodeXId === x.matrixNodeValue.id &&
                          s.matrixNodeYId === y.matrixNodeValue.id,
                      );
                      return (
                        <GridItem
                          {...(yIndex === 0
                            ? { ref: (r) => setCellRef(r) }
                            : null)}
                          padding={cellPadding}
                          key={yIndex}
                          borderRight="1px solid"
                          borderBottom="1px solid"
                          borderColor="texas.bg.blueTint.50"
                          backgroundColor={
                            isChecked ? 'texas.bg.700' : 'transparent'
                          }
                          role="group"
                        >
                          <VStack alignItems="start">
                            <SalesCellPriceForm
                              salesPrice={props.salesPrice}
                              purchasePrice={
                                purchaseCell?.price ??
                                props.purchasePrice?.price ??
                                0
                              }
                              tierPriceId={props.tierPriceId}
                              salesCell={salesCell}
                              cellX={x.matrixNodeValue.id}
                              cellY={y.matrixNodeValue.id}
                              salesCurrency={props.salesCurrency}
                              purchaseInformation={props.purchaseInformation}
                              sellingExchangeRate={props.sellingExchangeRate}
                              isDisabled={props.readyForSync}
                              onUpdate={(cell) => {
                                if (salesCells?.find((s) => s.id === cell.id)) {
                                  const toSet = salesCells.map((s) => {
                                    if (s.id === cell.id) {
                                      return cell;
                                    }
                                    return s;
                                  });

                                  setSalesCells(toSet);
                                  return;
                                }

                                setSalesCells((s) => [...(s ?? []), cell]);
                              }}
                            />
                            <HStack>
                              <Checkbox
                                isChecked={isChecked}
                                onChange={(e) =>
                                  e.target.checked
                                    ? addToCellSelection([
                                        {
                                          identifier: `${y.matrixNodeValue.identifier}${x.matrixNodeValue.identifier}`,
                                          matrixNodeXId: x.matrixNodeValue.id,
                                          matrixNodeYId: y.matrixNodeValue.id,
                                        },
                                      ])
                                    : removeFromCellSelection([
                                        {
                                          identifier: `${y.matrixNodeValue.identifier}${x.matrixNodeValue.identifier}`,
                                          matrixNodeXId: x.matrixNodeValue.id,
                                          matrixNodeYId: y.matrixNodeValue.id,
                                        },
                                      ])
                                }
                              />
                              <Text variant="sub">
                                {`${numberFormat(
                                  roundedPurchasePriceWithFees(
                                    purchaseCell?.price ??
                                      props.purchasePrice?.price ??
                                      0,
                                    props.sellingExchangeRate,
                                    props.purchaseInformation,
                                  ),
                                )} ${props.selectedCurrency.code}`}
                              </Text>
                              <UpdateHistory
                                header={t('history.salesPriceHistory')}
                                historyRequestWrapper={() =>
                                  articleHistoryApi.salesCellPrice(
                                    salesContext.articleId,
                                    props.tierPriceId,
                                    props.salesCurrency.id,
                                    x.matrixNodeValue.id,
                                    y.matrixNodeValue.id,
                                  )
                                }
                              />
                              <MarginCalculation
                                purchasePrice={getRoundedPurchasePriceWithFees(
                                  props.purchasePrice?.price ?? 0,
                                  props.purchaseInformation.freightCostValue,
                                  props.purchaseInformation.handlingFeeValue,
                                  5,
                                )}
                                purchaseExchangeRate={
                                  props.purchaseInformation.fixedExchangeRate
                                }
                                salesPrice={
                                  salesCell?.price ??
                                  props.salesPrice?.price ??
                                  0
                                }
                                salesExchangeRate={
                                  props.salesCurrency.fixedExchangeRate
                                }
                                targetExchangeRate={props.sellingExchangeRate}
                                targetCurrencyCode={props.selectedCurrency.code}
                                profit={evaluateProfit(
                                  exchangeRateCalculation(
                                    salesCell?.price ??
                                      props.salesPrice?.price ??
                                      0,
                                    props.salesCurrency.fixedExchangeRate,
                                    props.sellingExchangeRate,
                                    defaultNumberOfDecimals,
                                  ),
                                  roundedPurchasePriceWithFees(
                                    purchaseCell?.price ??
                                      props.purchasePrice?.price ??
                                      0,
                                    props.sellingExchangeRate,
                                    props.purchaseInformation,
                                  ),
                                )}
                              />
                            </HStack>
                          </VStack>
                        </GridItem>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Grid>
          </GridItem>
        </Grid>
        <Floater ref={floater.ref} modal={floater}>
          <VStack>
            <Button variant="ghost" color="black" size="xs" onClick={onOpen}>
              <IconWithBadge
                icon={<Icons.Pencil boxSize={5} />}
                tooltipLabel={t('salesMatrix.setCellPrices')}
              />
            </Button>
          </VStack>
        </Floater>
      </fieldset>
    </Box>
  );
}
