// @ngInject
const ResourcesService = ($q, DSHttpAdapter, DS, toasterService) => {
  const formatResourceError = (httpError) => {
    const error = {
      status: httpError.status,
      message: '',
      errors: [],
    };

    // Add child errors
    if (httpError.data && !_.isEmpty(httpError.data.errors)) {
      error.errors = httpError.data.errors;
    }

    // Add correct error message
    if (httpError.data && !_.isEmpty(httpError.data.message)) {
      error.message = httpError.data.message;
    } else {
      switch (httpError.status) {
        case 0:
          error.message = 'No connection to server';
          break;
        case 401:
          error.message = 'Unauthorized';
          break;
        case 404:
          error.message = 'This entity has been deleted';
          break;
        default:
          error.message = 'An unexpected error occurred';
          break;
      }
    }

    if (!error.errors || !error.errors.length) {
      error.errors = [{ message: error.message }];
    }

    return error;
  };

  const handleError = (error) => {
    const formattedError = formatResourceError(error);
    toasterService.error(formattedError.message);
    return formattedError;
  };

  const getResource = (relationDef, id) => {
    if (id) {
      return DS.find(relationDef.relation, id);
    }

    return;
  };

  const loadRelation = (relationDef, entity) => {
    const promises = [];
    if (relationDef.type === 'hasMany') {
      _.forEach(entity[relationDef.localKeys], (relation) => {
        promises.push(
          getResource(
            relationDef,
            relation[relationDef.localIdAttribute || 'id'],
          ),
        );
      });
    } else if (
      relationDef.type === 'hasOne' ||
      relationDef.type === 'belongsTo'
    ) {
      promises.push(getResource(relationDef, entity[relationDef.localKey]));
    }
    return promises;
  };

  const loadRelations = (resourceName, entity, relations) => {
    const deferred = $q.defer();
    let promises = [];

    _.forEach(relations, (relation) => {
      if (relation.indexOf('.') > 0) {
        const parentRelation = relation.split('.')[0];
        const nestedRelation = relation.split('.')[1];
        const parentRelationDef = _.find(
          DS.definitions[resourceName].relationList,
          { localField: parentRelation },
        );

        _.forEach(entity[parentRelation], (nestedEntity) => {
          const nestedRelationDef = _.find(
            DS.definitions[parentRelationDef.relation].relationList,
            { localField: nestedRelation },
          );
          promises = promises.concat(
            loadRelation(nestedRelationDef, nestedEntity),
          );
        });
      } else {
        const relationDef = _.find(DS.definitions[resourceName].relationList, {
          localField: relation,
        });
        promises = promises.concat(loadRelation(relationDef, entity));
      }
    });

    $q.all(promises).then(
      (response) => deferred.resolve(response),
      (error) => deferred.reject(handleError(error)),
    );

    return deferred.promise;
  };

  const getById = (resourceName, id, options = {}) => {
    const deferred = $q.defer();

    options = Object.assign(
      {},
      {
        bypassCache: true,
        includeRelations: false,
      },
      options,
    );
    DS.find(resourceName, id, options).then(
      (entity) => {
        if (options.includeRelations) {
          loadRelations(resourceName, entity, options.includeRelations).then(
            () => deferred.resolve(entity),
            (error) => deferred.reject(handleError(error)),
          );
        } else {
          deferred.resolve(entity);
        }
      },
      (error) => deferred.reject(handleError(error)),
    );

    return deferred.promise;
  };

  return {
    getById,
    getAll(resourceName, options = {}) {
      const deferred = $q.defer();

      DS.findAll(
        resourceName,
        { cache: +new Date() },
        Object.assign(
          {},
          {
            bypassCache: true,
            useFilter: true,
          },
          options,
        ),
      ).then(
        (response) => deferred.resolve(response),
        (error) => deferred.reject(handleError(error)),
      );

      return deferred.promise;
    },
    create(resourceName, data, options = {}) {
      const deferred = $q.defer();

      DS.create(resourceName, data, options).then(
        (response) => deferred.resolve(response),
        (error) => deferred.reject(handleError(error)),
      );

      return deferred.promise;
    },
    update(resourceName, id, data, options = {}) {
      const deferred = $q.defer();

      options = Object.assign(
        {},
        {
          cacheResponse: false,
          updatedFields: [],
        },
        options,
      );
      DS.update(resourceName, id, data, options).then(
        (response) => {
          const originalObject = DS.get(resourceName, id);
          _.forEach(options.updatedFields, (updatedField) => {
            originalObject[updatedField] = response[updatedField];
          });
          DS.inject(resourceName, {
            [DS.definitions[resourceName].idAttribute]: id,
          });
          deferred.resolve(originalObject);
        },
        (error) => deferred.reject(handleError(error)),
      );

      return deferred.promise;
    },
    destroy(resourceName, id, options = {}) {
      const deferred = $q.defer();

      DS.destroy(resourceName, id, options).then(
        (response) => deferred.resolve(response),
        (error) => deferred.reject(handleError(error)),
      );

      return deferred.promise;
    },
    loadRelations,
    handleError,
    formatResourceError,
    getRequestHeaders: () => DSHttpAdapter.defaults.httpConfig.headers,
    restore(resourceName, id) {
      const deferred = $q.defer();

      const options = { cacheResponse: false };
      const parentKey = DS.definitions[resourceName].parentKey;
      if (parentKey) {
        options.params = { [parentKey]: false };
      }

      DS.update(resourceName, id, { archived: false }, options).then(
        () => {
          if (resourceName === 'file') {
            return deferred.resolve();
          }
          getById(
            resourceName,
            id,
            options.params ? { params: options.params } : undefined,
          ).then(
            (resource) => {
              if (resourceName === 'matrixNodeValue') {
                getById('matrixNode', resource.matrixNodeId, {
                  params: { organizationId: false },
                }).then(
                  () => deferred.resolve(),
                  (error) => deferred.reject(handleError(error)),
                );
              } else {
                deferred.resolve();
              }
            },
            (error) => deferred.reject(handleError(error)),
          );
        },
        (error) => deferred.reject(handleError(error)),
      );

      return deferred.promise;
    },
    getArchived(resourceName, organizationId) {
      const deferred = $q.defer();
      const url =
        `${DS.defaults.basePath}organizations/${organizationId}` +
        `/archive/${DS.definitions[resourceName].endpoint}`;

      DSHttpAdapter.GET(url).then(
        (response) => deferred.resolve(response.data),
        (error) => deferred.reject(handleError(error)),
      );

      return deferred.promise;
    },
  };
};

angular
  .module('resources', [
    'resources.auth',
    'resources.articles',
    'resources.customers',
    'resources.organizations',
    'resources.concepts',
    'resources.users',
    'resources.files',
    'resources.notifications',
    'resources.suppliers',
    'resources.branches',
    'resources.tasks',
    'resources.countries',
    'resources.currencies',
    'resources.garp',
    'resources.vatCodes',
    'resources.matrixGroups',
    'resources.matrixNodes',
    'resources.articleTemplates',
    'resources.technicalOptions',
    'resources.deliveryMethods',
    'resources.deliveryTerms',
    'resources.paymentTerms',
    'resources.freightCosts',
    'resources.handlingFees',
    'resources.materials',
    'resources.productGroups',
    'resources.techniques',
    'resources.articleTags',
    'resources.excel',
  ])
  .factory('ResourcesService', ResourcesService);
