import { Box, Flex, Img, Input, Textarea } from '@chakra-ui/react';
import { Character, GetCharacterOption, GetCharacterOptions } from './types';
import { useTranslation } from 'react-i18next';
import {
  AutoUpdateWrapper,
  useAutoUpdateForm,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import {
  AutoUpdateInfoRequest,
  VariantInfo,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { hasClaims } from '@texas/utils/helpers/claimHelpers';
import { useActiveSession } from '@texas/hooks/useSession';
import { ClaimType } from '@texas/api/endpoints/userApi';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { reactEvents } from 'src/bridge/reactEvents';
import { AutoUpdateResponseCallback } from '@texas/components/shared/autoUpdate/types';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { LoadingOverlay } from '@texas/components/shared/LoadingOverlay';
import { Controller } from 'react-hook-form';
import { reactSelectStyle, reactSelectTheme } from '@texas/resources/styles';
import Select from 'react-select';
import * as characterImages from '@assets/characters/characterImages';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

const characters = GetCharacterOptions();

export function General({ id }: { id: number }) {
  const {
    data: variantInfo,
    refetch,
    loading,
    error,
  } = useApiResource(variantsApi.getVariantInfo);

  useEffect(() => {
    refetch(id);
  }, [refetch, id]);

  return (
    <Box pos="relative">
      {loading && <LoadingOverlay />}
      {variantInfo && <InfoForm variantInfo={variantInfo} />}
      {!variantInfo && <ErrorLabel text={error?.message} />}
    </Box>
  );
}

export function InfoForm({ variantInfo }: { variantInfo: VariantInfo }) {
  const { t } = useTranslation();
  const session = useActiveSession();
  const disabled = !hasClaims(
    session.currentUser.claims.map((c) => c.id),
    [
      ClaimType.CompanyAdministrator,
      ClaimType.OrganizationAdministrator,
      ClaimType.SystemAdministrator,
    ],
  );

  function onResponse(
    data: AutoUpdateResponseCallback<VariantInfo, AutoUpdateInfoRequest>,
  ) {
    reactEvents.variantNameChanged.dispatch({
      id: variantInfo.id,
      name: data.mappedServerData.name,
    });
  }

  const {
    registerAutoUpdate,
    form,
    formState,
    control,
    watch,
    triggerSubmit,
    register,
  } = useAutoUpdateForm<AutoUpdateInfoRequest, VariantInfo>({
    defaultValue: {
      name: variantInfo.name,
      character: variantInfo.character,
      notes: variantInfo.notes,
    },
    valueId: variantInfo.id,
    namePrefix: 'variantInfo',
    autoUpdateRequest: variantsApi.autoUpdateInfo,
    onResponse,
  });

  return (
    <form {...form}>
      <Flex
        direction="column"
        width="100%"
        backgroundColor="white"
        px={3}
        py={6}
        gap={4}
      >
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="name"
          boxProps={{ flexGrow: '1' }}
        >
          <TexasFormLabel>{t('general.name')}</TexasFormLabel>
          <Input
            {...register('name', {
              required: true,
              minLength: {
                value: 1,
                message: t('errors.minLength', { count: 1 }),
              },
              maxLength: {
                value: 100,
                message: t('errors.maxLength', { count: 100 }),
              },
              onBlur: triggerSubmit,
            })}
            disabled={disabled}
            borderBottom="1px"
            borderBottomColor="gray"
            borderRadius={0}
            p={1}
            fontSize="md"
            textColor="gray.600"
            _hover={{ borderColor: 'hsl(0, 0%, 70%)' }}
          />
          <ErrorLabel text={formState.errors.name?.message} />
        </AutoUpdateWrapper>
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="character"
          boxProps={{ flexGrow: '1' }}
        >
          <TexasFormLabel mb={0}>{t('variant.character')}</TexasFormLabel>
          <Controller
            name="character"
            control={control}
            render={({ field }) => (
              <>
                <Flex align="center">
                  <Select
                    {...field}
                    styles={reactSelectStyle<false>({ isAngularTheme: true })}
                    theme={reactSelectTheme}
                    value={GetCharacterOption(field.value)}
                    onChange={(e) => {
                      field.onChange(convertToEnum(Character, e?.value));
                      triggerSubmit();
                    }}
                    options={characters}
                    maxMenuHeight={160}
                    closeMenuOnSelect={true}
                  />
                  <Box data-group={true} position="relative">
                    <Box
                      position="absolute"
                      width="100%"
                      zIndex="1"
                      height="100%"
                    />
                    <Img
                      position="relative"
                      zIndex={1}
                      src={convertToEnum(characterImages, field.value)}
                      maxH={20}
                      borderRadius={2}
                      aria-label="Character image"
                      _groupHover={{
                        transform: 'scale(4) translateX(-35%)',
                      }}
                      css={{ transition: 'transform 200ms ease' }}
                    />
                  </Box>
                </Flex>
              </>
            )}
          />
        </AutoUpdateWrapper>
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="notes"
          boxProps={{ flexGrow: '1' }}
        >
          <TexasFormLabel>{t('general.note')}</TexasFormLabel>
          <AutoGrowTextareaContainer value={watch('notes')}>
            <Textarea
              {...register('notes', {
                maxLength: {
                  value: 4000,
                  message: t('errors.maxLength', { count: 4000 }),
                },
                onBlur: triggerSubmit,
              })}
              disabled={disabled}
              minHeight={10}
              height="auto"
              padding={1}
              width="100%"
              borderRadius={3}
              borderColor="gray"
              textColor="gray.600"
              _hover={{ borderColor: 'hsl(0, 0%, 70%)' }}
            />
          </AutoGrowTextareaContainer>
          <ErrorLabel text={formState.errors.notes?.message} />
        </AutoUpdateWrapper>
      </Flex>
    </form>
  );
}
