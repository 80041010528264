import { Box, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SaveAndSyncContent } from './SaveAndSyncContent';
import {
  useTexasDrawer,
  TexasDrawer,
} from '@texas/components/shared/drawer/TexasDrawer';
import { TexasDrawerCloseButton } from '@texas/components/shared/drawer/TexasDrawerCloseButton';
import { TexasDrawerHeader } from '@texas/components/shared/drawer/TexasDrawerHeader';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { TexasDrawerContent } from '@texas/components/shared/drawer/TexasDrawerContent';

interface SaveAndSyncProps {
  variantId: number;
  articleId: number;
  syncedVariantId?: number;
  isMain: boolean;
}

export function SaveAndSync(props: SaveAndSyncProps) {
  const drawer = useTexasDrawer(ModalState.Closed, true);

  const { t } = useTranslation();

  if (!props.isMain)
    return (
      <Text
        color="gray.300"
        fontFamily="fonts.body"
        fontStyle="italic"
        fontSize={14}
      >
        {t('variant.notMain')}
      </Text>
    );

  return (
    <Box>
      <TexasDrawer modal={drawer} ref={drawer.ref}>
        <TexasDrawerCloseButton setModalState={drawer.setModalState} />
        <TexasDrawerContent>
          <TexasDrawerHeader title="Save and sync" />

          <SaveAndSyncContent
            drawerState={drawer.modalState}
            configuration={{
              variantId: props.variantId,
              allBranches: false,
              articleId: props.articleId,
              syncedVariantId: props.syncedVariantId,
            }}
            onSyncSuccess={() => drawer.setModalState(ModalState.Closed)}
          />
        </TexasDrawerContent>
      </TexasDrawer>
      <Button
        ml={2}
        variant="texas-light"
        onClick={() => {
          drawer.setModalState(ModalState.Open);
        }}
      >
        {t('variant.sync.saveAndSync')}
      </Button>
    </Box>
  );
}
