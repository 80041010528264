import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ProductApproval } from '@texas/api/endpoints/productApprovalsApi';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { Box, Flex } from '@chakra-ui/react';

export const productApprovalColumns = (t: TFunction) => {
  const columnHelper = createColumnHelper<ProductApproval>();
  const columns = [
    columnHelper.accessor('formatNumber', {
      cell: (info) => info.getValue(),
      header: t('productApproval.number'),
      enableSorting: true,
    }),
    columnHelper.accessor('branchName', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      enableSorting: true,
    }),
    columnHelper.accessor('supplierName', {
      cell: (info) => info.getValue(),
      header: t('suppliers.supplier'),
      enableSorting: true,
    }),
    columnHelper.accessor('variantName', {
      cell: (info) => (
        <Flex direction="column">
          <Box>{info.getValue()}</Box>
          {info.row.original.matrixNodeXId && info.row.original.matrixNodeYId && (
            <>
              <Box color="gray.200">{info.row.original.matrixNodeXValue}</Box>
              <Box color="gray.200">{info.row.original.matrixNodeYValue}</Box>
            </>
          )}
        </Flex>
      ),
      header: t('general.option'),
      enableSorting: true,
    }),
    columnHelper.accessor('state', {
      cell: (info) => info.getValue(),
      header: t('general.state'),
      enableSorting: true,
    }),
    columnHelper.accessor('created', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.created'),
      enableSorting: true,
    }),
  ];

  return columns;
};
