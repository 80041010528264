import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { TreatmentListItem } from '@texas/api/endpoints/metadata/treatmentsApi';
import { Icons } from '@texas/components/shared/Icons';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';

export const treatmentTableColumns = (
  t: TFunction,
  onArchiveOpen: (treatment: TreatmentListItem) => void,
  onRestore: (id: number) => Promise<void>,
  onEditOpen: (treatmentId: number) => void,
  selectedProductGroupId: number,
) => {
  const columnHelper = createColumnHelper<TreatmentListItem>();
  const columns = [
    columnHelper.accessor('code', {
      cell: (info) => info.getValue(),
      header: t('general.code'),
      maxSize: 100,
    }),
    columnHelper.accessor('name', {
      cell: (info) => (
        <Flex direction="column">
          <Box>{info.getValue()}</Box>
          <Box color="gray.200" whiteSpace="normal">
            {info.row.original.description}
          </Box>
        </Flex>
      ),
      header: t('general.name'),
      maxSize: 200,
    }),
    columnHelper.accessor('productGroups', {
      cell: (info) => (
        <Flex gap={2} flexWrap="wrap">
          {info.getValue().map((v) => (
            <Badge
              key={v.id}
              color={
                v.id === selectedProductGroupId ? 'orange' : 'texas.sand.50'
              }
            >
              {v.value}
            </Badge>
          ))}
        </Flex>
      ),
      header: t('general.productGroups'),
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip archived={info.row.original.archived} />
            )}
            <Menu placement="left">
              <MenuButton float="right">
                <Box>
                  <IconButton
                    variant="ghost"
                    icon={<Icons.DotsHorizontal boxSize={4} />}
                    aria-label={t('general.more')}
                  />
                </Box>
              </MenuButton>
              <MenuList color="white">
                <MenuItem
                  icon={<Icons.Pencil boxSize={5} />}
                  onClick={() => onEditOpen(info.row.original.id)}
                >
                  {t('general.edit')}
                </MenuItem>
                {info.row.original.archived ? (
                  <MenuItem
                    color="texas.sand.100"
                    icon={<Icons.ArchiveRemove boxSize={5} />}
                    onClick={() => onRestore(info.row.original.id)}
                  >
                    {t('general.restore')}
                  </MenuItem>
                ) : (
                  <MenuItem
                    color="texas.sand.50"
                    icon={<Icons.Archive boxSize={5} />}
                    onClick={() => onArchiveOpen(info.row.original)}
                  >
                    {t('general.archive')}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
