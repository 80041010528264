import {
  Menu,
  MenuButton,
  Portal,
  MenuList,
  MenuItem,
  Box,
  Flex,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { UserProfile } from '@texas/api/endpoints/userProfilesApi';
import { IconWithBadge } from '@texas/components/shared/IconWithBadge';
import { Icons } from '@texas/components/shared/Icons';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';
import { TFunction } from 'i18next';

export const TableColumns = (
  t: TFunction,
  onArchiveOpen: (profile: UserProfile) => void,
  onRestore: (profileId: number) => Promise<void>,
  onEditOpen: (profile: UserProfile) => void,
) => {
  const columnHelper = createColumnHelper<UserProfile>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Name',
      enableSorting: true,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip
                archived={info.row.original.archived.toString()}
              />
            )}
            <Menu placement="left">
              <MenuButton float="right">
                <Box>
                  <IconWithBadge
                    tooltipLabel="More"
                    icon={<Icons.DotsHorizontal />}
                  />
                </Box>
              </MenuButton>
              <Portal>
                <MenuList color="white" zIndex={99}>
                  <MenuItem
                    icon={<Icons.Pencil boxSize={5} />}
                    onClick={() => onEditOpen(info.row.original)}
                  >
                    {t('general.edit')}
                  </MenuItem>
                  {info.row.original.archived ? (
                    <MenuItem
                      color="texas.sand.100"
                      icon={<Icons.ArchiveRemove boxSize={5} />}
                      onClick={() => onRestore(info.row.original.id)}
                    >
                      {t('general.restore')}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      color="texas.sand.50"
                      icon={<Icons.Archive boxSize={5} />}
                      onClick={() => onArchiveOpen(info.row.original)}
                    >
                      {t('general.archive')}
                    </MenuItem>
                  )}
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
    }),
  ];

  return columns;
};
