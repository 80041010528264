import { useMemo } from 'react';
import { BrandsWidget } from './Brands/BrandsWidget';
import { ArticlesWidget } from './Articles/ArticlesWidget';
import { Box } from '@chakra-ui/react';
import { WidgetsLayout } from './WidgetsContainer';
import { WidgetType, widgetEvents } from './widgetEvents';
import { defaultWidgetLayouts } from './defaultWidgetLayouts';
import { useLocalStorage } from '@texas/hooks/useLocalStorage';
import { SearchArticleOptions } from '../searchArticles/types';

export type WidgetOptions = SearchArticleOptions;
type Option = {
  [_ in WidgetType]?: WidgetOptions;
};

export interface Widget {
  id: string;
  type: WidgetType;
  layoutIndex: string;
  name: string;
}

export interface WidgetLayout {
  layout: ReactGridLayout.Layouts;
  widgets: Widget[];
  currentLayout?: ReactGridLayout.Layout[];
}

export interface WidgetLayoutProps {
  defaultLayout: WidgetLayout;
  localStorageKey?: string;
  widgetOptions: Option;
}

export const useWidgetLayout = (props: WidgetLayoutProps) => {
  const [layout, setLayout] = useLocalStorage(
    props.localStorageKey,
    props.defaultLayout,
  );

  function addNewWidget(widget: WidgetType) {
    const widgetId = layout.widgets.length.toString();

    setLayout((l) => ({
      layout: {
        ['lg']: [
          ...l.layout['lg'],
          { ...defaultWidgetLayouts[widget].lg, i: widgetId },
        ],
      },
      widgets: [
        ...l.widgets,
        { id: widgetId, type: widget, layoutIndex: widgetId, name: widget },
      ],
      currentLayout: l.currentLayout,
    }));
  }

  const widgets = useMemo(() => {
    return (
      <WidgetsLayout
        onResize={(_layout, _oldItem, newItem) => {
          const widget = layout.widgets.find(
            (w) => w.layoutIndex === newItem.i,
          );
          if (!widget) return;
          triggerEvent(widget.type, newItem.w, newItem.h);
        }}
        onLayoutChange={onLayoutChange}
        layout={layout.layout}
      >
        {layout.widgets.map((w) => {
          const widgetLayout = layout.currentLayout?.find(
            (x) => x.i === w.layoutIndex,
          );
          return (
            <Box key={w.layoutIndex}>
              {widgetComponent(
                w.type,
                widgetLayout?.h ?? 0,
                props.widgetOptions[w.type],
              )}
            </Box>
          );
        })}
      </WidgetsLayout>
    );

    function onLayoutChange(
      currentLayout: ReactGridLayout.Layout[],
      allLayouts: ReactGridLayout.Layouts,
    ) {
      setLayout((s) => ({
        widgets: s.widgets,
        layout: allLayouts,
        currentLayout: currentLayout,
      }));
    }
  }, [
    layout.layout,
    layout.widgets,
    layout.currentLayout,
    props.widgetOptions,
    setLayout,
  ]);

  return { widgets, addNewWidget };
};

function widgetComponent(
  widgetType: WidgetType,
  height: number,
  options?: WidgetOptions,
) {
  switch (widgetType) {
    case WidgetType.Brands:
      return <BrandsWidget height={height} />;
    case WidgetType.Articles:
      return (
        <ArticlesWidget
          customWidgetTitle={options?.customWidgetTitle}
          options={options!}
        />
      );
  }
}

function triggerEvent(event: WidgetType, width: number, height: number) {
  if (!widgetEvents.resize[event]) return;
  widgetEvents.resize[event]?.dispatch({
    widget: event,
    width: width,
    height: height,
  });
}
