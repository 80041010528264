import { Text, Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function ArticlesDataTableContainerFooter({
  selectedRows,
  onClear,
}: {
  selectedRows: number;
  onClear: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex align="start" flexDir="column" pl={6}>
      <Text>
        {t('searchArticles.articlesSelected', {
          articles: selectedRows,
        })}
      </Text>
      <Button
        size="xs"
        variant="link"
        color="blue.400"
        isDisabled={selectedRows === 0}
        onClick={onClear}
      >
        {t('general.clear')}
      </Button>
    </Flex>
  );
}
