import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useActiveSession } from '@texas/hooks/useSession';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';

export function useProductApprovals() {
  const { data, setData } = useProdutApprovalWizardState();
  const { currentUser } = useActiveSession();
  const [selectedKey, setSelectedKey] = useState<string>('');
  const previousRelevantPaRef = useRef<GeneratedProductApproval | null>();

  const { data: compositions, refetch } = useApiResource(
    compositionApi.searchCompositions,
  );

  useEffect(() => {
    refetch({
      variantId: data.variantId,
      branchIds: data.selectedBranches.map((x) => x.id),
      supplierIds: data.selectedSuppliers.map((x) => x.id),
    });
  }, [data.selectedBranches, data.selectedSuppliers, data.variantId, refetch]);

  const generateProductApprovals = useCallback(() => {
    if (!compositions) return;

    const productApprovals: Record<string, GeneratedProductApproval> = {};
    const keys: string[] = [];

    const baseTemplate = compositions.find(
      (c) => c.template && !c.cellX && !c.cellY,
    );

    for (let k = 0; k < data.selectedSuppliers.length; k++) {
      const supplier = data.selectedSuppliers[k];

      if (nodeGroupIsPopulated(data.articleNodes)) {
        const cellKeys = Object.keys(data.selectedCells);
        for (let i = 0; i < cellKeys.length; i++) {
          const cell = data.selectedCells[cellKeys[i]];

          if (!cell.added) continue;

          const format = `${supplier.id}-${supplier.branch.id}-${cell.x}-${cell.y}`;

          const cellTemplate = compositions.find(
            (c) => c.template && c.cellX === cell.x && c.cellY === cell.y,
          );
          const supplierCellComposition = compositions.find(
            (c) =>
              c.supplierId === supplier.id &&
              c.branchId === supplier.branch.id &&
              c.cellX === cell.x &&
              c.cellY === cell.y,
          );

          keys.push(format);
          productApprovals[format] = {
            key: format,
            branch: supplier.branch,
            cell: {
              x: cell.x,
              y: cell.y,
              xIndex: cell.xIndex,
              yIndex: cell.yIndex,
              added: true,
            },
            customerContact: null,
            issuedBy: {
              id: currentUser.id,
              name: `${currentUser.firstName} ${currentUser.lastName}`,
            },
            supplier: supplier,
            supplierContact: null,
            supplierExternalItemCode: '',
            supplierNote: '',
            state: GeneratedProductApprovalState.Unhandled,
            composition:
              supplierCellComposition ?? cellTemplate ?? baseTemplate ?? null,
          };
        }

        continue;
      }

      const supplierComposition = compositions.find(
        (c) =>
          c.supplierId === supplier.id && c.branchId === supplier.branch.id,
      );

      const format = `${supplier.id}-${supplier.branch.id}`;

      keys.push(format);
      productApprovals[format] = {
        key: format,
        branch: supplier.branch,
        cell: null,
        customerContact: null,
        issuedBy: {
          id: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
        },
        supplier: supplier,
        supplierContact: null,
        supplierExternalItemCode: '',
        supplierNote: '',
        state: GeneratedProductApprovalState.Unhandled,
        composition: supplierComposition ?? baseTemplate ?? null,
      };
    }

    if (keys.length === 0) return;

    setSelectedKey(keys[0]);
    setData((d) => ({
      ...d,
      generatedProductApprovals: productApprovals,
      generatedProductApprovalKeys: keys,
    }));
  }, [
    compositions,
    setData,
    data.selectedSuppliers,
    data.articleNodes,
    data.selectedCells,
    currentUser.id,
    currentUser.firstName,
    currentUser.lastName,
  ]);

  useEffect(() => {
    generateProductApprovals();
  }, [generateProductApprovals]);

  return {
    selectedKey,
    setSelectedKey,
    previousRelevantPaRef,
  };
}
