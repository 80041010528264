import { Flex, Text } from '@chakra-ui/react';

export function LabelValue({ label, value }: { label: string; value: any }) {
  return (
    <Flex align="center">
      <Text variant="meta-field">{label}</Text>
      <Text variant="small" ml="auto">
        {value}
      </Text>
    </Flex>
  );
}
