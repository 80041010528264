// @ngInject
const fileBrowser = () => ({
  templateUrl: 'directives/fileBrowser/fileBrowser.tpl.html',
  restrict: 'EA',
  replace: true,
  scope: {
    folderPath: '=',
    identifier: '=',
    rootFolderId: '=',
  },
  controller: 'FileBrowserController',
  controllerAs: 'vm',
});

class FileBrowserController {
  constructor(
    $scope,
    $state,
    $mdSidenav,
    $location,
    FilesApiService,
    loadSpinnerService,
    FolderDialogService,
    NotificationsApiService,
    UploaderService,
    AuthService,
    toasterService,
    HelperService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$mdSidenav = $mdSidenav;
    this.$location = $location;
    this.FilesApiService = FilesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.FolderDialogService = FolderDialogService;
    this.NotificationsApiService = NotificationsApiService;
    this.toasterService = toasterService;
    this.HelperService = HelperService;

    this.validationErrors = [];
    this.rootFolder = {};
    this.currentPath = '';
    this.selectedFolder = {};
    this.uploadInstance = {};
    this.selectedItems = [];
    this.dropping = false;
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);

    this.folderTreeOptions = {
      onSelect: ($event, node) => this.onFolderTreeSelect($event, node),
    };

    this.fileListOptions = {
      onFolderSelect: (folder) => this.onFileListFolderSelect(folder),
      onFolderEdit: (folder) => this.editFolder(folder),
      onFolderArchive: (folder) => this.onFolderArchive(folder),
      onFileEdit: (file) => this.editFile(file),
      onFileArchive: (file) => this.onFileArchive(file),
    };

    this.uploadInstance = UploaderService.instance('fileBrowserUploadInstance');
    this.uploadInstance.finalizeUrl = () =>
      `folders/${this.selectedFolder.id}/files`;

    this.startSpinner();

    FilesApiService.clearFolderCache();
    FilesApiService.getFolderById($scope.rootFolderId)
      .then((folder) => {
        this.rootFolder = folder;

        if (!$scope.folderPath) {
          this.selectedFolder = this.rootFolder;
        } else {
          this.goToPath($scope.folderPath);
        }

        if ($scope.identifier) {
          this.downloadFile($scope.identifier);
        }
      })
      .finally(() => this.stopSpinner());

    $scope.$watch(
      () => $scope.folderPath,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.goToPath($scope.folderPath);
        }
      },
    );

    $scope.$watch(
      () => $scope.identifier,
      (newValue, oldValue) => {
        if (newValue !== oldValue && newValue) {
          this.downloadFile($scope.identifier);
        }
      },
    );
  }

  selectFolder(id) {
    this.FilesApiService.getFolderById(id)
      .then((response) => {
        this.selectedFolder = response;
        this.selectedItems.length = 0;
        this.uploadInstance.disabled = !!this.selectedFolder.isReadOnly;
      })
      .finally(() => this.stopSpinner());
  }

  getFolder(folderId) {
    return this.FilesApiService.getFolderById(folderId);
  }

  newFolder() {
    this.FolderDialogService.showDialog({ parent: this.selectedFolder });
  }

  editFolder(folder) {
    if (folder.isEditable) {
      this.FolderDialogService.showDialog({ folder });
    }
  }

  editFile(file) {
    if (!this.currentUserCanEdit) {
      return;
    }

    this.selectedFileOriginal = file;
    this.selectedFile = _.extend({}, file);
    if (this.fileForm) {
      this.fileForm.$setPristine(true);
    }
    this.$mdSidenav('file-sidenav').toggle();
  }

  closeSidenav() {
    this.$mdSidenav('file-sidenav')
      .close()
      .then(() => {
        this.selectedFileOriginal = null;
        this.selectedFile = null;
      });
  }

  saveSidenav() {
    // Get any changes
    const changedData = this.HelperService.getChangedData(
      this.selectedFileOriginal,
      this.selectedFile,
      ['name', 'note'],
    );

    // If changes are found, update
    if (_.isEmpty(changedData)) {
      this.closeSidenav();
      return;
    }

    this.validationErrors = [];
    this.startSpinner();
    const identifier = this.selectedFile.identifier;
    this.FilesApiService.updateFileInfo(changedData, identifier)
      .then(
        () => {
          this.toasterService.success();
          this.closeSidenav();
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.stopSpinner());
  }

  onFolderTreeSelect($event, node) {
    this.updateFolderPath([], node.$model);
  }

  onFileListFolderSelect(folder) {
    this.updateFolderPath([], folder);
  }

  onFileArchive(file) {
    this.startSpinner();
    this.FilesApiService.updateFileInfo(
      { archived: !file.archived },
      file.identifier,
    )
      .then(() => this.toasterService.success())
      .finally(() => this.stopSpinner());
  }

  onFolderArchive(folder) {
    if (folder.isEditable) {
      this.startSpinner();
      this.FilesApiService.updateFolderInfo(
        { archived: !folder.archived },
        folder.id,
      )
        .then(() => this.toasterService.success())
        .finally(() => this.stopSpinner());
    }
  }

  goToPath(path) {
    this.startSpinner();
    this.goToLastFolderInPath(path);
  }

  goToLastFolderInPath(path) {
    if (path) {
      const folders = _.map(path.split('/'), Number);
      folders.unshift(this.rootFolder.id);
      this.selectLastFolderInPath(folders, this.rootFolder);
    } else {
      this.selectFolder(this.rootFolder.id);
    }
  }

  selectLastFolderInPath(folders, folder) {
    const currentIndex = folders.indexOf(folder.id);
    const nextFolderId = folders[currentIndex + 1];

    if (nextFolderId === undefined) {
      return;
    }

    const nextFolder = _.find(folder.folders, { id: nextFolderId });

    if (!nextFolder) {
      this.selectFolder(folder.id);
      return;
    }

    // If this is the last item, select it
    if (folders[folders.length - 1] === nextFolderId) {
      this.selectFolder(nextFolderId);
      return;
    }

    if (nextFolder.folders.length > 0) {
      this.selectLastFolderInPath(folders, nextFolder);
    } else {
      this.FilesApiService.getFolderById(nextFolderId).then((response) =>
        this.selectLastFolderInPath(folders, response),
      );
    }
  }

  updateFolderPath(folders, folder) {
    if (folders.length === 0) {
      folders.unshift(folder.id);
    }

    if (!folder.parentId || folder.id === this.rootFolder.id) {
      this.$state.go('.', { folderPath: null });
      return;
    }

    if (folder.parentId !== this.rootFolder.id) {
      folders.unshift(folder.parentId);
      this.updateFolderPath(
        folders,
        this.FilesApiService.getFolderFromCache(folder.parentId),
      );
    } else {
      this.$state.go('.', { folderPath: folders.join('/') });
    }
  }

  startSpinner() {
    this.loadSpinnerService.start('fileBrowserSpinner');
  }

  stopSpinner() {
    this.loadSpinnerService.stop('fileBrowserSpinner');
  }

  downloadFile(identifier) {
    if (!identifier) {
      return;
    }

    const fileBrowser = angular.element(
      document.querySelector('.file-browser'),
    );
    let iFrame = fileBrowser.find('iframe');
    if (!(iFrame && iFrame.length > 0)) {
      iFrame = angular.element(
        "<iframe style='position:fixed;display:none;top:-1px;left:-1px;'></iframe>",
      );
      fileBrowser.append(iFrame);
    }

    iFrame.attr('src', this.FilesApiService.getFileUrl(identifier));
    this.$location.search('identifier', null);
  }
}

angular
  .module('directives.fileBrowser', ['folderTree', 'fileList'])
  .directive('fileBrowser', fileBrowser)
  .controller('FileBrowserController', FileBrowserController);
