// This is is somehow causing prettier to cast a format error here (SyntaxError: Expression expected)
// inside the function call. But the function does not accept any arguments...

import { UpdateCompositionRequest } from "@texas/api/endpoints/compositionApi";
import { createContextValue } from "@texas/utils/contextState";
import { useFormSubmitStore } from "./useFormSubmit";

// prettier-ignore does not work, so we have to ignore the whole file.
export const [UpdateCompositionFormSubmitProvider, useUpdateCompositionFormSubmitState]
  = createContextValue<ReturnType<typeof useFormSubmitStore<UpdateCompositionRequest>>>();
