import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  ConnectContactRequest,
  ContactAddToType,
  SearchQuery,
  contactsApi,
  defaultSearchQuery,
} from '@texas/api/endpoints/contactsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { contactsTableColumns } from './Columns';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { TexasSwitch } from '@texas/components/shared/form/TexasSwitch';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ContactsModal } from '@texas/components/contacts/ContactsModal';

const defaultPageSize = 50;

export function Contacts({ customerId }: { customerId: number }) {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: contacts,
    refetch,
    error,
    loading,
  } = useApiResource(contactsApi.getAll);

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    ...defaultSearchQuery,
    pageSize: defaultPageSize,
    customerId: customerId,
  });

  const {
    onOpen: onAddOpen,
    onClose: onAddClose,
    isOpen: isAddOpen,
  } = useDisclosure();

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const { request: disconnectRequest } = useApiRequest(
    contactsApi.disconnectCustomer,
  );
  const { request: connectRequest } = useApiRequest(
    contactsApi.connectCustomer,
  );
  const onAdd = async (data: ConnectContactRequest) =>
    await request(
      connectRequest,
      [data],
      () => {
        toast({
          title: t('general.added'),
          status: 'success',
          isClosable: true,
        });
        refetch(searchQuery);
      },
      (connectError: ServerError) => {
        toast({
          title: t('general.addFailed'),
          description: connectError.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  const columns = useMemo(() => {
    const onRemove = async (data: ConnectContactRequest) =>
      await request(
        disconnectRequest,
        [data],
        () => {
          toast({
            title: t('general.successfullyRemoved'),
            status: 'success',
            isClosable: true,
          });
          refetch(searchQuery);
        },
        (connectError: ServerError) => {
          toast({
            title: t('general.removeFailed'),
            description: connectError.message,
            status: 'error',
            isClosable: true,
          });
        },
      );

    return contactsTableColumns(t, customerId, onRemove);
  }, [customerId, disconnectRequest, refetch, searchQuery, t, toast]);

  return (
    <BodyOverride>
      <ContactsModal
        onSelect={async (c) => {
          await onAdd({ id: c.id, referenceId: customerId });
          onAddClose();
        }}
        mode="link"
        filterOn={null}
        addTo={ContactAddToType.Customer}
        referenceId={customerId}
        onClose={() => {
          refetch(searchQuery);
          onAddClose();
        }}
        isOpen={isAddOpen}
      />
      <Flex direction="column" gap={4}>
        <Text variant="header">{t('contacts.contacts')}</Text>
        <Flex justify="space-between">
          <Box height="fit-content" mt="auto" w={400}>
            <SearchFilterInput
              value={searchQuery.searchTerm}
              placeholder={t('contacts.searcNameOrEmail')}
              onChange={(s) =>
                setSearchQuery({ ...searchQuery, searchTerm: s })
              }
            />
          </Box>
          <Button onClick={onAddOpen}>{t('contacts.addContact')}</Button>
        </Flex>
        <TexasSwitch
          checked={searchQuery.includeArchived}
          label={t('general.includeArchived')}
          onChange={(checked) =>
            setSearchQuery({
              ...searchQuery,
              includeArchived: checked,
            })
          }
        />
        <DataTableContainer
          w="100%"
          error={error}
          datatable={{
            data: contacts?.items ?? [],
            sorted: {
              onSortedChange: ({ key, desc }) => {
                setSearchQuery({ ...searchQuery, sortBy: key, sortDesc: desc });
              },
              key: searchQuery.sortBy,
              desc: searchQuery.sortDesc,
            },
            isLoading: loading,
            columns: columns,
            variant: 'configuration',
          }}
          pagination={{
            totalItems: contacts?.totalItems ?? 0,
            pageSize: defaultPageSize,
            currentPage: searchQuery.page,
            onPageChange: (page) =>
              setSearchQuery({
                ...searchQuery,
                page,
              }),
          }}
        />
      </Flex>
    </BodyOverride>
  );
}
