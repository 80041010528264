import { Text, VStack, GridItem, Badge, Link } from '@chakra-ui/react';
import { fadeInScaleAnimation } from '../../../resources/animations/animations';
import { useTranslation } from 'react-i18next';

export interface BrandCardProps {
  name: string;
  id: number;
  nrOfArticles: number;
}

export function BrandCard(props: BrandCardProps) {
  const { t } = useTranslation();
  return (
    <GridItem
      animation={fadeInScaleAnimation()}
      bg="texas.bg.900"
      borderRadius={4}
      px={4}
      py={2}
      _hover={{ bg: 'texas.bg.800' }}
      pos="relative"
      overflow="hidden"
      minW="120px"
      maxW="220px"
    >
      <Link href={`#/customers/${props.id}`} pos="relative">
        <VStack flexGrow={1} minW={100} justify="space-between" align="start">
          <Text fontSize={18}>{props.name}</Text>
          <Badge>
            {props.nrOfArticles} {t('general.articles')}
          </Badge>
        </VStack>
      </Link>
    </GridItem>
  );
}
