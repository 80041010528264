import { reactEvents } from '../../../bridge/reactEvents';

// @ngInject
const scrollTop = ($window) => $window.scrollTo(0, 0);

// @ngInject
const articleResolve = ($stateParams, ArticlesApiService) =>
  ArticlesApiService.getById($stateParams.articleId, {
    includeRelations: ['owner', 'matrixNodeX', 'matrixNodeY', 'productGroup'],
  });

// @ngInject
const rootFolderIdResolve = (article) => article.folderId;

// @ngInject
const variantsResolve = (article, ArticlesApiService) =>
  ArticlesApiService.getVariantsByArticleId(article.id);

// @ngInject
const productGroupsResolve = (currentOrganization, ProductGroupsApiService) =>
  ProductGroupsApiService.getAllByOrganizationId(currentOrganization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('article', {
    parent: 'main',
    url: '/articles/{articleId}',
    views: {
      'main-content': {
        controller: 'ArticleController as vm',
        templateUrl: 'main/article/article.tpl.html',
      },
    },
    data: { pageTitle: 'Article' },
    resolve: {
      article: articleResolve,
      variants: variantsResolve,
      productGroups: productGroupsResolve,
      variantOptions() {
        return { selectedBranch: null };
      },
    },
    onEnter: scrollTop,
  });

  // Files tab
  $stateProvider.state('article.files', {
    url: '/files',
    abstract: true,
    templateUrl: 'tabs/filesTab/filesTab.tpl.html',
    controller: 'FilesTabsController as vm',
    resolve: {
      rootFolderId: rootFolderIdResolve,
    },
  });

  $stateProvider.state('article.files.id', {
    url: '/{folderPath:nonURIEncoded}?{identifier:string}',
    params: {
      folderPath: { value: null, squash: true },
      identifier: { value: null, squash: true },
    },
  });

  // Tasks tab
  $stateProvider.state('article.tasks', {
    url: '/tasks',
    templateUrl: 'tabs/tasksTab/tasksTab.tpl.html',
    controller: 'TasksTabsController as vm',
    resolve: {
      concept: () => null,
      customer: () => null,
      tasksTabSettings: () => ({ hideBadges: false }),
    },
    params: {
      toDoTaskId: { value: null },
    },
  });
};

class ArticleController {
  constructor(
    $state,
    $scope,
    DS,
    article,
    variants,
    currentOrganization,
    ArticleVariantDialogService,
    SavingService,
  ) {
    'ngInject';

    this.DS = DS;
    this.article = article;
    this.variants = variants;
    this.nodeGroup = {
      nodeXValues: this.article.nodeXValues,
      nodeYValues: this.article.nodeYValues,
    };

    this.displayVariants = this.getDisplayVariants(variants);
    this.ArticleVariantDialogService = ArticleVariantDialogService;
    this.SavingService = SavingService;
    this.$state = $state;
    this.$scope = $scope;

    if (this.$state.params.variantId) {
      this.setCurrentVariant(this.$state.params.variantId);
    } else {
      const currentVariant =
        _.find(variants, { id: article.mainVariantId }) || variants[0];

      if (currentVariant == undefined) {
        this.setCurrentVariant(0);
      } else {
        this.setCurrentVariant(currentVariant.id);
      }
    }

    this.getDisplayText = (article) => article.itemCode;
    this.goToManagement = () => $state.go('article.management');

    this.entityTabs = [
      {
        heading: '<i class="icon icon-settings"></i>',
        tooltip: 'Management',
        route: 'article.management',
      },
      {
        heading: '<i class="icon icon-folder"></i>',
        tooltip: 'Files',
        route: 'article.files.id',
      },
      {
        heading: '<i class="icon icon-assignment"></i>',
        tooltip: 'Tasks',
        route: 'article.tasks',
      },
      {
        heading: '<i class="icon icon-eye"></i>',
        tooltip: 'Product approval',
        route: 'article.productApproval',
        hidden: true,
      },
    ];

    this.contentTabs = [
      {
        heading: 'Compositions',
        route: 'variant.compositions',
        hidden: true,
      },
      {
        isDefault: true,
        active: this.$state.is('article'),
        heading: 'Garp',
        route: 'variant.general.garp',
      },
      {
        heading: 'Technical',
        route: 'variant.technical',
      },
      {
        heading: 'Matrix',
        route: 'variant.matrix',
        hidden: !article.matrixNodeY || !article.matrixNodeX,
      },
      {
        heading: 'Suppliers',
        route: 'variant.suppliers',
        hidden: true,
      },
      {
        heading: 'Purchase',
        route: 'variant.purchase',
      },
      {
        heading: 'Sales',
        route: 'variant.sales',
      },
    ];

    _.map(this.contentTabs, (tab) => {
      tab.params = () => this.getCurrentVariantId();
    });

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('article') || this.$state.is('variant')) {
        this.goToDefaultState();
      }
    });

    this.variantNameChangedEvent = reactEvents.variantNameChanged.subscribe(
      (variant) => {
        this.setCurrentVariantName(variant);
      },
    );

    this.setupWatchers();
  }

  goToDefaultState() {
    const initialTab = _.find(this.contentTabs, { isDefault: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }

  getCurrentVariantId() {
    if (this.currentVariant == undefined) {
      return { variantId: 0 };
    }
    return { variantId: this.currentVariant.id };
  }

  newVariant() {
    this.ArticleVariantDialogService.showDialog({
      articleId: this.article.id,
    }).then((newVariant) => {
      this.addVariant(newVariant);
      this.changeVariant(newVariant.id);
    });
  }

  editVariant(variant, event) {
    event.stopPropagation();
    event.preventDefault();

    this.ArticleVariantDialogService.showDialog({ variant });
  }

  changeVariant(variantId) {
    const activeTab = this.getActiveTab();
    if (activeTab) {
      this.$state.go(this.getActiveTab().route, { variantId });
    }
  }

  setCurrentVariant(variantId) {
    variantId = parseInt(variantId, 10);
    this.currentVariant = _.find(this.variants, { id: variantId });
  }

  setCurrentVariantName(variant) {
    this.currentVariant.name = variant.name;
    this.variants.find((v) => v.id === variant.id).name = variant.name;
    this.displayVariants = this.getDisplayVariants(this.variants);
    this.$scope.$applyAsync();
  }

  addVariant(variant) {
    this.variants.push(variant);
    this.displayVariants = this.getDisplayVariants(this.variants);
    return variant;
  }

  getActiveTab() {
    return _.find(this.contentTabs, { active: true });
  }

  archivedExists() {
    return !!_.find(this.variants, { archived: true });
  }

  getDisplayVariants(variants) {
    return _.map(variants, (variant) => ({
      id: variant.id,
      name: variant.name,
      isMain: this.isMainVariant(variant),
      archived: variant.archived,
    }));
  }

  isMainVariant(variant) {
    return variant.id === this.article.mainVariantId ? true : false;
  }

  setupWatchers() {
    this.$scope.$watch(
      () => this.$state.params.variantId,
      (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          this.setCurrentVariant(newValue);
        }
      },
    );

    this.$scope.$watch(
      () => this.article.mainVariantId,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.displayVariants = this.getDisplayVariants(this.variants);
        }
      },
    );

    this.$scope.$on('$destroy', () => {
      this.variantNameChangedEvent();
    });
  }
}

angular
  .module('main.article', [
    'main.article.variant',
    'main.article.management',
    'main.article.productApproval',
  ])
  .config(config)
  .controller('ArticleController', ArticleController);
