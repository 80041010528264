import {
  Box,
  Button,
  Flex,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { productGroupTableColumns } from './Columns';
import { EditProductGroup } from './EditProductGroup';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { Container } from '@texas/components/shared/Container';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { TexasSwitch } from '@texas/components/shared/form/TexasSwitch';
import {
  SearchQuery,
  defaultSearchQuery,
} from '@texas/api/endpoints/metadata/types';
import {
  ProductGroupListItem,
  productGroupsApi,
} from '@texas/api/endpoints/metadata/productGroupsApi';
import { CreateProductGroup } from './CreateProductGroup';
import { ArchiveProductGroup } from './ArchiveProductGroup';

export function ProductGroups() {
  const {
    onOpen: onCreateOpen,
    onClose: onCreateClose,
    isOpen: isCreateOpen,
  } = useDisclosure();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<ProductGroupListItem>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<number>();

  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: productGroups,
    refetch,
    error,
    loading,
  } = useApiResource(productGroupsApi.getAll);

  const [searchQuery, setSearchQuery] =
    useState<SearchQuery>(defaultSearchQuery);

  const { request: restoreProductGroupRequest } = useApiRequest(
    productGroupsApi.restore,
  );

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restoreProductGroupRequest,
        [id],
        () => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(searchQuery);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [restoreProductGroupRequest, toast, t, refetch, searchQuery],
  );

  const columns = useMemo(() => {
    return productGroupTableColumns(
      t,
      onArchiveOpen,
      performRestore,
      onEditOpen,
    );
  }, [t, onArchiveOpen, performRestore, onEditOpen]);

  return (
    <Container title={t('configuration.productGroups')}>
      {archiveValue && (
        <ArchiveProductGroup
          productGroup={archiveValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchive={() => {
            refetch(searchQuery);
          }}
        />
      )}

      <EditProductGroup
        productGroupId={editValue}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onUpdated={(_) => {
          refetch(searchQuery);
        }}
      />

      <CreateProductGroup
        onCreated={() => {
          refetch(searchQuery);
          onCreateClose();
        }}
        onClose={onCreateClose}
        isOpen={isCreateOpen}
      />

      <VStack w="100%" alignItems="start" p={4} background="texas.bg.900">
        <Flex justify="space-between" w="100%">
          <Flex direction="column" gap={4}>
            <Flex gap={4} align="flex-end">
              <Box height="fit-content" mt="auto" minWidth={80}>
                <SearchFilterInput
                  value={searchQuery.searchTerm}
                  onChange={(s) =>
                    setSearchQuery({ ...searchQuery, searchTerm: s })
                  }
                />
              </Box>
            </Flex>
            <TexasSwitch
              checked={searchQuery.includeArchived}
              label={t('general.includeArchived')}
              onChange={(checked) =>
                setSearchQuery({
                  ...searchQuery,
                  includeArchived: checked,
                })
              }
            />
          </Flex>
          <Button onClick={onCreateOpen}>{t('general.addNew')}</Button>
        </Flex>
        <DataTableContainer
          w="100%"
          datatable={{
            data: productGroups ?? [],
            sorted: {
              onSortedChange: ({ key, desc }) => {
                setSearchQuery({ ...searchQuery, sortBy: key, sortDesc: desc });
              },
              key: searchQuery.sortBy,
              desc: searchQuery.sortDesc,
            },
            isLoading: loading,
            columns: columns,
            variant: 'configuration',
          }}
          error={error}
        />
      </VStack>
    </Container>
  );
}
