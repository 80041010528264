import { useActiveSession } from '@texas/hooks/useSession';
import { BodyOverride } from '../shared/BodyOverride';
import { useWidgetLayout } from '../widgets/useWidgetLayout';
import { WidgetType } from '../widgets/widgetEvents';
import { TableColumns } from './TableColumns';
import { defaultDashboardWidgetsLayout } from './defaultDashboardWidgetsLayout';
import {
  defaultArticlesWidgetOptions,
  pageSize,
} from './defaultWidgetsOptions';

export function Dashboard() {
  const session = useActiveSession();
  const { widgets } = useWidgetLayout({
    localStorageKey: `${session.currentUser.id}_dashboard`,
    defaultLayout: defaultDashboardWidgetsLayout,
    widgetOptions: {
      [WidgetType.Articles]: {
        ...defaultArticlesWidgetOptions(session.currentUser),
        localStorageKey: `${session.currentUser.id}_${WidgetType.Articles}`,
        columns: TableColumns,
        optOutDefaultOrdering: true,
        pageSize: pageSize,
      },
    },
  });

  return <BodyOverride>{widgets}</BodyOverride>;
}
