import {
  Box,
  HStack,
  VStack,
  Text,
  SkeletonText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Portal,
  Flex,
} from '@chakra-ui/react';
import { Project, projectApi } from '@texas/api/endpoints/projectsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Icons } from '@texas/components/shared/Icons';
import { AlertDetails } from '@texas/components/shared/alert/AlertDetails';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import {
  fadeInScaleAnimation,
  scaleInHeightAnimation,
  scaleInWidthAnimation,
} from '@texas/resources/animations/animations';
import { ServerError } from '@texas/types';
import { useTranslation } from 'react-i18next';
import { ArchiveProjectDialog } from './ArchiveProjectDialog';
import { EditProjectModal } from './EditProjectModal';
import { request } from '@texas/utils/helpers/httpHelpers';

interface ProjectComponentProps {
  project: Project | null;
  loading: boolean;
  customerArchived: boolean;
  keyAccountManagerId: number;
  onProjectUpdated: (project: Project) => void;
  onArchivedStateChanged: (project: Project) => void;
  finalizeContainer: boolean;
}

export function ProjectComponent(props: ProjectComponentProps) {
  const { t } = useTranslation();
  const { request: restoreProjectRequest, loading: restoreLoading } =
    useApiRequest(projectApi.restoreProject);
  const toast = useToast();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<Project>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<Project>();

  if (props.loading) {
    return <SkeletonText noOfLines={3} spacing="4" skeletonHeight="2" />;
  }
  if (!props.project) {
    return <AlertDetails type="error" title={t('project.failedToLoad')} />;
  }

  return (
    <Box
      pos="relative"
      overflow="hidden"
      boxShadow="base"
      borderRadius={6}
      animation={fadeInScaleAnimation()}
    >
      {(props.project.archived || props.customerArchived) && (
        <Box
          animation={fadeInScaleAnimation(200)}
          pos="absolute"
          boxSize="100%"
          zIndex={1}
          bg="texas.bg.90085"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <VStack spacing={1}>
            <Icons.Archive />
            <HStack
              fontSize={12}
              animation={scaleInHeightAnimation('32px', 400, 200)}
            >
              {props.customerArchived ? (
                <Text>{t('customer.archived')}</Text>
              ) : (
                <Text>
                  {t('project.isArchived', { projectName: props.project.name })}
                </Text>
              )}
            </HStack>

            {!props.customerArchived && (
              <VerifyButton
                buttonProps={{
                  size: 'xs',
                  variant: 'texas-success-solid',
                  isLoading: restoreLoading,
                }}
                onVerified={() => {
                  restoreProject(props.project!.id);
                }}
                label="Restore project"
              />
            )}
          </VStack>
        </Box>
      )}
      <Flex
        dir="column"
        align="center"
        gap={2}
        backgroundColor="texas.bg.800"
        p={3}
        role="group"
      >
        {editValue && (
          <EditProjectModal
            project={editValue}
            isOpen={isEditOpen}
            userId={props.keyAccountManagerId}
            onClose={onEditClose}
            onProjectUpdated={(p) => {
              props.onProjectUpdated(p);
              onEditClose();
            }}
          />
        )}

        {archiveValue && (
          <ArchiveProjectDialog
            project={archiveValue}
            isOpen={isArchiveOpen}
            onClose={onArchiveClose}
            onArchive={(project: Project) => {
              props.onArchivedStateChanged(project);
            }}
          />
        )}
        <VStack alignItems="start">
          <HStack>
            <Icons.AlphaPBox
              animation={scaleInWidthAnimation('32px', 200, 200)}
              maxW={0}
              color="texas.sand.100"
              boxSize={6}
            />
            <Text fontWeight="bold" mb="0">
              {props.project.name}
            </Text>
          </HStack>

          <Text variant="small" mt="0 !important">
            {t('project.projectNumber', { projectNumber: props.project.erpId })}
          </Text>
          {props.project.keyAccountManager && (
            <HStack>
              <Icons.CircleMultiple boxSize={4} />
              <Text>{props.project.keyAccountManager.name}</Text>
            </HStack>
          )}
        </VStack>

        <UtilityButton
          finalizeContainer={props.finalizeContainer}
          project={props.project}
          onEditOpen={onEditOpen}
          onArchiveOpen={onArchiveOpen}
        />
      </Flex>
    </Box>
  );

  async function restoreProject(projectId: number) {
    await request(
      restoreProjectRequest,
      [projectId],
      (data) => {
        toast({
          title: t('general.restored'),
          status: 'success',
          isClosable: true,
        });
        props.onArchivedStateChanged(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.restoreFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}

function UtilityButton({
  finalizeContainer,
  project,
  onEditOpen,
  onArchiveOpen,
}: {
  finalizeContainer: boolean;
  project: Project;
  onEditOpen: (project: Project) => void;
  onArchiveOpen: (project: Project) => void;
}) {
  const { t } = useTranslation();

  if (finalizeContainer) {
    return (
      <Box
        ml="auto"
        zIndex={2}
        transition="all 200ms ease"
        opacity={0}
        padding={1}
        transform="scale(0.9)"
        _groupHover={{
          opacity: 1,
          transform: 'scale(1)',
        }}
        borderRadius="100%"
        aria-label="Edit"
        backgroundColor="white"
        onClick={() => onEditOpen(project)}
      >
        <Icons.Pencil boxSize={6} color="black" />
      </Box>
    );
  }

  return (
    <Box ml="auto !important" zIndex={2}>
      <Menu>
        <MenuButton>
          <Box
            transition="all 200ms ease"
            opacity={0}
            padding={1}
            transform="scale(0.9)"
            _groupHover={{
              opacity: 1,
              transform: 'scale(1)',
            }}
            borderRadius="100%"
            aria-label="Edit"
            backgroundColor="white"
          >
            <Icons.DotsHorizontal boxSize={6} color="black" />
          </Box>
        </MenuButton>
        <Portal>
          <MenuList color="white">
            <MenuItem
              icon={<Icons.Pencil boxSize={5} />}
              onClick={() => onEditOpen(project)}
            >
              {t('general.edit')}
            </MenuItem>
            <MenuItem
              color="texas.sand.50"
              icon={<Icons.Archive boxSize={5} />}
              onClick={() => onArchiveOpen(project)}
            >
              {t('general.archive')}
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
}
