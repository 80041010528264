import { Box, Heading } from '@chakra-ui/react';

export interface ContainerProps {
  children: React.ReactNode;
  title: string;
  noBorderRadius?: boolean;
}

export function Container(props: ContainerProps) {
  return (
    <Box
      bgColor="texas.bg.900"
      borderRadius={props.noBorderRadius ? 0 : 12}
      overflow="hidden"
    >
      <Heading size="md" px={4} py={2} letterSpacing="wider">
        {props.title.toUpperCase()}
      </Heading>
      {props.children}
    </Box>
  );
}
